import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: rgb(242, 5, 94);
  background: linear-gradient(
    90deg,
    rgba(242, 5, 94, 1) 12%,
    rgba(13, 150, 177, 1) 100%
  );
`;

export const LoginForm = styled.div`
  width: 400px;
  height: 400px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: center;
  border-radius: 10px;
  box-shadow: -6px 6px 17px 3px rgba(0, 0, 0, 0.48);
  -webkit-box-shadow: -6px 6px 17px 3px rgba(0, 0, 0, 0.48);
  -moz-box-shadow: -6px 6px 17px 3px rgba(0, 0, 0, 0.48);
  @media screen and (max-width: 768px) {
    width: 300px;
    height: 400px;
  }
`;

export const LogoImage = styled.img`
  width: auto;
  max-width: 350px;
`;
