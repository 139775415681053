import * as yup from 'yup';

const newCaseSchema = yup.object().shape({
  name: yup
    .string()
    .required('Campo obrigatório.')
    .min(5, 'O nome do caso deve ter ao menos 5 caracteres.')
});

export { newCaseSchema };
