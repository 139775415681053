import { Li } from '../styled';


export default function FrontCnaeCommercial({front}) {
    return (
        <>
            <li>
                <h2>Linha de ação do objeto social que envolve atividade comercial.</h2>
            </li>
            <ul>
                <Li>Considerações e/ou fatos relevantes:
                    <ul>
                        {front.rule.map((r) => (
                            <>
                            <Li>
                                Considerando que com relação ao(à) investigado(a) “<strong>{r.name} - {r.cpf_cnpj}</strong>” 
                                consta como empresa <strong>{r.status}</strong> nos cadastros da RECEITA FEDERAL e com capital social de <strong>{r.capital_social}</strong>;
                            </Li>
                            <Li>
                                Considerando que um dos objetos sociais envolve atividade de comércio (no caso, “<strong>CNAE Fiscal: {r.cnae}</strong>”);
                            </Li>
                            </>
                        ))}
                        <Li>Considerando que a atividade comercial, seja física, seja virtual, envolve fluxo de caixa e faturamento;</Li>
                        <Li>Considerando a possibilidade de o (s, os as) devedor(es) estarem operando;</Li>
                    </ul>
                </Li>
                <Li>
                    <strong>Conclusões</strong>: Considerando esses pontos e o que mais consta, recomenda-se verificar a viabilidade jurídica, 
                    bem como a conveniência e oportunidade estratégica, das seguintes medidas:
                    <ul>
                        <Li>
                            Aprofundar as pesquisas para descobrir onde está localizado de fato e atualmente o ponto de comércio do(a) pesquisado(a), 
                            e realizar diligência in loco para confirmar se está ou não operando e, se estiver, adquirir produto simples, 
                            por meio de cartão de crédito e solicitando a via do cliente, demonstrando-se assim para onde ou quem está sendo direcionado o faturamento com a venda de produtos, 
                            o que permitirá elucidar a ocorrência ou não de possíveis desvios ou atos contestáveis pelo credor;
                        </Li>
                        <Li>
                            Vasculhar por sites, blogs, mídias sociais ou lojas virtuais para encontrar possíveis pontos de comércio eletrônico do(a) investigado(a), 
                            sendo recomendável agir conforme acima exposto, no que couber;
                        </Li>
                        <Li>
                            Caso positivas as diligências acima, verificar com advogado se não é possível o uso de uma medida jurídica/processual que vise utilizar esses valores para pagamento da dívida, 
                            como por exemplo penhora de recebíveis, penhora de faturamento, penhora de crédito, penhora portas adentro ou outros pedidos.
                        </Li>
                    </ul>
                </Li>
                <Li>
                    Caso haja interesse em solicitar auxílio para tais tarefas 
                    e análises, <a target="_blank" rel="noreferrer" href="https://forms.monday.com/forms/53cda30c0b17fcb6c85e4a23a3ec7fec?r=use1"><strong>clique aqui</strong></a>.
                </Li>
            </ul>
        </>
    )
}