import React from 'react';
import Header from '../../components/Header';
import CasesTable from '../../components/CasesTable';
import useProtectedPage from '../../hooks/useProtectedPage';

const HomePage = () => {
  useProtectedPage();
  console.error = () => {};
  return (
    <div>
      <Header />
      <CasesTable />
    </div>
  );
};

export default HomePage;
