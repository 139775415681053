import { useHistory, useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import { goToLogin } from '../routes/coordinator';

const useProtectedPage = () => {
  const history = useHistory();
  const location = useLocation();
  localStorage.setItem('url', location.pathname);

  useLayoutEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      goToLogin(history);
    }
  }, [history]);
};

export default useProtectedPage;
