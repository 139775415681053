import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

import { tableHeadSmart } from '../../../../../../constants/colors';
import { VerdeEscuro, VermelhoEscuro } from '../../../Parecer/CreditAnalysis/styled';
import { Body1 } from '../styled';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: tableHeadSmart,
    color: theme.palette.common.black,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  body: {
    fontSize: 14,
    textAlign: 'center',
  }
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%'
  },
  isPositivo: {
    color: "green",
    fontWeight: "bold",
  },
  isNegativo: {
      color: "red",
      fontWeight: "bold",
  }
}));

export default function AveriguacaoRegularidadeFormal({front}) {
    const classes = useStyles();

    return (
        <>
            <h2>
                <li><strong>{front.front}</strong></li>
            </h2>

            <Body1>
                A consulta às bases de dados oficiais sobre regularidade das pessoas jurídicas
                pesquisadas revela os seguintes resultados:
            </Body1>

            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Nome</StyledTableCell>
                            <StyledTableCell>CNPJ</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>Data do Status</StyledTableCell>
                            <StyledTableCell>Motivo</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            {front.rule.map(({
                                name, 
                                cpf_cnpj, 
                                status, 
                                data_status, 
                                reason,
                                parecer
                            }, index) => (
                                <TableRow key={index}>
                                    <StyledTableCell component="th" scope="row" style={{backgroundColor: "#D4D4D4"}}>
                                        {name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{cpf_cnpj}</StyledTableCell>
                                    <StyledTableCell 
                                        align="left" 
                                        className={parecer === "POSITIVO" ? classes.isPositivo : classes.isNegativo}
                                    >
                                        {status}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{data_status}</StyledTableCell>
                                    <StyledTableCell align="left">{reason || "N/A"}</StyledTableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            
            {front.rule.some(({parecer}) => parecer === "POSITIVO") && (
                <Body1>
                    Portanto, com relação à <strong>averiguação sobre a regularidade formal da(s) pessoa(s)
                    jurídica(s) pesquisada(s)</strong>, considerando que as pessoas pesquisadas constam 
                    como <VerdeEscuro>REGULARES</VerdeEscuro> perante os órgãos oficiais, 
                    recomenda-se considerar este como um ponto <VerdeEscuro>positivo para a concessão do crédito</VerdeEscuro>.
                </Body1>
            )}

            {front.rule.some(({parecer}) => parecer === "NEGATIVO") && (
                <Body1>
                    Portanto, com relação à <strong>averiguação sobre a regularidade formal da(s) pessoa(s) jurídica(s) pesquisada(s)</strong>, considerando 
                    que <strong>{front.rule.filter(({parecer}) => parecer === "NEGATIVO").length} pessoas jurídicas das {front.rule.length} indicadas</strong> para 
                    a pesquisa constam como <VermelhoEscuro>BAIXADAS</VermelhoEscuro> perante os órgãos oficiais, 
                    recomenda-se considerar este como um <VermelhoEscuro>PONTO NEGATIVO</VermelhoEscuro> para a contratação, 
                    especialmente se havia a intenção de que alguma dessas pessoas assinasse o contrato, 
                    participasse do negócio ou se a regularidade delas fosse determinante ou relevante para a continuidade das tratativas.
                </Body1>
            )}
        </>
    )
}