import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { primaryColor } from '../../../constants/colors';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: '#E6E6E6'
  },
  table: {
    width: '100%'
  },
  td: {
    maxWidth: 300,
    wordWrap: 'break-word'
  }
}));

export default function Gnews({ data, last_update }) {
  const classes = useStyles();

  return (
    <>
      <h3>
        Conforme pesquisa realizada em {last_update}, o resultado é o seguinte:
      </h3>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Título</StyledTableCell>
              <StyledTableCell>Descrição</StyledTableCell>
              <StyledTableCell>Site</StyledTableCell>
              <StyledTableCell>Data</StyledTableCell>
              <StyledTableCell>Link</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length ? (
              data.map(({ title, desc, site, date, link }) => (
                <StyledTableRow>
                  <StyledTableCell
                    className={classes.td}
                    component="th"
                    scope="row"
                  >
                    {title}
                  </StyledTableCell>
                  <StyledTableCell className={classes.td} align="left">
                    {desc}
                  </StyledTableCell>
                  <StyledTableCell className={classes.td} align="left">
                    {site}
                  </StyledTableCell>
                  <StyledTableCell className={classes.td} align="left">
                    {date}
                  </StyledTableCell>
                  <StyledTableCell className={classes.td} align="left">
                    <a target="_blank" rel="noreferrer" href={`http://${link}`}>
                      Link
                    </a>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell align="center" colSpan={5}>
                  Sem Resultados
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
