import { 
    Paper,
    Table,
    TableHead,
    TableBody,
    TableContainer,
    TableCell,
    TableRow,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { tableHeadSmart } from '../../../../../../constants/colors';
import { Body1 } from "../styled";


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: tableHeadSmart,
        color: theme.palette.common.black,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    body: {
        fontSize: 14,
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    table: {
        width: '100%'
    },
    isStrong: {
        fontWeight: 'bold'
    }
}));

export default function AveriguacaoGrupoEconomicoSA({ front }) {
    const classes = useStyles();

    return (
        <>
        <h2>
            <li><strong>{front.front}</strong></li>
        </h2>
        <Body1>
            Normalmente, grupos econômicos formalmente constituídos são descobertos consultando 
            a estrutura societária de um conjunto de empresas e especialmente verificando as 
            pessoas jurídicas (holdings) que detêm participação societária em outras empresas (operacionais).
        </Body1>
        {front.rule.map(({name, cnpj, qsa, group_high, group_low}) => (
            <>
            <Body1>
                Entretanto, a pessoa pesquisada <strong>{name} - {cnpj}</strong> é uma sociedade anônima fechada, 
                o que implica na não divulgação pública de seu quadro de acionistas na Receita Federal 
                ou na Junta Comercial. Assim sendo, do ponto de vista de aferição de grupo econômico formal 
                baseado em participação societária a análise fica prejudicada (cfr. Art. 265, da LSA/1976).
            </Body1>
            <Body1>
                Por outro lado, dentre os elementos que denotam existência de grupo econômico de fato está a unidade gerencial, 
                isto é, multiplicidade de empresas com quadro de administradores idênticos ou semelhantes.
            </Body1>
            {group_high ? (
                <>
                <Body1>
                    Assim sendo, a tabela abaixo revela o QSA da pessoa pesquisada (na primeira linha) e, 
                    nas linhas subsequentes, outras empresas que possuem QSA idêntico ou semelhante (isto é, 
                    com repetição dos sócios acima) e que guardam relação de objetos sociais, 
                    complementaridade de atividades ou ainda razões sociais/nomes fantasia compatíveis, 
                    o que leva à dedução de que as empresas listadas abaixo têm alta chance de fazerem parte 
                    de um mesmo grupo econômico:
                </Body1>
                <TableContainer component={Paper} style={{ marginTop: '10px' }}>
                    <Table
                        className={classes.table}
                        size="small"
                        aria-label="customized table"
                    >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Empresa do Grupo</StyledTableCell>
                            <StyledTableCell>QSA: Sócios e/ou administradores</StyledTableCell>
                            <StyledTableCell>Coincidências</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                            <StyledTableCell align="center">
                                {name} - {cnpj}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {qsa.map(({name, type, strong}) => (
                                    <p className={classes.isStrong}>
                                        {name} - {type}
                                    </p>
                                ))}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                -
                            </StyledTableCell>
                        </StyledTableRow>
                        {group_high.map(({ company_name, cnpj, matches, qsa }, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell align="center">
                                    {company_name} - {cnpj}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {qsa.map(({name, type, strong}) => (
                                        <p className={strong && classes.isStrong}>
                                            {name} - {type}
                                        </p>
                                    ))}
                                </StyledTableCell>
                                <StyledTableCell align="center">{matches}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                    </Table>
                </TableContainer>
                </>
            ) : (
                <Body1>
                    Foram realizadas pesquisas a fim de elucidar se alguma pessoa do QSA acima aparece em outras empresas. 
                    De acordo com as buscas realizadas, <strong>NÃO foi identificado nenhum indício que aponte 
                    a formação de grupo Econômico por semelhança de sócios ou administradores com outras pessoas jurídicas</strong>.
                </Body1>
            )}
            {group_low ? (
                <>
                <Body1>
                    Ainda, abaixo, eis outras empresas em cujos quadros de sócios e/ou administradores (QSA) 
                    aparecem coincidências com a pesquisada, mas essas abaixo são <strong>empresas cuja chance de 
                    participarem do grupo econômico são mais baixas</strong>:
                </Body1>
                <TableContainer component={Paper} style={{ marginTop: '10px' }}>
                    <Table
                        className={classes.table}
                        size="small"
                        aria-label="customized table"
                    >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Empresa do Grupo</StyledTableCell>
                            <StyledTableCell>QSA: Sócios e/ou administradores</StyledTableCell>
                            <StyledTableCell>Coincidências</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {group_low.map(({ company_name, cnpj, matches, qsa }, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell align="center">
                                    {company_name} - {cnpj}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {qsa.map(({name, type, strong}) => (
                                        <p className={strong && classes.isStrong}>
                                            {name} - {type}
                                        </p>
                                    ))}
                                </StyledTableCell>
                                <StyledTableCell align="center">{matches}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                    </Table>
                </TableContainer>
                </>
            ) : (
                <Body1>
                    Foram realizadas pesquisas a fim de elucidar se alguma pessoa do QSA acima aparece em outras empresas. 
                    De acordo com as buscas realizadas, <strong>NÃO foi identificado nenhum indício que aponte 
                    a formação de grupo Econômico por semelhança de sócios ou administradores com outras pessoas jurídicas</strong>.
                </Body1>
            )}
            </>
        ))}
        <Body1>
            Assim sendo, submetem-se os achados acima à avaliação dos responsáveis para tomada das decisões ou providências cabíveis.
        </Body1>
        </>
    )
}