import {
  Box,
  Button,
  createStandaloneToast,
  extendTheme,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useState } from 'react';
import { RiAddLine } from 'react-icons/ri';
import { BASE_URL } from '../../../../constants/urls';
import { useParams } from 'react-router-dom';

const AddInfoModal = ({ type, initialState, changeState }) => {
  console.error = () => {};
  const [isOpen, setIsOpen] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState([]);
  const params = useParams();
  const productId = params.id;
  const token = localStorage.getItem('token');

  var _ = require('lodash');

  const id = 'test';

  const theme = extendTheme({
    fonts: {
      heading: 'Open Sans',
      body: 'Raleway'
    }
  });

  const toast = createStandaloneToast({ theme: theme });

  const onClose = () => {
    if (clicked) {
      window.location.reload();
    }
    setIsOpen(false);
  };

  const onOpen = () => {
    setIsOpen(true);
  };

  const getData = async () => {
    let req = '';

    if (type == 'Contexto') {
      req = `${BASE_URL}/context`;
    } else {
      req = `${BASE_URL}/service`;
    }

    const response = await axios.get(req, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    setResult(
      response?.data?.data.filter(function (o1) {
        return _.findIndex(initialState, { id: o1.id, name: o1.name }) !== -1
          ? false
          : true;
      })
    );
  };

  const updateData = async (id, item) => {
    setIsLoading(true);
    setClicked(true);

    let req = '';

    if (type == 'Contexto') {
      req = `${BASE_URL}/product/${productId}/context/${id}`;
    } else {
      req = `${BASE_URL}/product/${productId}/service/${id}`;
    }

    const response = await axios
      .put(req, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        setTimeout(() => {
          setResult(result.filter((item) => item.id !== id));
          changeState([...initialState], item);
          setIsLoading(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              title: `${type} adicionado com sucesso!`,
              status: 'success',
              duration: 9000,
              isClosable: true
            });
          }
        }, 4000);
      })
      .catch((error) => {
        setIsLoading(false);
        const msg = error?.response?.data?.detail?.map((msg) => msg.msg);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: `Houve um erro ao adicionar o ${type}`,
            description: msg,
            status: 'error',
            duration: 9000,
            isClosable: true
          });
          setIsLoading(false);
        }
      });
  };

  const returnData = result?.map((info) => {
    return (
      <Tbody>
        <Tr>
          <Td>
            <Box>
              <Text fontWeight="bold">{info?.name}</Text>
              <Text fontWeight="sm" color="gray.500">
                {info?.dag_id}
              </Text>
            </Box>
          </Td>
          <Td>
            <Link>
              <Button
                as="a"
                size="sm"
                fontSize="sm"
                colorScheme="blue"
                leftIcon={<Icon as={RiAddLine} />}
                isLoading={isLoading}
                onClick={() => updateData(info?.id, info)}
              >
                Adicionar
              </Button>
            </Link>
          </Td>
        </Tr>
      </Tbody>
    );
  });

  return (
    <>
      <Button
        onClick={() => {
          onOpen();
          getData();
        }}
        as="a"
        size="sm"
        fontSize="sm"
        colorScheme="blue"
        leftIcon={<Icon as={RiAddLine} />}
      >
        Adicionar
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior={'inside'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cadastrar {type}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="4">
              <Table colorScheme="whiteAlpha">
                <Thead>
                  <Tr>
                    <Th>NOME</Th>
                    <Th>Adicionar</Th>
                    <Th width="8"></Th>
                  </Tr>
                </Thead>
                {returnData}
              </Table>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddInfoModal;
