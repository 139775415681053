import { 
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableCell,
    TableRow,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { tableHeadSmart } from '../../../../../../constants/colors';
import { VerdeEscuro, VermelhoEscuro } from "../../../Parecer/CreditAnalysis/styled";
import { Body1, Li } from "../styled";


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: tableHeadSmart,
      color: theme.palette.common.black,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    body: {
      fontSize: 14,
    }
  }))(TableCell);
  
const useStyles = makeStyles((theme) => ({
    table: {
        width: '100%'
    },
}));


export default function AveriguacaoProtestos({front}) {
    const classes = useStyles();

    const positivos = front.rule.filter(({parecer}) => (parecer === "POSITIVO"));
    const negativos= front.rule.filter(({parecer}) => (parecer === "NEGATIVO"));

    return (
        <>
            <h2>
                <li><strong>{front.front}</strong></li>
            </h2>
            <ul>
                {positivos.length > 0 && (
                    <>
                    <Li>
                        <strong>Considerações e/ou fatos relevantes</strong>.
                        <ul>
                        {positivos.map(({name, cpf_cnpj}, index) => (
                            <Li key={index}>
                                Considerando que o(a) pesquisado(a) “<strong>{name} - {cpf_cnpj}</strong>”{' '}
                                <VerdeEscuro>NÃO</VerdeEscuro> <strong>contém indicação de protestos</strong> em seu nome.
                            </Li>
                        ))}
                        </ul>
                    </Li>
                    <Li>
                        <strong>Conclusões e/ou recomendações técnicas.</strong>
                        <Li>
                            Tem-se que esse deve ser considerado como um ponto <VerdeEscuro>POSITIVO</VerdeEscuro> para a concessão do crédito.
                        </Li>
                    </Li>
                    </>
                )}
                {negativos.length > 0 && (
                    <>
                    <Li>
                        <strong>Considerações e/ou fatos relevantes</strong>.
                        <ul>
                        {negativos.map(({
                            name, 
                            cpf_cnpj, 
                            protesto, 
                            total_protestos
                        }, index) => (
                            <div key={index}>
                            <Li>
                                Considerando que o(a) pesquisado(a) “<strong>{name} - {cpf_cnpj}</strong>”{' '}
                                <strong>contém indicação de protestos ou negativações</strong> em
                                seu nome, como se confere abaixo:
                            </Li>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>UF</StyledTableCell>
                                            <StyledTableCell>Cidade</StyledTableCell>
                                            <StyledTableCell>Cartório</StyledTableCell>
                                            <StyledTableCell>Protestos</StyledTableCell>
                                            <StyledTableCell>Valor (R$)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                            {protesto?.map(({
                                                uf, 
                                                city, 
                                                registry, 
                                                qtde, 
                                                value,
                                            }, index) => (
                                                <TableRow key={index}>
                                                    <StyledTableCell component="th" scope="row">
                                                        {uf}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {city}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {registry}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {qtde}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {value}
                                                    </StyledTableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell colSpan={4}>
                                                    <strong>Total</strong>
                                                </TableCell>
                                                <TableCell>
                                                    <strong>{total_protestos}</strong>
                                                </TableCell>
                                            </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            </div>
                        ))}
                        </ul>
                    </Li>
                    <Body1>
                        <strong>Conclusão:</strong> tem-se que esse deve ser considerado como um fator <VermelhoEscuro>NEGATIVO</VermelhoEscuro> para 
                        a realização de potenciais futuros negócios com o(a) pesquisado(a).
                    </Body1>
                    <Body1>
                        A depender do valor do negócio realizado e do contexto de riscos que se pretende assumir, 
                        recomenda-se que o responsável verifique se é possível seguir ou não com o negócio tendo em vista os protestos encontrados acima.
                    </Body1>
                    <Body1>
                        Ademais, caso seja juridicamente possível e caso faça sentido no contexto do negócio, recomenda-se que se indague à outra parte sobre esses protestos, 
                        a fim que esclareça essas descobertas e tranquilize o contratante sobre eventuais riscos.
                    </Body1>
                    </>
                )}
            </ul>
        </>
    )
}