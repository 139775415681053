import styled from 'styled-components';
import { primaryColor } from '../../constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: #4a4a4a;
  word-break: break-all;
`;

export const ContextContainer = styled.div`
  width: 100%;
  border: 1px solid ${primaryColor};
  border-radius: 5px;
`;
