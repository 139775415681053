import { Button, TextField } from '@material-ui/core';
import styled from 'styled-components';
import { primaryColor } from '../../constants/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export const TrueIcon = styled(CheckCircleIcon)`
  color: green;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 25px;
  height: 2rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 3rem;
    margin-top: 5px;
  }
`;

export const StyledTextField = styled(TextField)`
  width: 90%;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 2px;
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${primaryColor};
  color: white;
  width: 15%;
  font-size: 14px;
  .placeholder {
    font-size: 1px;
  }
  @media screen and (max-width: 768px) {
    font-size: 10px;
    width: 100%;
  }
  :hover {
    background-color: ${primaryColor};
  }
`;

export const Container = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    padding: 15px;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25rem;
  width: auto;
  position: relative;
`;

export const PaginationContainer = styled.div`
  .paginationBttns {
    margin-top: 2rem;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .paginationBttns a {
    padding: 5px;
    margin: 5px;
    cursor: pointer;
    border-radius: 3px;
  }
  .previusBttn {
    cursor: pointer;
    border: 1px solid ${primaryColor};
    border-radius: 3px;
    padding: 5px;
    color: white;
    background-color: ${primaryColor};
  }
  .nextBttn {
    cursor: pointer;
    border: 1px solid ${primaryColor};
    border-radius: 3px;
    padding: 5px;
    color: white;
    background-color: ${primaryColor};
  }
  .paginationActive a {
    border: 1px solid ${primaryColor};
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

export const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 25rem;
`;
