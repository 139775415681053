import { 
    Paper,
    Table,
    TableHead,
    TableBody,
    TableContainer,
    TableCell,
    TableRow,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { tableHeadSmart } from '../../../../../../constants/colors';
import { Li } from "../styled";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: tableHeadSmart,
        color: theme.palette.common.black,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    body: {
        fontSize: 14,
    }
}))(TableCell);
  
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);
  
const useStyles = makeStyles((theme) => ({
    table: {
        width: '100%'
    }
}));


export default function AveriguacaoEmpresasPesquisados({ front }) {
    const classes = useStyles();

    return (
        <>
        <h2>
            <li><strong>{front.front}</strong></li>
        </h2>
        <Li>
            <strong>Considerações e/ou fatos relevantes</strong>: 
            <ul>
                <Li>
                    Considerando que com relação aos(às) pesquisado(as) 
                    foram encontradas as seguintes empresas:
                </Li>
            </ul>
        </Li>
        <TableContainer component={Paper} style={{ marginTop: '10px' }}>
            <Table
                className={classes.table}
                size="small"
                aria-label="customized table"
            >
                <TableHead>
                    <TableRow>
                        <StyledTableCell>PESQUISADO</StyledTableCell>
                        <StyledTableCell>RAZÃO SOCIAL</StyledTableCell>
                        <StyledTableCell>CNPJ</StyledTableCell>
                        <StyledTableCell>CAPITAL SOCIAL</StyledTableCell>
                        <StyledTableCell>STATUS</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {front.rule.map(({ name, cpf_cnpj, empresas}, index) => (
                        <StyledTableRow key={index}>
                            <StyledTableCell component="th" scope="row">
                                {name} - {cpf_cnpj}
                            </StyledTableCell>
                            <StyledTableCell colSpan={4}>
                            {empresas.map(({company, cadastral}) => (
                                <StyledTableRow>
                                    <StyledTableCell align="left">
                                        {company.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {company.cnpj}
                                    </StyledTableCell> 
                                    <StyledTableCell align="left">
                                        {company.capital_social}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {cadastral.situation}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <Li>
            <strong>Conclusões</strong>: recomenda-se consultar a viabilidade jurídica, 
            bem como a oportunidade e conveniência estratégica, 
            de se realizar as seguintes medidas:

            <ul>
                <Li>
                    Com relação às <strong>empresas ativas</strong>:
                    <ul>
                        <Li>
                            Obter as alterações societárias mais atualizadas a fim de esclarecer 
                            se o(a, os, as) investigado(s) realmente possuem participação 
                            nas pessoas jurídicas indicadas;
                        </Li>
                        <Li>
                            Aprofundar as pesquisas, encomendar relatórios ou contratar dossiês 
                            mais elaborados sobre os sócios e pessoas próximas do(a) devedor(a) primário(a), 
                            a fim de confirmar ou refutar a hipótese de que tenha continuado a exercer 
                            a mesma atividade comercial em sucessão empresarial por meio de 
                            outras pessoas jurídicas ou naturais;
                        </Li>
                        <Li>
                            Caso o conflito já tenha sido judicializado, averbar a certidão de existência 
                            da ação no cadastro da pessoa jurídica perante a Junta Comercial competente 
                            ou Registro de Pessoas Jurídicas competente;
                        </Li>
                        <Li>
                            Aprofundar as pesquisas sobre essas pessoas jurídicas encontradas para verificar 
                            se não seria pertinente requerer a penhora das quotas, ações ou participação jurídica encontrada;
                        </Li>
                        <Li>
                            Caso o(a) pesquisado(s) relacionado(a) não seja titular da pessoa jurídica, mas, sim, 
                            exerça função no corpo de administração dela, verificar a viabilidade de intimação 
                            para esclarecimentos de a que título essa função é exercida e como se dá a remuneração 
                            pelo exercício de tal atividade, com a consequente penhora, ainda que parcial, do que for encontrado;
                        </Li>
                    </ul>
                </Li>

                <Li>
                    Com relação às <strong>empresas inativas</strong>:
                    <ul>
                        <Li>
                            Buscar encontrar estabelecimentos virtuais ou físicos onde o(a) devedor(a) 
                            porventura esteja operando e/ou comercializando seus produtos e, caso esteja, 
                            recomenda-se adquirir produto ou serviço (caso o preço seja factível), 
                            a fim de se entender como estão operando e em nome de quem estão faturando;
                        </Li>
                        <Li>
                            Requerer em face do(a) devedor(a) as medidas executivas de praxe e pesquisas 
                            patrimoniais que exigem autorização judicial, mesmo constando a pessoa jurídica como inapta, 
                            pois sabe-se que é possível que pessoas jurídicas inaptas ou baixadas ainda consigam movimentar 
                            ou titularizar patrimônio, o que, inclusive, é utilizado como estratégia de alguns para blindar 
                            ardilosamente patrimônio;
                        </Li>
                    </ul>
                </Li>
            </ul>
        </Li>
        <Li>
            <strong>
                Caso haja interesse em solicitar auxílio para tais tarefas e análises, {' '}
                <a target="_blank" rel="noreferrer" href="mailto:vega@inquest.com.br?r=use1">
                    clique aqui
                </a> ou {' '}
                <a target="_blank" rel="noreferrer" href="mailto:vega@inquest.com.br?r=use1">
                    entre em contato conosco
                </a>.
            </strong>
        </Li>
        </>
    )
}