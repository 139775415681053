import React from 'react';
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Typography,
  AppBar,
  Tabs,
  Tab,
  Box,
  Badge
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { primaryColor } from '../../../constants/colors';
import PropTypes from 'prop-types';
import TableJusbrasil from './TableJusbrasil';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: '#E6E6E6'
  },
  table: {
    width: '100%'
  }
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    left: 50,
    padding: '0 4px'
  }
}))(Badge);

export default function Jusbrasil({ data }) {
  const { filtered, low_score, not_delivered } = data;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const qtd_filtered = filtered ? filtered.processes.length : 0;
  const qtd_low_score = low_score ? low_score.processes.length : 0;
  const qtd_not_delivered = not_delivered ? not_delivered.length : 0;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <h3>Processos Judiciais e Administrativos:</h3>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Tabs Processos"
        >
          <Tab
            label="Processos Jusbrasil"
            {...a11yProps(0)}
            icon={
              <StyledBadge color="secondary" badgeContent={qtd_filtered} />
            }
          />
          <Tab
            label="Baixa Probabilidade de Participação"
            {...a11yProps(1)}
            icon={
              <StyledBadge color="secondary" badgeContent={qtd_low_score} />
            }
          />
          <Tab
            label="Demais Processos"
            {...a11yProps(2)}
            icon={
              <StyledBadge color="secondary" badgeContent={qtd_not_delivered} />
            }
          />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <TableJusbrasil processes={filtered} active={true} />
        <TableJusbrasil processes={filtered} active={false} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TableJusbrasil processes={low_score} active={true} />
        <TableJusbrasil processes={low_score} active={false} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Número do Processo</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {qtd_not_delivered > 0 ? (
                not_delivered.map((number) => (
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      <a
                        href={`https://www.jusbrasil.com.br/consulta-processual/busca?q=${number}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {number}
                      </a>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Informação Não Suportada
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell align="center" colSpan={2}>
                    Nenhum Resultado Encontrado.
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
    </>
  );
}
