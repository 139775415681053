import { 
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableCell,
    TableRow,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { tableHeadSmart } from '../../../../../../constants/colors';
import { Alert, AlertTitle } from "@material-ui/lab";
import { VerdeEscuro, VermelhoEscuro } from "../../../Parecer/CreditAnalysis/styled";
import { Li, Body1 } from "../styled";


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: tableHeadSmart,
        color: theme.palette.common.black,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    body: {
        fontSize: 14,
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);
  
const useStyles = makeStyles((theme) => ({
    table: {
        width: '100%'
    },
}));

export default function AveriguacaoScore({ front }) {
    const classes = useStyles();

    return (
        <>
        <h2>
            <li>{front.front}</li>
        </h2>
        <Body1>
            As pesquisas realizadas revelaram as seguintes informações 
            sobre score de crédito dos pesquisados no mercado:
        </Body1>
        {front.rule.map(({
            name, 
            cpf_cnpj, 
            error, 
            score, 
            reasons, 
            parecer
        }, index) => (
            error ? (
                <Li key={index}>
                    <Alert severity="warning">
                        <AlertTitle>Aviso!</AlertTitle>
                        Nas pesquisas realizadas perante instituições de proteção ao crédito 
                        e cadastro de inadimplentes e bons pagadores NÃO foram encontrados registros 
                        ou informações sobre a pessoa pesquisada <strong>{name} - {cpf_cnpj}</strong>.
                    </Alert>
                </Li>
            ) : (
                <>
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="customized table">
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell>
                                    <strong>Pessoa Pesquisada</strong>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <strong>{name}</strong>
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell>
                                    <strong>CPF/CNPJ</strong>
                                </StyledTableCell>
                                <StyledTableCell  align="center">
                                    <strong>{cpf_cnpj}</strong>
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell>
                                    Score de 0 a 1.000
                                </StyledTableCell>
                                <StyledTableCell  align="center">
                                    {score}
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell>
                                    Mensagem
                                </StyledTableCell>
                                <StyledTableCell>
                                    {reasons.map((reason) => (
                                        <p>- {reason}</p>
                                    ))}
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                {parecer === "POSITIVO" ? (
                    <Body1>
                        Assim sendo, considerando que o mercado dá <VerdeEscuro>score satisfatório</VerdeEscuro> para o(s) pesquisado(s), 
                        tem se que esse deve ser considerado como um <VerdeEscuro>ponto positivo para a concessão do crédito</VerdeEscuro>.
                    </Body1>
                ) : (
                    <Body1>
                        Assim sendo, considerando que o mercado dá <VermelhoEscuro>score Baixo</VermelhoEscuro> para o(s) pesquisado(s), 
                        considerando que constaram mensagens desabonadoras de adimplemento impontual ou parcial das obrigações, 
                        tem se que esse deve ser considerado como um <VermelhoEscuro>ponto negativo</VermelhoEscuro> para fins de 
                        celebração do negócio.
                    </Body1>
                )}
                </>
            )
        ))}
        </>
    )
}