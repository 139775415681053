import Header from '../../../components/Header';
import useProtectedPage from '../../../hooks/useProtectedPage';
import {
  Box,
  Button,
  ChakraProvider,
  Checkbox,
  extendTheme,
  Flex,
  Heading,
  Icon,
  Link,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react';
import SideBar from '../../../components/SideBar';
import { RiAddLine } from 'react-icons/ri';
import { Fonts } from '../../../styles/fonts';
import axios from 'axios';
import { BASE_URL } from '../../../constants/urls';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  goToCreateProduct,
  goToCreateUser,
  goToProduct,
  goToUser
} from '../../../routes/coordinator';

const Products = () => {
  useProtectedPage();
  console.error = () => {};
  const token = localStorage.getItem('token');
  const [Products, setProducts] = useState([]);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const history = useHistory();

  const theme = extendTheme({
    fonts: {
      heading: 'Open Sans',
      body: 'Raleway'
    }
  });

  const getProducts = async () => {
    const response = await axios.get(`${BASE_URL}/product`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    setProducts(response?.data?.data);
    setIsLoadingPage(false);
  };

  const onClickDetails = (id) => {
    goToProduct(history, id);
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoadingPage(true);
        await getProducts();
      } catch (error) {}
    })();
  }, [token]);

  const returnProducts = Products.map((product) => {
    return (
      <Tbody>
        <Tr>
          <Td>
            <Box>
              <Text fontWeight="bold">{product?.name}</Text>
              <Text fontWeight="sm" color="gray.500">
                {product?.value}
              </Text>
            </Box>
          </Td>
          <Td>{product?.label}</Td>
          <Td>{product?.id}</Td>
          <Td>{product?.enable ? 'Sim' : 'Não'}</Td>
          <Td>
            <Link>
              <Button
                onClick={() => onClickDetails(product?.id)}
                as="a"
                size="sm"
                fontSize="sm"
                colorScheme="blue"
              >
                Ver Detalhes
              </Button>
            </Link>
          </Td>
        </Tr>
      </Tbody>
    );
  });

  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <Box>
        <Header />
        <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
          <SideBar />

          {isLoadingPage ? (
            <Box flex="1" borderRadius={8} bg="gray.50" p="8">
              <Progress isIndeterminate />
            </Box>
          ) : (
            <>
              <Box flex="1" borderRadius={8} bg="gray.50" p="8">
                <Flex mb="8" justify="space-between" align="center">
                  <Heading size="lg" fontWeight="normal">
                    Produtos
                  </Heading>
                  <Link>
                    <Button
                      as="a"
                      size="sm"
                      fontSize="sm"
                      colorScheme="pink"
                      leftIcon={<Icon as={RiAddLine} />}
                      onClick={() => goToCreateProduct(history)}
                    >
                      Criar novo
                    </Button>
                  </Link>
                </Flex>

                <Table colorScheme="whiteAlpha">
                  <Thead>
                    <Tr>
                      <Th>Produto</Th>
                      <Th>Label</Th>
                      <Th>ID</Th>
                      <Th>Ativo</Th>
                      <Th width="8"></Th>
                    </Tr>
                  </Thead>
                  {returnProducts}
                </Table>
              </Box>
            </>
          )}
        </Flex>
      </Box>
    </ChakraProvider>
  );
};

export default Products;
