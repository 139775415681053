import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

import { primaryColor } from '../../../../../../constants/colors';
import { Li } from '../styled';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 12
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%'
  }
}));

export default function FrontIndividualCompanyPartners({ front }) {
  const classes = useStyles();

  return (
    <>
      <li>
        <h2>{front.front}</h2>
      </li>
      <ul>
        <Li>
          <strong>Considerações e/ou fatos relevantes</strong>.
          <ul>
            <Li>
              Considerando que o (a, os, as) pesquisado(a, os, as) abaixo são ou
              estão relacionados com CNPJ(s) de empresário individual:
            </Li>
            <TableContainer component={Paper} style={{ marginTop: '10px' }}>
              <Table
                className={classes.table}
                size="small"
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Nome</StyledTableCell>
                    <StyledTableCell>CPF</StyledTableCell>
                    <StyledTableCell>CNPJ</StyledTableCell>
                    <StyledTableCell>CNAE</StyledTableCell>
                    <StyledTableCell>Capital Social</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {front.rule.map(({ name, cpf_cnpj, result }) =>
                    result.map(({ status, cnpj, cnae, capital_social }) => (
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {status}
                        </StyledTableCell>
                        <StyledTableCell align="left">{name}</StyledTableCell>
                        <StyledTableCell align="left">
                          {cpf_cnpj}
                        </StyledTableCell>
                        <StyledTableCell align="left">{cnpj}</StyledTableCell>
                        <StyledTableCell align="left">{cnae}</StyledTableCell>
                        <StyledTableCell align="left">
                          {capital_social}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Li>
              Considerando a orientação de que “
              <strong>
                quanto às obrigações decorrentes de sua atividade, o empresário
                responde ilimitadamente, vale dizer, os bens particulares
                respondem, sem qualquer limitação, pelas obrigações contraídas,
                vez que não há separação patrimonial
              </strong>
              ”<sup>6</sup>, de modo que a pessoa física é naturalmente
              responsável pelas obrigações da jurídica e vice-versa;
            </Li>
          </ul>
        </Li>
        <Li>
          <strong>Conclusões e/ou recomendações técnicas</strong>. Diante dos
          fatos relevantes acima indicados e tudo mais encontrado, sugere-se
          estudar a viabilidade jurídica e/ou conveniência estratégica de se
          promover as seguintes medidas:
          <ul>
            <Li>
              Consultar advogado sobre a possibilidade de se responsabilizar e
              realizar atos constritivos em face do CNPJ (caso o devedor direto
              seja o CPF) ou em face do CPF (caso o devedor direto seja o CNPJ);
            </Li>
            <Li>
              Aprofundar pesquisas patrimoniais e outras medidas em face do CNPJ
              e CPF de empresário individual, tendo em vista que a ausência de
              separação patrimonial entre essas figuras pode abrir novos
              caminhos em prol da recuperação do crédito;
            </Li>
          </ul>
        </Li>
        <Li>
          Caso haja interesse em solicitar auxílio para tais tarefas e análises,{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://forms.monday.com/forms/53cda30c0b17fcb6c85e4a23a3ec7fec?r=use1"
          >
            <strong>clique aqui</strong>
          </a>
          .
        </Li>
      </ul>
    </>
  );
}
