import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Container, LoginForm } from './styled';
import WLogo from '../../assets/WLogo.png';
import { LogoImage } from './styled';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from '@material-ui/core';
import { toast, ToastContainer } from 'react-toastify';
import api from '../../services/api/api';
import { goToHome } from '../../routes/coordinator';
import { useHistory } from 'react-router';
import { loginSchema } from '../../schemas/login';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const LoginPage = () => {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(loginSchema)
  });

  const signIn = async (form) => {
    setIsLoading(true);
    try {
      const response = await api.post(
        '/login',
        `username=${form.email}&password=${form.password}&grant_type=password`
      );

      localStorage.setItem('cg_name', response.data.name);
      localStorage.setItem('token', response.data.access_token);
      localStorage.setItem('access', response.data.rule.id);

      const url = localStorage.getItem('url');
      if (url !== "/" && url !== null) {
        window.location.assign(url);
      }

      goToHome(history);
    } catch (error) {

      const msg = error.response.data.detail;
      msg.length ? (
        msg.map(({msg, type}) => (
          toast.error(msg)
        ))
      ) : (
        toast.error('E-mail ou senha incorretos.')
      )
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <ToastContainer />
      <CssBaseline />
      <LoginForm>
        <LogoImage src={WLogo} />
        <form className={classes.form} onSubmit={handleSubmit(signIn)}>
          <TextField
            variant="outlined"
            margin="normal"
            {...register('email')}
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
          />
          {errors.email?.message}
          <TextField
            variant="outlined"
            margin="normal"
            {...register('password')}
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {errors.password?.message}
          <Button
            disabled={isLoading}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {isLoading ? (
              <CircularProgress color={'primary'} size={30} />
            ) : (
              'Login'
            )}
          </Button>
        </form>
      </LoginForm>
    </Container>
  );
};

export default LoginPage;
