import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { H3 } from '../../styled';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: '100%',
    marginBottom: '10px'
  }
});

export function CardTitulos({ negative, cpf_cnpj }) {
  const classes = useStyles();

  const returnCpfData = () => {
    return (
      <>
        <H3>
          Valor financeiro total de Títulos Protestados: R${' '}
          {negative?.resumo_titulos_protestados?.valorAcumulado} em{' '}
          {negative?.resumo_titulos_protestados?.total} protestos
        </H3>
        <H3 style={{ marginTop: '15px' }}>Últimas Ocorrências</H3>
        {negative?.titulos_protestados?.map((debito) => (
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h6">Valor: R$ {debito?.valor}</Typography>
              <Typography color="textSecondary">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <p>
                      <strong>Cidade:</strong> {debito?.cidade}
                    </p>
                    <p>
                      <strong>Estado:</strong> {debito?.uf}
                    </p>
                    <p>
                      <strong>Data Ocorrência:</strong>{' '}
                      {debito?.data_ocorrencia}
                    </p>
                  </Grid>
                </Grid>
              </Typography>
            </CardContent>
          </Card>
        ))}
      </>
    );
  };

  const returnCnpjData = () => {
    return (
      <>
        <H3>
          Valor financeiro total de Títulos Protestados: R${' '}
          {negative[0]?.titulos_protestados?.valor_total} em{' '}
          {negative[0]?.titulos_protestados?.total_protestos} protestos
        </H3>
        <H3 style={{ marginTop: '15px' }}>Últimas Ocorrências</H3>
        {negative[0]?.titulos_protestados?.ultimas_ocorrencias.map((debito) => (
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h6">Valor: R$ {debito?.valor}</Typography>
              <Typography color="textSecondary">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <p>
                      <strong>Cidade:</strong> {debito?.cidade}
                    </p>
                    <p>
                      <strong>Estado:</strong> {debito?.estado}
                    </p>
                    <p>
                      <strong>Data Ocorrência:</strong> {debito?.data}
                    </p>
                  </Grid>
                </Grid>
              </Typography>
            </CardContent>
          </Card>
        ))}
      </>
    );
  };

  return (
    <>
      {cpf_cnpj.length === 14 ? (
        <>{returnCpfData()}</>
      ) : (
        <>{returnCnpjData()}</>
      )}
    </>
  );
}
