import { Li } from "../styled";
 
export default function FrontDomainsVirtualAddresses({front}) {
    return (
        <>
            <li>
                <h2>{front.front}</h2>
            </li>
            <ul>
                {front.rule.map((r) => (
                    <Li>
                        Considerando que nas pesquisas realizadas foram encontrados os seguintes domínios e/ou sites que possivelmente pertencem 
                        ou tem relação com o (a) pesquisado (a) “<strong>{r.name} - {r.cpf_cnpj}</strong>” limitada:
                        <ul>
                            {r.result.map(({domain}) => (
                                <Li>{domain}</Li>
                            ))}
                        </ul>
                    </Li>
                ))}
                <Li>
                    Sugere-se estudar a viabilidade jurídica e conveniência estratégica das seguintes medidas:
                    <ul>
                        <Li>
                            Estudar tal site para obter toda informação relevante para a finalidade de cobrança e recuperação de crédito, tal como atuais clientes, 
                            serviços prestados, fornecedores, nome fantasia diferente ou indícios de sucessão empresarial ou grupo empresarial, 
                            indícios de existência de faturamento ou recebíveis atuais, entre outros dados que possam ser relevantes.
                        </Li>
                    </ul>
                </Li>
                <Li>
                    Caso haja interesse em solicitar auxílio para tais tarefas 
                    e análises, <a target="_blank" rel="noreferrer" href="https://forms.monday.com/forms/53cda30c0b17fcb6c85e4a23a3ec7fec?r=use1"><strong>clique aqui</strong></a>.
                </Li>
            </ul>
        </>
    )
}