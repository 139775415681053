import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { tableHeadSmart } from '../../../../../../constants/colors';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
  VerdeEscuro,
  VermelhoEscuro
} from '../../../Parecer/CreditAnalysis/styled';
import { Li } from '../styled';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: tableHeadSmart,
    color: theme.palette.common.black,
    textAlign: 'center',
    fontWeight: 'bold'
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%'
  }
}));

export default function AveriguacaoNegativacoes({ front }) {
  const classes = useStyles();

  return (
    <>
      <h2>
        <li>
          <strong>{front.front}</strong>
        </li>
      </h2>
      <Li>
        <strong>Considerações e/ou fatos relevantes.</strong>
        <ul>
          {front.rule.map(({ name, cpf_cnpj, negativacoes, error }, index) =>
            error ? (
              <Li key={index}>
                <Alert severity="warning">
                  <AlertTitle>Aviso!</AlertTitle>
                  Nas pesquisas realizadas perante instituições de proteção ao
                  crédito e cadastro de inadimplentes e bons pagadores NÃO foram
                  encontrados registros ou informações sobre a pessoa pesquisada{' '}
                  <strong>
                    {name} - {cpf_cnpj}
                  </strong>
                  .
                </Alert>
              </Li>
            ) : (
              <Li key={index}>
                {negativacoes ? (
                  <div>
                    <p>
                      Considerando que o(a) pesquisado(a) “
                      <strong>
                        {name} - {cpf_cnpj}
                      </strong>
                      ”{' '}
                      <VermelhoEscuro>
                        contém indicação de negativações
                      </VermelhoEscuro>
                       em seu nome, como se confere abaixo:
                    </p>
                    {negativacoes.apontamento.map(
                      ({
                        company_name,
                        cnpj,
                        address,
                        date_included,
                        date_occurred,
                        amount,
                        contract_number
                      }) => (
                        <>
                          <TableContainer
                            style={{ marginTop: '20px', marginBottom: '20px' }}
                            component={Paper}
                          >
                            <Table
                              className={classes.table}
                              size="small"
                              aria-label="customized table"
                            >
                              <TableBody>
                                <StyledTableRow>
                                  <StyledTableCell>
                                    <strong>Empresa Negativadora</strong>
                                  </StyledTableCell>
                                  <StyledTableCell align="center" colSpan={3}>
                                    {company_name}
                                  </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell>
                                    <strong>CNPJ</strong>
                                  </StyledTableCell>
                                  <StyledTableCell align="center" colSpan={3}>
                                    {cnpj}
                                  </StyledTableCell>
                                </StyledTableRow>
                                <TableRow>
                                  <StyledTableCell>
                                    <strong>Cidade</strong>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {address?.city} - {address?.state}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <strong>Valor</strong>
                                  </StyledTableCell>
                                  <StyledTableCell>{amount}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                  <StyledTableCell>
                                    <strong>Bairro</strong>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {address?.neighborhood}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <strong>Data Inclusão</strong>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {date_included}
                                  </StyledTableCell>
                                </TableRow>
                                <TableRow>
                                  <StyledTableCell>
                                    <strong>Código Postal</strong>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {address?.postal_code}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <strong>Data Ocorrência</strong>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {date_occurred}
                                  </StyledTableCell>
                                </TableRow>
                                <TableRow>
                                  <StyledTableCell>
                                    <strong>Logradouro</strong>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {address?.street} -{' '}
                                    {address?.number || 'S/N'}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <strong>Nº Contrato</strong>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {contract_number}
                                  </StyledTableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </>
                      )
                    )}
                    <Li>
                      <strong>Conclusões e/ou recomendações técnicas</strong>.
                    </Li>
                    Tem-se que esse deve ser considerado como um ponto{' '}
                    <VermelhoEscuro>NEGATIVO</VermelhoEscuro> para a concessão
                    do crédito.
                  </div>
                ) : (
                  <div>
                    <p>
                      Considerando que o(a) pesquisado(a) “
                      <strong>
                        {name} - {cpf_cnpj}
                      </strong>
                      ” <VerdeEscuro>NÃO</VerdeEscuro>{' '}
                      <strong>contém indicação de negativações</strong> em seu
                      nome.
                    </p>
                    <Li>
                      <strong>Conclusões e/ou recomendações técnicas</strong>.
                      <Li>
                        Tem-se que esse deve ser considerado como um ponto{' '}
                        <VerdeEscuro>POSITIVO</VerdeEscuro> para a concessão do
                        crédito.
                      </Li>
                    </Li>
                  </div>
                )}
              </Li>
            )
          )}
        </ul>
      </Li>
    </>
  );
}
