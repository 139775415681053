import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Typography
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { primaryColor } from '../../../../constants/colors';
import { Paper } from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: '#E6E6E6'
  },
  table: {
    width: '100%'
  },
  markTarget: {
    fontWeight: 'bold',
    color: 'red'
  }
}));

const TableJusbrasil = ({ processes, active }) => {
  const classes = useStyles();
  const status_process = active ? 'ACTIVE' : 'INACTIVE';

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={9}>
                <Typography variant="h6">
                  Processos {active ? 'Ativos' : 'Inativos'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={6}>
                <p>
                  Total de Processos Ativos:&nbsp;
                  {processes &&
                    processes.values.polo_ativo &&
                    (active
                      ? `${processes.values.polo_ativo.abertos.valor_total}
                                            em ${processes.values.polo_ativo.abertos.qtd} Processo(s)`
                      : `${processes.values.polo_ativo.fechados.valor_total}
                                            em ${processes.values.polo_ativo.fechados.qtd} Processo(s)`)}
                </p>
                <p>
                  Total de Processos Passivos:&nbsp;
                  {processes &&
                    processes.values.polo_passivo &&
                    (active
                      ? `${processes.values.polo_passivo.abertos.valor_total}
                                            em ${processes.values.polo_passivo.abertos.qtd} Processo(s)`
                      : `${processes.values.polo_passivo.fechados.valor_total}
                                            em ${processes.values.polo_passivo.fechados.qtd} Processo(s)`)}
                </p>
              </TableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Data de Distribuição</StyledTableCell>
              <StyledTableCell>Nº do Processo</StyledTableCell>
              <StyledTableCell>Orgão Julgador</StyledTableCell>
              <StyledTableCell>Valor da Ação</StyledTableCell>
              <StyledTableCell>Polo</StyledTableCell>
              <StyledTableCell>Área do Direito</StyledTableCell>
              <StyledTableCell>Classe</StyledTableCell>
              <StyledTableCell>Assunto</StyledTableCell>

              <StyledTableCell>Pessoas Relacionadas</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {processes ? (
              processes.processes.map(
                ({
                  distribution_date,
                  number,
                  court,
                  declared_value,
                  pole,
                  nature,
                  kind,
                  subject,
                  related_people,
                  status
                }) =>
                  status === status_process && (
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        {distribution_date}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <a
                          href={`https://www.jusbrasil.com.br/consulta-processual/busca?q=${number}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {number}
                        </a>
                      </StyledTableCell>
                      <StyledTableCell align="left">{court}</StyledTableCell>
                      <StyledTableCell align="left">
                        {declared_value}
                      </StyledTableCell>

                      <StyledTableCell align="left">{pole}</StyledTableCell>
                      <StyledTableCell align="left">{kind}</StyledTableCell>
                      <StyledTableCell align="left">{nature}</StyledTableCell>
                      <StyledTableCell align="left">{subject}</StyledTableCell>
                      <StyledTableCell align="left">
                        {related_people.map((person) => (
                          <p className={person.is_target && classes.markTarget}>
                            {person.name?.toUpperCase()} ({person.role})
                          </p>
                        ))}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
              )
            ) : (
              <StyledTableRow>
                <StyledTableCell align="center" colSpan={9}>
                  Nenhum Resultado Encontrado.
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableJusbrasil;
