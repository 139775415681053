import {
  Button,
  createStandaloneToast,
  extendTheme,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import axios from 'axios';
import React from 'react';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../../../../constants/urls';
import { RiAlertFill } from 'react-icons/ri';

const DeleteModal = ({
  initialState,
  changeState,
  isLoading,
  setIsLoading,
  typeId,
  type
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const token = localStorage.getItem('token');
  const params = useParams();
  const productId = params.id;
  const id = 'test';

  const theme = extendTheme({
    fonts: {
      heading: 'Open Sans',
      body: 'Raleway'
    }
  });

  const toast = createStandaloneToast({ theme: theme });

  const updateData = async () => {
    setIsLoading(true);

    let urlToUpdate = '';
    if (type === 'Contexto') {
      urlToUpdate = `${BASE_URL}/product/${productId}/context/${typeId}`;
    } else {
      urlToUpdate = `${BASE_URL}/product/${productId}/service/${typeId}`;
    }

    const response = await axios
      .put(
        urlToUpdate,

        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then((res) => {
        changeState(initialState.filter((item) => item.id !== typeId));
        onClose();
        setIsLoading(false);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: `${type} excluído com sucesso!`,
            status: 'success',
            duration: 9000,
            isClosable: true
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        const msg = error?.response?.data?.detail?.map((msg) => msg.msg);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: `Houve um erro ao excluir o ${type}`,
            description: msg,
            status: 'error',
            duration: 9000,
            isClosable: true
          });
          setIsLoading(false);
        }
      });
  };

  return (
    <>
      <Button onClick={onOpen} colorScheme="pink">
        Excluir
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex>
              <Icon as={RiAlertFill} mt="6px" mr="2" />
              <p>Alerta</p>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="2xl">Tem certeza que deseja continuar?</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              isLoading={isLoading}
              colorScheme="pink"
              onClick={() => updateData()}
            >
              Sim
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteModal;
