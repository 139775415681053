import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { H3 } from '../../styled';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: '100%',
    marginBottom: '10px'
  }
});

export function CardNegative({ negative }) {
  const classes = useStyles();
  const { apontamentos, pendencies_control_cred } = negative;

  const qtd_apontamentos = apontamentos?.apontamento
    ? apontamentos.apontamento.length
    : 0;
  const total_pendencies = pendencies_control_cred
    ? pendencies_control_cred
    : 0;

  return (
    <>
      <H3>
        Valor financeiro total de negativações: R$ {total_pendencies} em{' '}
        {qtd_apontamentos} negativações
      </H3>
      {apontamentos?.apontamento.map(
        ({
          cnpj,
          company_name,
          nature,
          amount,
          contract_number,
          date_occurred,
          participant_type,
          apontamento_status,
          date_included,
          address
        }) => (
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h6">
                Empresa Negativadora: {company_name}
              </Typography>
              <Typography variant="h6">CNPJ: {cnpj}</Typography>
              <Typography color="textSecondary">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <p>
                      <strong>Valor:</strong> {amount}
                    </p>
                    <p>
                      <strong>Número do Contrato:</strong> {contract_number}
                    </p>
                    <p>
                      <strong>Código da Natureza da Operação:</strong> {nature}
                    </p>
                    <p>
                      <strong>Data da Inclusão:</strong> {date_included}
                    </p>
                    <p>
                      <strong>Data da Ocorrência:</strong> {date_occurred}
                    </p>
                    <p>
                      <strong>Tipo de Participante:</strong> {participant_type}
                    </p>
                    <p>
                      <strong>Status do Apontamento:</strong>{' '}
                      {apontamento_status}
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p>
                      <strong>Cidade:</strong> {address?.city}
                    </p>
                    <p>
                      <strong>UF:</strong> {address?.state}
                    </p>
                    <p>
                      <strong>Bairro:</strong> {address?.neighborhood}
                    </p>
                    <p>
                      <strong>Código Postal:</strong> {address?.postal_code}
                    </p>
                    <p>
                      <strong>Número:</strong> {address?.number}
                    </p>
                    <p>
                      <strong>Logradouro:</strong> {address?.street}
                    </p>
                  </Grid>
                </Grid>
              </Typography>
            </CardContent>
          </Card>
        )
      )}
    </>
  );
}
