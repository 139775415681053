import * as yup from 'yup';

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email('Por favor digite um e-mail válido.')
    .required('Campo obrigatório.'),
  password: yup
    .string()
    .required('Por favor digite uma senha válida.')
    .min(5, 'Sua senha deve conter um mínimo de 5 caracteres.')
});

export { loginSchema };
