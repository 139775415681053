import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BASE_URL } from '../constants/urls';
import { goToDetails } from '../routes/coordinator';
import {
  credit_recovery,
  credit_recovery_jr,
  due_diligence_ma,
  preventive,
  compliance,
  divorce,
  inventary,
  docs,
  joker,
  imobiliary,
  due_diligence_pre,
  credit_analysis
} from './returnJSONValues';

const sendForm = async (value, targets, caseData, data, history) => {
  let reqBody = {};
  const token = localStorage.getItem('token');

  const onClickDetails = (id) => {
    goToDetails(history, id);
  };

  if (value == 'credit_recovery') {
    reqBody = {
      client_id: caseData?.client,
      name: data?.name,
      product_id: caseData?.product,
      context_id: caseData?.context,
      context_form: credit_recovery(data),
      targets: targets
    };
  } else if (value == 'credit_recovery_jr') {
    reqBody = {
      client_id: caseData?.client,
      name: data?.name,
      product_id: caseData?.product,
      context_id: caseData?.context,
      context_form: credit_recovery_jr(data),
      targets: targets
    };
  } else if (value == 'due_diligence_ma') {
    reqBody = {
      client_id: caseData?.client,
      name: data?.name,
      product_id: caseData?.product,
      context_id: caseData?.context,
      context_form: due_diligence_ma(data),
      targets: targets
    };
  } else if (value == 'preventive') {
    reqBody = {
      client_id: caseData?.client,
      name: data?.name,
      product_id: caseData?.product,
      context_id: caseData?.context,
      context_form: preventive(data),
      targets: targets
    };
  } else if (value == 'compliance') {
    reqBody = {
      client_id: caseData?.client,
      name: data?.name,
      product_id: caseData?.product,
      context_id: caseData?.context,
      context_form: compliance(data),
      targets: targets
    };
  } else if (value == 'divorce') {
    reqBody = {
      client_id: caseData?.client,
      name: data?.name,
      product_id: caseData?.product,
      context_id: caseData?.context,
      context_form: divorce(data),
      targets: targets
    };
  } else if (value == 'inventary') {
    reqBody = {
      client_id: caseData?.client,
      name: data?.name,
      product_id: caseData?.product,
      context_id: caseData?.context,
      context_form: inventary(data),
      targets: targets
    };
  } else if (value == 'docs') {
    reqBody = {
      client_id: caseData?.client,
      name: data?.name,
      product_id: caseData?.product,
      context_id: caseData?.context,
      context_form: docs(data),
      targets: targets
    };
  } else if (value == 'joker') {
    reqBody = {
      client_id: caseData?.client,
      name: data?.name,
      product_id: caseData?.product,
      context_id: caseData?.context,
      context_form: joker(data),
      targets: targets
    };
  } else if (value == 'imobiliary') {
    reqBody = {
      client_id: caseData?.client,
      name: data?.name,
      product_id: caseData?.product,
      context_id: caseData?.context,
      context_form: imobiliary(data),
      targets: targets
    };
  } else if (value == 'due_diligence_pre') {
    reqBody = {
      client_id: caseData?.client,
      name: data?.name,
      product_id: caseData?.product,
      context_id: caseData?.context,
      context_form: due_diligence_pre(data),
      targets: targets
    };
  } else if (value == 'credit_analysis') {
    reqBody = {
      client_id: caseData?.client,
      name: data?.name,
      product_id: caseData?.product,
      context_id: caseData?.context,
      context_form: credit_analysis(data),
      targets: targets
    };
  } else {
    reqBody = {
      client_id: caseData?.client,
      name: data?.name,
      product_id: caseData?.product,
      context_id: caseData?.context,
      targets: targets
    };
  }

  await axios
    .post(`${BASE_URL}/case`, reqBody, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
      toast.success('Caso cadastrado com sucesso!');
      onClickDetails(response.data.uuid);
    })
    .catch((error) => {
      toast.error('Falha ao criar caso!');

      // setTimeout(() => {
      //   window.location.reload();
      // }, 5000);
    });

  return false;
};
export default sendForm;
