import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { primaryColor } from '../../../../../../constants/colors';
import { Paper } from '@material-ui/core';

import { Li } from '../styled';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%'
  },
  markTarget: {
    fontWeight: 'bold',
    color: 'red'
  }
}));

export default function FrontProcessPassive({ front }) {
  const classes = useStyles();

  return (
    <>
      <li>
        <h2>
          Frente do(s) processo(s) em que um ou mais pesquisado(s) figura(m) no
          polo passivo.
        </h2>
      </li>
      <ul>
        <Li>
          <strong>Considerações e/ou fatos relevantes:</strong>
          <ul>
            <Li>
              Considerando que nos processos abaixo aparentemente figuram
              simultaneamente no polo passivo algum dos pesquisados e mais
              outra(s) pessoa(s):
            </Li>
          </ul>
        </Li>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Data de Distribuição</StyledTableCell>
                <StyledTableCell>Nº do Processo</StyledTableCell>
                <StyledTableCell>Orgão Julgador</StyledTableCell>
                <StyledTableCell>Valor da Ação</StyledTableCell>
                <StyledTableCell>Polo</StyledTableCell>
                <StyledTableCell>Pessoas Relacionadas</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {front.rule.map(
                ({
                  distribution_date,
                  number,
                  court,
                  declared_value,
                  pole,
                  related_people
                }) => (
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                      {distribution_date}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <a
                        href={`https://www.jusbrasil.com.br/consulta-processual/busca?q=${number}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {number}
                      </a>
                    </StyledTableCell>
                    <StyledTableCell align="left">{court}</StyledTableCell>
                    <StyledTableCell align="left">
                      {declared_value}
                    </StyledTableCell>
                    <StyledTableCell align="left">{pole}</StyledTableCell>
                    <StyledTableCell align="left">
                      {related_people.map((person) => (
                        <p className={person.target && classes.markTarget}>
                          {person.name.toUpperCase()} ({person.role})
                        </p>
                      ))}
                    </StyledTableCell>
                  </StyledTableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Li>
          <strong>Conclusões e/ou recomendações técnicas</strong>.
          <ul>
            <Li>
              Recomenda-se estudar a oportunidade e conveniência estratégica,
              bem como consultar advogado sobre a viabilidade jurídica de se
              obter as cópias integrais desses processos a fim de estudá-los e
              entender quais foram os motivos de sua instauração, qual foi a
              justa causa utilizada para que mais de uma pessoa constasse no
              polo passivo, e qual foi o seu desfecho (ou qual é o estado atual,
              caso ainda estejam ativos).
            </Li>
            <Li>
              Estudar por qual razão constam simultaneamente como “
              <strong>REQUERIDO</strong>” as pessoas acima indicadas, podendo
              tal aferição revelar importantes indícios que contribuam para
              provar confusão patrimonial, desvio de finalidade, grupo econômico
              qualificado, sociedade de fato, sucessão empresarial, entre
              outros.
            </Li>
            <Li>
              Sendo a justa causa aproveitável para o interessado, que se
              transponha como prova emprestada a documentação de interesse, a
              fim de instruir futura medida tendente a resolver o caso,
              especialmente com extensão da responsabilidade.
            </Li>
          </ul>
        </Li>
        <Li>
          Caso haja interesse em solicitar auxílio para tais tarefas e análises,{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://forms.monday.com/forms/53cda30c0b17fcb6c85e4a23a3ec7fec?r=use1"
          >
            <strong>clique aqui</strong>
          </a>
          .
        </Li>
      </ul>
    </>
  );
}
