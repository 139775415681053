import { 
  Paper,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { tableHeadSmart } from '../../../../../../constants/colors';
import { Body1, Body2, Li } from '../styled';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: tableHeadSmart,
    color: theme.palette.common.black,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  body: {
    fontSize: 14,
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%'
  }
}));

export default function AveriguacaoEcossistema({ front }) {
  const classes = useStyles();

  return (
    <>
      <h2>
        <li>{front.front}</li>
      </h2>
      <Body1>
        Considerando que em uma diligência preventiva é importante levar em conta não só os negócios 
        e relações jurídicas daqueles com quem se pretende diretamente contratar, mas também das pessoas mais próximas, 
        que infelizmente são utilizadas, em vários casos, como forma de ocultar problemas relevantes, ou cujas dívidas 
        e responsabilidades podem se espraiar e contaminar, no futuro, o negócio que se pretende celebrar; 
        considerando que no ecossistema de pessoas relacionadas ao caso foram encontrados os seguintes SÓCIOS, EMPRESAS, 
        COLIGADAS POR POSSÍVEL GRUPO ECONÔMICO ou FAMILIARES PRÓXIMOS relevantes do(a, os, as) pesquisado(a, os, as):
      </Body1>
            
      <TableContainer component={Paper} style={{ marginTop: '10px' }}>
        <Table
          className={classes.table}
          size="small"
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>NOME</StyledTableCell>
              <StyledTableCell>CPF/CNPJ</StyledTableCell>
              <StyledTableCell>RELAÇÃO COM O CASO</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {front.rule.map(({ name, cpf_cnpj, relation }, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {name}
                </StyledTableCell>
                <StyledTableCell align="left">{cpf_cnpj}</StyledTableCell>
                <StyledTableCell align="left">{relation}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Body1>
        Assim sendo, com relação à <strong>frente do ecossistema</strong>, 
        sugere-se estudar a viabilidade das seguintes medidas: 
      </Body1>
      <Body2>
        <Li>
          A depender do nível de risco do negócio, do contexto negocial e do valor do crédito a ser concedido, 
          verificar a possibilidade de se encomendar relatórios sobre essas pessoas, inclui-las como novas 
          pesquisadas no dossiê, ou aprofundar as investigações com relação a elas, desde que o contexto do caso 
          permita fazê-lo legitimamente e atendendo as leis aplicáveis à proteção dos dados pessoais.
        </Li>
      </Body2>
      <Li>
        <strong>
            Caso haja interesse em solicitar auxílio para tais tarefas e análises, {' '}
            <a target="_blank" rel="noreferrer" href="mailto:vega@inquest.com.br?r=use1">
                clique aqui
            </a> ou {' '}
            <a target="_blank" rel="noreferrer" href="mailto:vega@inquest.com.br?r=use1">
                entre em contato conosco
            </a>.
        </strong>
      </Li>
    </>
  );
}
