import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { primaryColor } from '../../../../constants/colors';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: '#E6E6E6'
  },
  table: {
    width: '100%'
  }
}));

export default function Marcas({ data }) {
  const classes = useStyles();

  return (
    <Typography className={classes.title} variant="h6" align="center">
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={6}>
                <Typography variant="h6">MARCAS</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Número</StyledTableCell>
              <StyledTableCell>Prioridade</StyledTableCell>
              <StyledTableCell>Marca</StyledTableCell>
              <StyledTableCell>Titular</StyledTableCell>
              <StyledTableCell>Situação</StyledTableCell>
              <StyledTableCell>Classe</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length ? (
              data.map(
                ({ numero, prioridade, marca, titular, situacao, classe }) => (
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                      {numero}
                    </StyledTableCell>
                    <StyledTableCell align="left">{prioridade}</StyledTableCell>
                    <StyledTableCell align="left">{marca}</StyledTableCell>
                    <StyledTableCell align="left">{titular}</StyledTableCell>
                    <StyledTableCell align="left">{situacao}</StyledTableCell>
                    <StyledTableCell align="left">{classe}</StyledTableCell>
                  </StyledTableRow>
                )
              )
            ) : (
              <StyledTableRow>
                <StyledTableCell align="center" colSpan={6}>
                  Sem Resultados
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Typography>
  );
}
