import styled from 'styled-components';

export const Li = styled.li`
  margin: 1rem 0 1rem 3rem;
  text-align: justify;
`;

export const Body1 = styled.div`
  margin: 1rem 0;
  text-align: justify;
  text-indent: 3rem;
`;

export const Body2 = styled.div`
  margin-left: 25%;
  text-align: justify;
`;
