import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, AppBar, Tabs, Box, Badge, Tab } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import CardArquivamentos from './CardArquivamentos';
import CardEmpresa from './CardEmpresa';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

const StyledBadge = withStyles((theme) => ({
  badge: {
    left: 50,
    padding: '0 4px'
  }
}))(Badge);

const RenderData = data => {

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return data.error ? (
    <>
      <Alert severity="warning">
        <AlertTitle>Aviso!</AlertTitle>
        Empresa: <strong>{data?.name}</strong> <br />
        CNPJ : <strong>{data?.cnpj}</strong> <br />
        Mensagem: <strong>{data.msg}</strong><br />
      </Alert>
      <br />
    </>
  ) :
    (
      <>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Tabs Jucesp"
          >
            <Tab label="Empresa" {...a11yProps(0)} />
            <Tab
              label="Arquivamentos"
              {...a11yProps(1)}
              icon={
                <StyledBadge
                  color="secondary"
                  badgeContent={data.arquivamentos?.length}
                />
              }
            />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          <CardEmpresa data={data} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CardArquivamentos arquivamentos={data.arquivamentos} />
        </TabPanel>
      </>
    );

}

export default function Infosimples({ data }) {


  return (
    Array.isArray(data) ? (
      <>
        {
          data.map((val) => (
            RenderData(val)
          ))
        }

      </>
    ) : (
      RenderData(data)

    )

  );


}
