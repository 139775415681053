import {
  Card,
  Grid,
  Typography,
  CardContent,
  Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: '100%',
    marginBottom: '10px'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  actual: {
    border: '2px solid #81c784'
  },
  notActual: {
    border: '2px solid #e57373'
  }
});

export function CardRf({ data }) {
  const classes = useStyles();

  return data?.length ? (
    data.map(({ company, addr, qsa, cadastral, actual }) => (
      <Card className={classes.root} variant="outlined">
        <CardContent className={actual ? classes.actual : classes.notActual}>
          <Typography variant="h6">
            {company.name} - {company.cnpj}
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <p>
                  <strong>Email:</strong> {company.email}
                </p>
                <p>
                  <strong>Data de Abertura:</strong> {company.start_date}
                </p>
                <p>
                  <strong>Capital Social:</strong> {company.capital_social}
                </p>
                <p>
                  <strong>CNAE:</strong> {company.cnae}
                </p>
                <p>
                  <strong>Natureza Legal:</strong> {company.legal_nature?.code}{' '}
                  - {company.legal_nature?.description}
                </p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <p>
                  <strong>Situação:</strong> {cadastral.situation}
                </p>
                <p>
                  <strong>Data da Situação:</strong> {cadastral.date}
                </p>
                <p>
                  <strong>Motivo da Situação:</strong> {cadastral.reason}
                </p>
              </Grid>
            </Grid>
          </Typography>
          {addr && (
            <CardContent>
              <Divider />
              <Typography
                color="textSecondary"
                display="block"
                variant="caption"
                align="center"
              >
                Endereço
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <p>
                    <strong>Logradouro:</strong> {addr.street}
                  </p>
                  <p>
                    <strong>Número:</strong> {addr.number}
                  </p>
                  <p>
                    <strong>Complemento:</strong> {addr.complement}
                  </p>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <p>
                    <strong>CEP:</strong> {addr.zipcode}
                  </p>
                  <p>
                    <strong>Cidade:</strong> {addr.city}
                  </p>
                  <p>
                    <strong>Estado:</strong> {addr.province}
                  </p>
                  <p>
                    <strong>Bairro:</strong> {addr.neighborhood}
                  </p>
                  <p>
                    <strong>Telefone 1:</strong> {addr.phone_0}
                  </p>
                </Grid>
              </Grid>
            </CardContent>
          )}
          {qsa.length > 0 && (
            <CardContent>
              <Divider />
              <Typography
                color="textSecondary"
                display="block"
                variant="caption"
                align="center"
              >
                Quadro de Sócios e Administradores
              </Typography>
              {qsa.map((socio) => (
                <Typography variant="body2" component="p">
                  {socio.name} - {socio.cpf_cnpj} ({socio.type})
                </Typography>
              ))}
            </CardContent>
          )}
        </CardContent>
      </Card>
    ))
  ) : (
    <p>Nenhum resultado encontrado.</p>
  );
}
