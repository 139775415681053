import styled from 'styled-components';
import { Button, FormControl } from '@material-ui/core';
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone';
import BubbleChartTwoToneIcon from '@material-ui/icons/BubbleChartTwoTone';
import FormatListBulleted from '@material-ui/icons/FormatListBulleted';

export const Container = styled.div`
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4a4a4a;
  @media screen and (max-width: 768px) {
    margin-top: -30px;
  }
`;

export const Card = styled.div`
  padding: 2rem;
  width: 100%;
  height: auto;
  border-radius: 4px;
  background-color: white;
  display: flex;
  flex-direction: column;
  color: #4a4a4a;
`;

export const H1 = styled.h1`
  margin-bottom: 1rem;
`;

export const H4 = styled.h4`
  margin-bottom: 1rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

export const StyledFormControl = styled(FormControl)`
  width: 10rem;
  margin: 0;
  padding: 0;
  margin-left: 1rem;
  color: #4a4a4a;
`;

export const StyledButton = styled(Button)`
  padding: 0.7rem;
  margin-right: 1rem;
  width: auto;
  @media screen and (max-width: 768px) {
    font-size: 0;
    padding: 1rem;
    height: 50px;
  }
`;

export const StyledRefresh = styled(Button)`
  padding: 0.7rem;
  width: 200px;
  @media screen and (max-width: 768px) {
    padding: 1rem;
    height: 50px;
    font-size: 8px;
    width: 100%;
  }
`;

export const StyledSendData = styled(Button)`
  padding: 0.7rem;
  width: auto;
  margin-right: 1rem;
  @media screen and (max-width: 768px) {
    padding: 1rem;
    height: 50px;
    font-size: 8px;
    width: 100%;
  }
`;

export const GrafoIcon = styled(BubbleChartTwoToneIcon)`
  margin-left: 0.5rem;
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`;

export const SmartIcon = styled(DescriptionTwoToneIcon)`
  margin-left: 0.5rem;
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`;

export const ExcelIcon = styled(FormatListBulleted)`
  margin-left: 0.5rem;
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`;

export const RefreshContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;
  width: 100%;
  @media screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`;
