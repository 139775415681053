import { Button, FormControl, TextField } from '@material-ui/core';
import styled from 'styled-components';
import { primaryColor } from '../../constants/colors';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  color: #4a4a4a;
  @media screen and (max-width: 1250px) {
    flex-direction: column;
  }
`;

export const StyledFormControl = styled(FormControl)`
  margin: 10px;
  @media screen and (max-width: 1250px) {
    width: 100%;
  }
`;

export const StyledButton = styled(Button)`
  @media screen and (max-width: 1250px) {
    width: 100%;
  }
`;

export const ContextContainer = styled.div`
  width: 100%;
  border: 1px solid ${primaryColor};
  border-radius: 5px;
`;

export const StyledTextField = styled(TextField)`
  margin: 10px;
`;
