import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  Container,
  EmptyContainer,
  SearchContainer,
  StyledButton,
  StyledTextField,
  TrueIcon
} from './styled';
import { Button, Box, CircularProgress, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { goToDetails } from '../../routes/coordinator';
import useProtectedPage from '../../hooks/useProtectedPage';
import api from '../../services/api/api';
import { ToastContainer } from 'react-toastify';
import Pagination from '@material-ui/lab/Pagination';
import { Warning } from '@material-ui/icons';

const StyledTableCell = withStyles((theme) => ({
  head: {
    background: '#CF0865',
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700
  }
});

const CasesTable = () => {
  useProtectedPage();
  const token = localStorage.getItem('token');
  const classes = useStyles();
  const history = useHistory();
  const [cases, setCases] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [actualPage, setActualPage] = useState(0);
  const [caseName, setCaseName] = useState('');
  const [pages, setPages] = useState(0);

  const pagination = async ({ pageNumber, caseName }) => {
    setIsLoading(true);
    const params = new URLSearchParams([
      ['limit', '20'],
      ['page', pageNumber.toString()]
    ]);

    if (caseName) {
      params.append('q', caseName);
    }

    const response = await api.get(`/case`, { params });

    const data = response.data;
    const cases = data.data;

    if (response?.data?.data.length > 0) {
      setCases(cases);
      setActualPage(pageNumber);
      setPages(pageNumber + 1);
      setIsLoading(false);
    } else if (response?.data?.data.length < 20) {
      setCases(cases);
      setActualPage(pageNumber);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const onClickDetails = (id) => {
    goToDetails(history, id);
  };

  const handleChangeCaseName = (event) => {
    setCaseName(event.target.value);
  };

  const returnIcon = (status) => {
    if (status === 'Processado') {
      return <TrueIcon color="secondary" size={20} />;
    } else if (status === 'Pendente') {
      return <Warning color="secondary" size={20} />;
    } else {
      return <CircularProgress size={20} />;
    }
  };

  const displayCases = cases.map((row) => {
    return (
      <TableBody key={row.id}>
        <StyledTableRow>
          <StyledTableCell component="th" scope="row">
            {row.name}
          </StyledTableCell>
          <StyledTableCell align="left">{row?.product.name}</StyledTableCell>
          <StyledTableCell align="left">{row?.context.name}</StyledTableCell>
          <StyledTableCell align="left">{row?.client.name}</StyledTableCell>

          <StyledTableCell align="left">{row?.client.cpf_cnpj}</StyledTableCell>
          <StyledTableCell align="center">{row?.created_at}</StyledTableCell>
          <StyledTableCell align="center">
            <Tooltip title={row.status} placement="top">
              {returnIcon(row.status)}
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell align="center">
            <Button
              onClick={() => onClickDetails(row.uuid)}
              variant="outlined"
              size="small"
              color="primary"
            >
              Ver detalhes
            </Button>
          </StyledTableCell>
        </StyledTableRow>
      </TableBody>
    );
  });

  useEffect(() => {
    (async () => {
      try {
        await pagination({
          pageNumber: 1
        });
        setIsLoadingPage(false);
      } catch (error) {}
    })();
  }, [token]);

  return (
    <Container>
      <ToastContainer />
      {isLoadingPage ? (
        <CircularProgress size={80} />
      ) : (
        <>
          <SearchContainer>
            <StyledTextField
              id="outlined-basic"
              on
              onChange={handleChangeCaseName}
              label="Digite para pesquisar"
              variant="outlined"
              size="small"
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  pagination({
                    pageNumber: 0,
                    caseName: caseName
                  });
                }
              }}
            />
            <StyledButton
              onClick={() =>
                pagination({
                  pageNumber: 0,
                  caseName: caseName
                })
              }
              variant="outlined"
              color="primary"
              size="large"
            >
              Pesquisar
            </StyledButton>
          </SearchContainer>
          <TableContainer component={Paper}>
            {cases.length === 0 ? (
              <EmptyContainer>
                <strong>Nenhum caso encontrado.</strong>
              </EmptyContainer>
            ) : (
              <>
                {isLoading ? (
                  <EmptyContainer>
                    <CircularProgress size={80} />
                  </EmptyContainer>
                ) : (
                  <>
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Nome</StyledTableCell>
                          <StyledTableCell>Produto</StyledTableCell>
                          <StyledTableCell>Contexto</StyledTableCell>
                          <StyledTableCell align="left">
                            Cliente
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            CPF/CNPJ Solicitante
                          </StyledTableCell>
                          <StyledTableCell align="center">Data</StyledTableCell>
                          <StyledTableCell align="center">
                            Status
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Detalhes
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <>{displayCases}</>
                    </Table>
                  </>
                )}
              </>
            )}
          </TableContainer>
          <Box py={3} display="flex" justifyContent="center">
            <Pagination
              page={actualPage}
              count={pages}
              color="secondary"
              variant="outlined"
              onChange={(e, pageNumber) =>
                pagination({
                  pageNumber: pageNumber,
                  caseName: caseName
                })
              }
            />
          </Box>
        </>
      )}
    </Container>
  );
};

export default CasesTable;
