import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import CaseDetailsPage from '../pages/CaseDetails';
import ErrorPage from '../pages/Error';
import HomePage from '../pages/Home';
import LoginPage from '../pages/Login';
import NewCasePage from '../pages/NewCase';
import Smart from '../pages/CaseDetails/Smart';
import SearchPage from '../pages/Search';
import ErrorBoundary from '../pages/Error/ErrorBoundary';
import Users from '../pages/CadGeral/users';
import Services from '../pages/CadGeral/services';
import Products from '../pages/CadGeral/products';
import Contexts from '../pages/CadGeral/contexts';
import { ChakraProvider } from '@chakra-ui/react';
import { chakraTheme } from '../styles/chakraTheme';
import AdminPage from '../pages/CadGeral';
import UserDetails from '../pages/CadGeral/users/details';
import CreateUser from '../pages/CadGeral/users/create';
import CreateService from '../pages/CadGeral/services/create';
import ServicesDetails from '../pages/CadGeral/services/details';
import CreateProduct from '../pages/CadGeral/products/create';
import ProductDetails from '../pages/CadGeral/products/details';
import CreateContext from '../pages/CadGeral/contexts/create';
import ContextDetails from '../pages/CadGeral/contexts/details';

const Router = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route exact path="/login">
            <LoginPage />
          </Route>
          <Route exact path="/caso/:id">
            <CaseDetailsPage />
          </Route>
          <Route exact path="/novo-caso">
            <NewCasePage />
          </Route>
          <Route exact path="/search">
            <SearchPage />
          </Route>
          <Route exact path="/cadgeral">
            <ChakraProvider theme={chakraTheme}>
              <AdminPage />
            </ChakraProvider>
          </Route>
          <Route exact path="/cadgeral/users">
            <ChakraProvider theme={chakraTheme}>
              <Users />
            </ChakraProvider>
          </Route>
          <Route exact path="/cadgeral/users/create">
            <ChakraProvider theme={chakraTheme}>
              <CreateUser />
            </ChakraProvider>
          </Route>
          <Route exact path="/cadgeral/users/:id">
            <ChakraProvider theme={chakraTheme}>
              <UserDetails />
            </ChakraProvider>
          </Route>
          <Route exact path="/cadgeral/services">
            <ChakraProvider theme={chakraTheme}>
              <Services />
            </ChakraProvider>
          </Route>
          <Route exact path="/cadgeral/services/create">
            <ChakraProvider theme={chakraTheme}>
              <CreateService />
            </ChakraProvider>
          </Route>
          <Route exact path="/cadgeral/services/:id">
            <ChakraProvider theme={chakraTheme}>
              <ServicesDetails />
            </ChakraProvider>
          </Route>
          <Route exact path="/cadgeral/products">
            <ChakraProvider theme={chakraTheme}>
              <Products />
            </ChakraProvider>
          </Route>
          <Route exact path="/cadgeral/products/create">
            <ChakraProvider theme={chakraTheme}>
              <CreateProduct />
            </ChakraProvider>
          </Route>
          <Route exact path="/cadgeral/products/:id">
            <ChakraProvider theme={chakraTheme}>
              <ProductDetails />
            </ChakraProvider>
          </Route>
          <Route exact path="/cadgeral/contexts">
            <ChakraProvider theme={chakraTheme}>
              <Contexts />
            </ChakraProvider>
          </Route>
          <Route exact path="/cadgeral/contexts/create">
            <ChakraProvider theme={chakraTheme}>
              <CreateContext />
            </ChakraProvider>
          </Route>
          <Route exact path="/cadgeral/contexts/:id">
            <ChakraProvider theme={chakraTheme}>
              <ContextDetails />
            </ChakraProvider>
          </Route>
          <Route exact path="/smart/:id">
            <Smart />
          </Route>
          <Route>
            <ErrorPage />
          </Route>
        </Switch>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default Router;
