import { Li } from '../styled';

export default function FrontAddresses({front}) {
    return (
        <>
            <li>
                <h2>{front.front}</h2>
            </li>
            <ul>
                <Li><strong>Considerandos:</strong>
                    <ul>
                        {front.rule.map((r) => (
                        <Li>
                            Considerando que o(a) investigado(a) “<strong>{r.name} - {r.cpf_cnpj}</strong>” aparece vinculado aos seguintes endereços:
                            {r.result.map((addr) => (
                                <Li>
                                    {addr.complement}, {addr.number ? addr.number : "S/N"} - {addr.neighborhood} - {addr.city}/{addr.province}, CEP: {addr.zipcode}
                                </Li>
                            ))}
                        </Li>
                        ))}
                        <Li>
                            Considerando que é comum a prática de ocultar imóveis por sua não titularização direta, ou considerando que, 
                            no caso de locação, trata-se de um contrato com alta qualidade de indicar padrão econômico e origem de recursos do(a) pesquisado(a);
                        </Li>
                    </ul>
                </Li>
                <Li>
                    Tendo em vista essas considerações e o que mais constou nas pesquisas, a <strong>conclusão e/ou recomendação técnica</strong> é(são) a(s) abaixo indicada(s), 
                    sugerindo-se desde logo consultar advogado sobre a viabilidade jurídica e estudar a conveniência e oportunidade estratégicas dos seguintes atos:
                    <ul>
                        <Li>
                            Aprofundar quais endereços se sabe que o(s) pesquisado(s) efetivamente tem posse, por exemplo, 
                            indagando a cliente ou pessoa que os conheça, enviando carta com A.R., 
                            verificando em outros processos aqueles em que houve citação ou intimação postal positiva, 
                            realizando diligências in loco etc.
                        </Li>
                        <Li>
                            Descobrir quais são e adquirir as matrículas correspondentes aos endereços acima indicados;
                        </Li>
                        <Li>
                            Realizar diligências in loco para confirmar os ocupantes atuais do imóvel e se os endereços indicados são, de fato, os atualizados;
                        </Li>
                        <Li>
                            Em caso de o imóvel ser encontrado diretamente em nome do(a) investigado(a) ou em nome de pessoa próxima cuja renda seja aparentemente incompatível com a aquisição, 
                            tomar as providências cabíveis, inclusive com medidas que tendam ao reconhecimento da interposição de pessoas ou fraude, na segunda hipótese;
                        </Li>
                        <Li>
                            Em caso de o imóvel ser encontrado em nome de terceiro não vinculado, subordinado ou próximo ao investigado(a), 
                            que seja então intimado a esclarecer a que título o pesquisado(a) ocupa o imóvel em questão, 
                            apresentando a devida comprovação de tudo o que afirmar e, em caso de locação ou algum outro contrato a título oneroso, 
                            que forneça informações sobre como os pagamentos são realizados, em quais valores e de onde partem os numerários, 
                            indicando o(a) titular da conta bancária ou bens transferidos com esse fim.
                        </Li>
                    </ul>
                </Li>
                <Li>Caso haja interesse em solicitar auxílio para tais tarefas 
                    e análises, <a target="_blank" rel="noreferrer" href="https://forms.monday.com/forms/53cda30c0b17fcb6c85e4a23a3ec7fec?r=use1"><strong>clique aqui</strong></a>.
                </Li>
            </ul>
        </>
    )
}