/* eslint-disable */
!(function (t, e) {
    'function' == typeof define && define.amd
        ? define(e)
        : 'object' == typeof exports
        ? (module.exports = e())
        : (t.VMasker = e());
})(this, function () {
    var t = [9, 16, 17, 18, 36, 37, 38, 39, 40, 91, 92, 93],
        e = function (e) {
            for (var n = 0, i = t.length; n < i; n++) if (e == t[n]) return !1;
            return !0;
        },
        n = function (t) {
            return (
                ((t = {
                    delimiter: (t = t || {}).delimiter || '.',
                    lastOutput: t.lastOutput,
                    precision: t.hasOwnProperty('precision') ? t.precision : 2,
                    separator: t.separator || ',',
                    showSignal: t.showSignal,
                    suffixUnit:
                        (t.suffixUnit &&
                            ' ' + t.suffixUnit.replace(/[\s]/g, '')) ||
                        '',
                    unit: (t.unit && t.unit.replace(/[\s]/g, '') + ' ') || '',
                    zeroCents: t.zeroCents,
                }).moneyPrecision = t.zeroCents ? 0 : t.precision),
                t
            );
        },
        i = function (t, e, n) {
            for (; e < t.length; e++)
                ('9' !== t[e] && 'A' !== t[e] && 'S' !== t[e]) || (t[e] = n);
            return t;
        },
        r = function (t) {
            this.elements = t;
        };
    (r.prototype.unbindElementToMask = function () {
        for (var t = 0, e = this.elements.length; t < e; t++)
            (this.elements[t].lastOutput = ''),
                (this.elements[t].onkeyup = !1),
                (this.elements[t].onkeydown = !1),
                this.elements[t].value.length &&
                    (this.elements[t].value = this.elements[t].value.replace(
                        /\D/g,
                        '',
                    ));
    }),
        (r.prototype.bindElementToMask = function (t) {
            for (
                var n = this,
                    i = function (i) {
                        var r = (i = i || window.event).target || i.srcElement;
                        e(i.keyCode) &&
                            setTimeout(function () {
                                (n.opts.lastOutput = r.lastOutput),
                                    (r.value = o[t](r.value, n.opts)),
                                    (r.lastOutput = r.value),
                                    r.setSelectionRange &&
                                        n.opts.suffixUnit &&
                                        r.setSelectionRange(
                                            r.value.length,
                                            r.value.length -
                                                n.opts.suffixUnit.length,
                                        );
                            }, 0);
                    },
                    r = 0,
                    s = this.elements.length;
                r < s;
                r++
            )
                (this.elements[r].lastOutput = ''),
                    (this.elements[r].onkeyup = i),
                    this.elements[r].value.length &&
                        (this.elements[r].value = o[t](
                            this.elements[r].value,
                            this.opts,
                        ));
        }),
        (r.prototype.maskMoney = function (t) {
            (this.opts = n(t)), this.bindElementToMask('toMoney');
        }),
        (r.prototype.maskNumber = function () {
            (this.opts = {}), this.bindElementToMask('toNumber');
        }),
        (r.prototype.maskAlphaNum = function () {
            (this.opts = {}), this.bindElementToMask('toAlphaNumeric');
        }),
        (r.prototype.maskPattern = function (t) {
            (this.opts = { pattern: t }), this.bindElementToMask('toPattern');
        }),
        (r.prototype.unMask = function () {
            this.unbindElementToMask();
        });
    var o = function (t) {
        if (!t) throw new Error('VanillaMasker: There is no element to bind.');
        var e = 'length' in t ? (t.length ? t : []) : [t];
        return new r(e);
    };
    return (
        (o.toMoney = function (t, e) {
            if ((e = n(e)).zeroCents) {
                e.lastOutput = e.lastOutput || '';
                var i = '(' + e.separator + '[0]{0,' + e.precision + '})',
                    r = new RegExp(i, 'g'),
                    o = t.toString().replace(/[\D]/g, '').length || 0,
                    s =
                        e.lastOutput.toString().replace(/[\D]/g, '').length ||
                        0;
                (t = t.toString().replace(r, '')),
                    o < s && (t = t.slice(0, t.length - 1));
            }
            for (
                var l = t.toString().replace(/[\D]/g, ''),
                    a = new RegExp('^(0|\\' + e.delimiter + ')'),
                    u = new RegExp('(\\' + e.separator + ')$'),
                    p = l.substr(0, l.length - e.moneyPrecision),
                    h = p.substr(0, p.length % 3),
                    c = new Array(e.precision + 1).join('0'),
                    f = 0,
                    g = (p = p.substr(p.length % 3, p.length)).length;
                f < g;
                f++
            )
                f % 3 == 0 && (h += e.delimiter), (h += p[f]);
            h = (h = h.replace(a, '')).length ? h : '0';
            var m = '';
            if (
                (!0 === e.showSignal &&
                    (m =
                        t < 0 || (t.startsWith && t.startsWith('-'))
                            ? '-'
                            : ''),
                !e.zeroCents)
            ) {
                var v = l.length - e.precision,
                    d = l.substr(v, e.precision),
                    y = d.length;
                c = (c + d).slice(-(e.precision > y ? e.precision : y));
            }
            return (
                (e.unit + m + h + e.separator + c).replace(u, '') + e.suffixUnit
            );
        }),
        (o.toPattern = function (t, e) {
            var n,
                r = 'object' == typeof e ? e.pattern : e,
                o = r.replace(/\W/g, ''),
                s = r.split(''),
                l = t.toString().replace(/\W/g, ''),
                a = l.replace(/\W/g, ''),
                u = 0,
                p = s.length,
                h = 'object' == typeof e ? e.placeholder : void 0;
            for (n = 0; n < p; n++) {
                if (u >= l.length) {
                    if (o.length == a.length) return s.join('');
                    if (void 0 !== h && o.length > a.length)
                        return i(s, n, h).join('');
                    break;
                }
                if (
                    ('9' === s[n] && l[u].match(/[0-9]/)) ||
                    ('A' === s[n] && l[u].match(/[a-zA-Z]/)) ||
                    ('S' === s[n] && l[u].match(/[0-9a-zA-Z]/))
                )
                    s[n] = l[u++];
                else if ('9' === s[n] || 'A' === s[n] || 'S' === s[n])
                    return void 0 !== h
                        ? i(s, n, h).join('')
                        : s.slice(0, n).join('');
            }
            return s.join('').substr(0, n);
        }),
        (o.toNumber = function (t) {
            return t.toString().replace(/(?!^-)[^0-9]/g, '');
        }),
        (o.toAlphaNumeric = function (t) {
            return t.toString().replace(/[^a-z0-9 ]+/i, '');
        }),
        o
    );
});
