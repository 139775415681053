import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

import { primaryColor } from '../../../../../../constants/colors';
import { Li } from '../styled';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%'
  }
}));

export default function FrontEcosystem({ front }) {
  const classes = useStyles();

  return (
    <>
      <li>
        <h2>{front.front}</h2>
      </li>
      <ul>
        <Li>
          <strong>Considerações e/ou fatos relevantes</strong>:
          <ul>
            <Li>
              Considerando que em casos de recuperação de crédito com suspeita
              de blindagem patrimonial o devedor principal se vale de pessoas
              próximas para escamotear seus bens e se furtar ao cumprimento de
              suas obrigações e às determinações da Justiça; considerando que no
              ecosistema de pessoas relacionadas ao caso foram encontrados os
              seguintes SÓCIOS, EMPRESAS ou FAMILIARES PRÓXIMOS dos pesquisados:
            </Li>
            <TableContainer component={Paper} style={{ marginTop: '10px' }}>
              <Table
                className={classes.table}
                size="small"
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>NOME</StyledTableCell>
                    <StyledTableCell>CPF/CNPJ</StyledTableCell>
                    <StyledTableCell>RELAÇÃO COM O CASO</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {front.rule.map(({ name, cpf_cnpj, relation }) => (
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        {name}
                      </StyledTableCell>
                      <StyledTableCell align="left">{cpf_cnpj}</StyledTableCell>
                      <StyledTableCell align="left">{relation}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ul>
        </Li>
        <Li>
          Sugere-se estudar a viabilidade das seguintes medidas:
          <ul>
            <Li>
              Encomendar relatórios dessas pessoas ou incluí-las como novas
              pesquisadas no dossiê.
            </Li>
          </ul>
        </Li>
        <Li>
          Caso haja interesse em solicitar auxílio para tais tarefas e análises,{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://forms.monday.com/forms/53cda30c0b17fcb6c85e4a23a3ec7fec?r=use1"
          >
            <strong>clique aqui</strong>
          </a>
          .
        </Li>
      </ul>
    </>
  );
}
