import React, { useState } from 'react';
import {
  Container,
  StyledButton,
  StyledFormControl,
  StyledTextField
} from './styled';
import { Add } from '@material-ui/icons';
import { InputLabel, MenuItem, Select } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { toast, ToastContainer } from 'react-toastify';

const AddInfoAccordion = ({ data, setData, executeScroll }) => {
  const [dataForAdd, setDataForAdd] = useState({
    nome: '',
    cpf_cnpj_oab: '',
    identidade: '',
    cartorio: '',
    cns: '-',
    livro: '',
    folha: '',
    data_do_ato: '',
    tipo_ato: '',
    natureza_escritura: '-',
    natureza: '-',
    valor: '-',
    litigio: '-',
    acordo: '-',
    partes: '-'
  });

  const handleChange = (event) => {
    setDataForAdd({
      ...dataForAdd,
      [event.target?.name]: event.target?.value
    });
  };

  const pushData = () => {
    if (
      dataForAdd.nome === '' ||
      dataForAdd.cpf_cnpj_oab === '' ||
      dataForAdd.identidade === '' ||
      dataForAdd.cartorio === '' ||
      dataForAdd.livro === '' ||
      dataForAdd.folha === '' ||
      dataForAdd.data_do_ato === ''
    ) {
      toast.error('Preencha todos os campos.');
    } else {
      setData([...data, dataForAdd]);
      setDataForAdd({
        nome: '',
        cpf_cnpj_oab: '',
        identidade: '',
        cartorio: '',
        cns: '-',
        livro: '',
        folha: '',
        data_do_ato: '',
        tipo_ato: '',
        natureza_escritura: '-',
        natureza: '-',
        valor: '-',
        litigio: '-',
        acordo: '-',
        partes: '-'
      });
      executeScroll();
    }
  };

  return (
    <>
      <ToastContainer />
      <Container>
        <StyledTextField
          variant="outlined"
          name={'nome'}
          value={dataForAdd.nome}
          onChange={handleChange}
          label={'Nome'}
          fullWidth
          margin={'normal'}
          type={'text'}
          required
        />
        <StyledTextField
          variant="outlined"
          name={'cpf_cnpj_oab'}
          value={dataForAdd.cpf_cnpj_oab}
          onChange={handleChange}
          label={'CPF/CNPJ/OAB'}
          fullWidth
          margin={'normal'}
          type={'text'}
          required
        />
        <StyledTextField
          variant="outlined"
          name={'identidade'}
          value={dataForAdd.identidade}
          onChange={handleChange}
          label={'Identidade'}
          fullWidth
          margin={'normal'}
          type={'text'}
          required
        />
        <StyledTextField
          variant="outlined"
          name={'cartorio'}
          value={dataForAdd.cartorio}
          onChange={handleChange}
          label={'Cartório'}
          fullWidth
          margin={'normal'}
          type={'text'}
          required
        />

        <StyledFormControl style={{ minWidth: 150 }}>
          <InputLabel style={{ marginLeft: 12 }} id="">
            Tipo do ato*
          </InputLabel>
          <Select
            variant="filled"
            onChange={handleChange}
            name={'tipo_ato'}
            required
          >
            <MenuItem value="Escritura">Escritura</MenuItem>
            <MenuItem value="Procuração">Procuração</MenuItem>
            <MenuItem value="Substabelecimento">Substabelecimento</MenuItem>
            <MenuItem value="Inventário">Inventário</MenuItem>
            <MenuItem value="Retificação">Retificação</MenuItem>
            <MenuItem value="Sobrepartilha">Sobrepartilha</MenuItem>
            <MenuItem value="Divórcio Direto">Divórcio Direto</MenuItem>
            <MenuItem value="Ata Notarial">Ata Notarial</MenuItem>
          </Select>
        </StyledFormControl>
        <StyledTextField
          variant="outlined"
          name={'livro'}
          value={dataForAdd.livro}
          onChange={handleChange}
          label={'Livro'}
          fullWidth
          margin={'normal'}
          type={'text'}
          required
        />
        <StyledTextField
          variant="outlined"
          name={'folha'}
          value={dataForAdd.folha}
          onChange={handleChange}
          label={'Folha'}
          fullWidth
          margin={'normal'}
          type={'text'}
          required
        />
        <InputMask
          ariant="outlined"
          mask="99/99/9999"
          name={'data_do_ato'}
          value={dataForAdd.data_do_ato}
          onChange={handleChange}
          label={'Data do Ato'}
          fullWidth
          margin={'normal'}
          type={'text'}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              pushData();
            }
          }}
          required
        >
          {(inputProps) => (
            <StyledTextField
              {...inputProps}
              type="tel"
              label={'Data do ato'}
              variant="outlined"
            />
          )}
        </InputMask>
        <StyledButton variant="outlined" color="primary" onClick={pushData}>
          <Add fontSize={'large'} />
        </StyledButton>
      </Container>
    </>
  );
};

export default AddInfoAccordion;
