import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

import { primaryColor } from '../../../../../../constants/colors';
import { Li } from '../styled';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%'
  }
}));

export default function FrontDeathSearchedPerson({ front }) {
  const classes = useStyles();

  return (
    <>
      <li>
        <h2>{front.front}</h2>
      </li>
      <ul>
        <Li>
          <strong>Considerações e/ou fatos relevantes</strong>.
          <ul>
            <Li>
              Considerando que nas buscas realizadas foi constatado que consta
              informação de ÓBITO com relação às seguintes pessoas:
            </Li>
            <TableContainer component={Paper} style={{ marginTop: '10px' }}>
              <Table
                className={classes.table}
                size="small"
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Nome</StyledTableCell>
                    <StyledTableCell>CPF</StyledTableCell>
                    <StyledTableCell>Data Nascimento/Óbito</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {front.rule.map(
                    ({ name, cpf_cnpj, birthdate, date_death, age }) => (
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {name}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {cpf_cnpj}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {birthdate} - {date_death} - {age} Anos
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Li>
              Considerando que a abertura de sucessão de alguém gera um complexo
              de direitos e devedores para as pessoas relacionadas com o óbito
              ocorrido;
            </Li>
          </ul>
        </Li>
        <Li>
          Conclusões e/ou recomendações técnicas. Considerando os elementos
          acima indicados, bem como todos os dados encontrados, sugere-se
          consultar advogado sobre a viabilidade jurídica e estudar a
          conveniência estratégica de tomar as seguintes medidas:
          <ul>
            <Li>
              Confirmar se, com relação à(s) pessoa(s) indicadas com óbito, se
              elas realmente faleceram ou se se trata de mera inconsistência de
              dados;
            </Li>
            <Li>
              Confirmando-se o falecimento, verificar se houve a devida
              comunicação e abertura de inventário ou arrolamento;
            </Li>
            <Li>
              Sendo o falecido o próprio devedor(a), verificar com advogado a
              possibilidade de habilitar-se nos autos do inventário (ou iniciar
              um novo, caso não exista ainda), na condição de credor, e
              habilitar o crédito para que seja satisfeito com os bens que forem
              encontrados. Caso o inventário já tenha sido concluído, verificar
              a possibilidade de responsabilizar os sucessores legais, nos
              limites das forças da herança;
            </Li>
            <Li>
              Sendo o falecido pessoa de quem o(a) devedor(a) é herdeiro ou
              legatário, verificar com advogado a possibilidade de penhora dos
              bens que seriam destinados ao(à) devedor.
            </Li>
          </ul>
        </Li>
        <Li>
          Caso haja interesse em solicitar auxílio para tais tarefas e análises,{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://forms.monday.com/forms/53cda30c0b17fcb6c85e4a23a3ec7fec?r=use1"
          >
            <strong>clique aqui</strong>
          </a>
          .
        </Li>
      </ul>
    </>
  );
}
