import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RefreshIcon from '@material-ui/icons/Refresh';
import { AccordionSummary, AccordionDetails, Tooltip } from '@material-ui/core';
import Credilink from './Credilink';
import Jusbrasil from './Jusbrasil';
import Imoveis from './Imoveis';
import Whois from './Whois';
import Marcas from './Inpi/Marcas';
import Patentes from './Inpi/Patentes';
import Sncr from './Sncr';
import RFsocios from './RFsocios';
import Rf from './Rf';
import SocialMedia from './SocialMedia';
import Checktudo from './Checktudo';
import Gnews from './Gnews';
import Protesto from './Protesto';
import Negativados from './Negativados';
import Infosimples from './Infosimples';
import Processos from './Processos';
import Procuracoes from './Procuracoes';
import Anac from './Anac';
import Pep from './Pep';

import { green, red } from '@material-ui/core/colors';
import { toast, ToastContainer } from 'react-toastify';
import { useParams } from 'react-router-dom';
import {
  Actions,
  FalseIcon,
  StyledAccordion,
  StyledButton,
  StyledTypography,
  TrueIcon,
  RunningIcon
} from './styled';

import axios from 'axios';
import { BASE_URL } from '../../constants/urls';
import ErrorBoundary from '../../pages/Error/ErrorBoundary';
import { useState } from 'react';
import UploadModal from '../ModalPlanilha';
import Sintegra from './Sintegra';
import Ecosystem from './Ecosystem';
import Quod from './Quod';
import CertidaoDebitos from './CertidaoDebitos';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '2rem',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    padding: 0
  },
  table: {
    width: '100%'
  },
  button: {
    marginLeft: '10px'
  },
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    padding: theme.spacing(1)
  }
}));

const ServicesComponent = ({ target }) => {
  const classes = useStyles();
  const token = localStorage.getItem('token');
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const access = localStorage.getItem('access');

  console.error = () => {};

  const updateService = (event, service, target, service_name, target_name) => {
    event.stopPropagation();
    if (service?.dag_id === 'procuracoes') {
      UploadModal();
    } else {
      setIsLoading(true);
      const reqBody = {
        service_id: service,
        target_id: target,
        service_name: service_name,
        target_name: target_name
      };
      axios
        .post(`${BASE_URL}/case/${params.id}/service`, reqBody, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((response) => {
          toast.success('Atualizando Microserviço, aguarde.');
          setIsLoading(false);
        })
        .catch((error) => {
          toast.error('Houve um erro ao atualizar o Microserviço');
          setIsLoading(false);
        });
    }
  };

  const verify_error_in_imoveis = (service) => {
    service?.data.some((d) => Object.values(d) == 'ERROR') &&
      (service.success = false);
  };

  return (
    <div className={classes.root}>
      <ToastContainer />

      {target.services.map((service) => {
        return (
          <StyledAccordion key={service.id}>
            <AccordionSummary
              className={classes.heading}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <StyledTypography className={classes.heading}>
                <Actions>
                  {access >= 3 && (
                    <Tooltip
                      title={
                        service.dag_id === 'procuracoes'
                          ? 'Adicionar dados'
                          : 'Atualizar microserviço'
                      }
                      placement="top"
                    >
                      {service.dag_id === 'procuracoes' ? (
                        <UploadModal service={service} target={target.id} />
                      ) : service.dag_id === 'ecosystem' ? (
                        <>
                          {((service.status = true), (service.success = true))}
                        </>
                      ) : (
                        <StyledButton
                          disabled={isLoading}
                          className={classes.button}
                          variant="outlined"
                          color="primary"
                          size="small"
                          endIcon={<RefreshIcon />}
                          onClick={(event) =>
                            updateService(
                              event,
                              service.id,
                              target.id,
                              service.name,
                              `${target.name} - ${target.cpf_cnpj}`
                            )
                          }
                        >
                          {service.last_update}
                        </StyledButton>
                      )}
                    </Tooltip>
                  )}

                  {service.status ? (
                    <Tooltip title="Dados coletados" placement="top">
                      <TrueIcon
                        style={{ color: green[500] }}
                        fontSize="small"
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Coletando dados..." placement="top">
                      <RunningIcon color="primary" size={20} />
                    </Tooltip>
                  )}

                  {service.dag_id === 'imoveis' &&
                    verify_error_in_imoveis(service)}

                  {service.success ? (
                    <Tooltip
                      title="Dados coletados e processados com successo"
                      placement="top"
                    >
                      <TrueIcon
                        style={{ color: green[500], marginRight: '8px' }}
                        fontSize="small"
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title="Os dados foram coletados, mas houve um erro. Clique em atualizar!"
                      placement="top"
                    >
                      <FalseIcon
                        style={{ color: red[500], marginRight: '8px' }}
                        fontSize="small"
                      />
                    </Tooltip>
                  )}
                </Actions>
                {service.name}
              </StyledTypography>
            </AccordionSummary>
            <AccordionDetails>
              <StyledTypography>
                <ErrorBoundary>
                  {service.dag_id === 'ecosystem' && (
                    <Ecosystem data={service.data} />
                  )}
                  {service.dag_id === 'credilink' && (
                    <Credilink data={service.data} />
                  )}
                  {service.dag_id === 'jusbrasil' && (
                    <Jusbrasil data={service.data} />
                  )}
                  {service.dag_id === 'tst' && (
                    <CertidaoDebitos data={service.data} />
                  )}
                  {service.dag_id === 'imoveis' && (
                    <Imoveis data={service.data} />
                  )}
                  {service.dag_id === 'whois' && <Whois data={service.data} />}
                  {service.dag_id === 'inpi' && (
                    <>
                      {service.data.marcas && (
                        <Marcas data={service.data.marcas} />
                      )}
                      {service.data.patentes && (
                        <Patentes data={service.data.patentes} />
                      )}
                    </>
                  )}
                  {service.dag_id == 'anac' && <Anac data={service.data} />}
                  {service.dag_id == 'pep' && <Pep data={service.data} />}
                  {service.dag_id === 'sncr' && <Sncr data={service.data} />}
                  {service.dag_id === 'receita_federal_socios' && (
                    <RFsocios data={service.data} />
                  )}
                  {service.dag_id === 'rf' && <Rf data={service.data} />}
                  {service.dag_id === 'social-media' && (
                    <SocialMedia data={service.data} />
                  )}
                  {service.dag_id === 'veiculos' && (
                    <Checktudo data={service.data} />
                  )}
                  {service.dag_id === 'gnews' && (
                    <Gnews
                      data={service.data}
                      last_update={service.last_update}
                    />
                  )}
                  {service.dag_id === 'infosimples' && (
                    <Infosimples data={service.data} />
                  )}
                  {service.dag_id === 'protesto' && (
                    <Protesto data={service.data} />
                  )}
                  {service.dag_id === 'negativados' && (
                    <Negativados
                      data={service.data}
                      cpf_cnpj={target?.cpf_cnpj}
                    />
                  )}
                  {service.dag_id === 'quod' && <Quod data={service.data} />}
                  {service.dag_id === 'processos' && (
                    <Processos data={service.data} />
                  )}
                  {service.dag_id === 'procuracoes' && (
                    <Procuracoes data={service.data} />
                  )}
                  {service.dag_id === 'sintegra' && (
                    <Sintegra data={service.data} />
                  )}
                </ErrorBoundary>
              </StyledTypography>
            </AccordionDetails>
          </StyledAccordion>
        );
      })}
    </div>
  );
};

export default ServicesComponent;
