import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Container, ContextContainer } from './styled';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 0,
    color: '#4a4a4a'
  },
  MuiAccordionroot: {
    '&.MuiAccordion-root:before': {
      backgroundColor: 'white'
    }
  }
}));

const ContextFormAccordion = ({ form }) => {
  const classes = useStyles();

  return (
    <ContextContainer className={classes.root}>
      <Accordion
        elevation={0}
        classes={{
          root: classes.MuiAccordionroot
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            <strong>Dados do contexto</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Container>
            {form != null ? (
              <>
                {form?.map((question) => (
                  <>
                    <strong>{question?.name}: </strong>
                    {question?.response &&
                    typeof question?.response == 'object' &&
                      question?.response.map((response) =>
                        typeof response == 'string' ? (
                          <p>{response}</p>
                        ) : (
                          <>
                            <p>Nome: {response?.name}</p>
                            <p>CPF/CNPJ: {response?.cpf_cnpj}</p>
                          </>
                        )
                      )}
                    {question?.response 
                    && typeof question?.response == 'string' && (
                      <p>{question?.response}</p>
                    )}
                  </>
                ))}
              </>
            ) : (
              <strong>Não há dados</strong>
            )}
          </Container>
        </AccordionDetails>
      </Accordion>
    </ContextContainer>
  );
};

export default ContextFormAccordion;
