import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  Grid,
  Typography,
  CardContent,
  Divider
} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: '100%',
    marginBottom: '10px'
  }
});

export default function CardEmpresa({ data }) {
  const classes = useStyles();
  const { empresa, capital, endereco, objeto_social, participantes } = data;

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography
          variant="h6"
          component="div"
          style={{ marginBottom: '2rem' }}
        >
          {empresa?.nome} - {empresa?.cnpj}
        </Typography>
        <Typography color="textSecondary">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <p>
                <strong>Tipo</strong>: {empresa?.tipo}
              </p>
              <p>
                <strong>Capital Social</strong>: {capital}
              </p>
              <p>
                <strong>Nire</strong>: {empresa?.nire}
              </p>
              <p>
                <strong>Inscrição Estadual</strong>:{' '}
                {empresa?.inscricao_estadual}
              </p>
              <p>
                <strong>Inicio das Atividades</strong>:{' '}
                {empresa?.inicio_atividades}
              </p>
              <p>
                <strong>Data da Constituição</strong>:{' '}
                {empresa?.data_constituicao}
              </p>
              <p>
                <strong>Data da Emissão</strong>: {empresa?.data_emissao}
              </p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>
                <strong>Logradouro</strong>: {endereco?.logradouro}
              </p>
              <p>
                <strong>Complemento</strong>: {endereco?.complemento}
              </p>
              <p>
                <strong>Bairro</strong>: {endereco?.bairro}
              </p>
              <p>
                <strong>UF</strong>: {endereco?.uf}
              </p>
              <p>
                <strong>Município</strong>: {endereco?.municipio}
              </p>
              <p>
                <strong>Número</strong>: {endereco?.numero}
              </p>
              <p>
                <strong>CEP</strong>: {endereco?.cep}
              </p>
            </Grid>
          </Grid>
        </Typography>

        <Grid align="justify" style={{ marginTop: 12 }}>
          <Divider />
          <Typography
            color="textSecondary"
            display="block"
            variant="caption"
            align="center"
            style={{ marginBottom: '2rem', marginTop: '2rem' }}
          >
            Objeto Social
          </Typography>
          {objeto_social?.length ? (
            objeto_social.map((item) => (
              <Typography
                className={classes.root}
                style={{ marginBottom: '2rem' }}
              >
                {item}
              </Typography>
            ))
          ) : (
            <p>Sem resultados.</p>
          )}
        </Grid>

        <Grid align="justify">
          <Divider />
          <Typography
            color="textSecondary"
            display="block"
            variant="caption"
            align="center"
            style={{ marginBottom: '2rem' }}
          >
            Participantes
          </Typography>
          {participantes?.length ? (
            participantes.map((item) => (
              <Typography
                className={classes.root}
                style={{ marginBottom: '2rem' }}
              >
                {item}
              </Typography>
            ))
          ) : (
            <p>Sem resultados.</p>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
