import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { BASE_URL } from '../../constants/urls';
import { goToLogin } from '../../routes/coordinator';


const api = axios.create({
  baseURL: `${BASE_URL}`
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      config.headers['Content-Type'] = 'application/json';
    }
    else {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    // config.headers['Content-Type'] = 'application/json';
    
    return config;
  },
  (error) => {
    const history = useHistory();
    localStorage.removeItem('token');
    goToLogin(history);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          const token = localStorage.getItem('token');
          if (token) {
            localStorage.removeItem('token');
            window.location.reload();
          }
          break;

        default:
          break;
      }      
    }

    return Promise.reject(error)
  }
);

export default api;
