import styled from 'styled-components';
import { Accordion, Button } from '@material-ui/core';
import { Typography, CircularProgress } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { primaryColor } from '../../constants/colors';

export const StyledAccordion = styled(Accordion)`
  width: 100%;
`;

export const StyledTypography = styled(Typography)`
  width: 100%;
`;

export const TrueIcon = styled(CheckCircleIcon)`
  margin-left: 0.5rem;
  color: green;
`;

export const FalseIcon = styled(CancelIcon)`
  margin-left: 0.5rem;
`;

export const RunningIcon = styled(CircularProgress)`
  margin-left: 0.5rem;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  width: 200px;
  @media screen and (max-width: 768px) {
    font-size: 8px;
    width: 100px;
  }
`;

export const ProcButton = styled(Button)`
  width: 200px;
  color: white;
  background-color: ${primaryColor};
  @media screen and (max-width: 768px) {
    font-size: 8px;
    width: 100px;
  }
  :hover {
    background-color: ${primaryColor};
  }
`;

export const H3 = styled.h3`
  text-align: center;
  margin-bottom: 0.5rem;
`;
