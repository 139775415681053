import {
  Box,
  Button,
  ChakraProvider,
  createStandaloneToast,
  Divider,
  extendTheme,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Link,
  Progress,
  Select,
  SimpleGrid,
  VStack
} from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import InputBase from '../../../../components/ChakraInput/Input';
import Header from '../../../../components/Header';
import SideBar from '../../../../components/SideBar';
import { BASE_URL } from '../../../../constants/urls';
import useProtectedPage from '../../../../hooks/useProtectedPage';
import { goToProduct, goToProducts } from '../../../../routes/coordinator';
import { Fonts } from '../../../../styles/fonts';

const CreateProduct = () => {
  useProtectedPage();
  console.error = () => {};
  const token = localStorage.getItem('token');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors }
  } = useForm();

  const id = 'test-toast';

  const theme = extendTheme({
    fonts: {
      heading: 'Open Sans',
      body: 'Raleway'
    }
  });

  const toast = createStandaloneToast({ theme: theme });

  const updateService = async (form) => {
    setIsLoading(true);

    const reqBody = {
      name: form.name,
      label: form.label,
      value: form.value,
      enable: form.enable
    };

    const response = await axios
      .post(`${BASE_URL}/product`, reqBody, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        setIsLoading(false);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: 'Produto cadastrado com sucesso!',
            description:
              'Você será redirecionado para a página do produto automaticamente em alguns instantes',
            status: 'success',
            duration: 9000,
            isClosable: true
          });
          const productId = res?.data?.id;

          setTimeout(() => {
            goToProduct(history, productId);
          }, 5000);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        const msg = error?.response?.data?.detail?.map((msg) => msg.msg);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: 'Houve um erro ao criar o produto',
            description: msg,
            status: 'error',
            duration: 9000,
            isClosable: true
          });
          setIsLoading(false);
        }
      });
  };

  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <Box>
        <Header />

        <Flex
          as="form"
          noValidate
          onSubmit={handleSubmit(updateService)}
          w="100%"
          my="6"
          maxWidth={1480}
          mx="auto"
          px="6"
        >
          <SideBar />

          <Box flex="1" borderRadius={8} bg="gray.50" p={['6', '8']}>
            {isLoadingPage ? (
              <Progress isIndeterminate />
            ) : (
              <>
                <Heading size="lg" fontWeight="normal">
                  Dados gerais
                </Heading>

                <Divider my="6" borderColor="gray.700" />

                <VStack spacing="8">
                  <SimpleGrid minChildWidth="240px" mt="8" spacing="8" w="100%">
                    <InputBase
                      {...register('name')}
                      name="name"
                      type="text"
                      label="Nome"
                    />
                    <InputBase
                      {...register('label')}
                      name="label"
                      type="text"
                      label="Label"
                    />
                  </SimpleGrid>
                </VStack>

                <VStack spacing="8">
                  <SimpleGrid minChildWidth="240px" mt="8" spacing="8" w="100%">
                    <InputBase
                      {...register('value')}
                      name="value"
                      type="number"
                      label="Valor (apenas números)"
                    />
                    <FormControl>
                      <FormLabel>Ativo</FormLabel>
                      <Select
                        {...register('enable')}
                        size="lg"
                        focusBorderColor="pink.500"
                        bgColor="gray.200"
                        variant="filled"
                        _hover={{
                          bgColor: 'grey.100'
                        }}
                        placeholder="Selecionar..."
                      >
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                      </Select>
                    </FormControl>
                  </SimpleGrid>
                </VStack>

                <Flex mt="8" justify="flex-end">
                  <HStack spacing="4">
                    <Link onClick={() => goToProducts(history)}>
                      <Button as="a" colorScheme="pink">
                        Cancelar
                      </Button>
                    </Link>
                    <Button
                      isLoading={isLoading}
                      type="submit"
                      colorScheme="blue"
                    >
                      Salvar
                    </Button>
                  </HStack>
                </Flex>
              </>
            )}
          </Box>
        </Flex>
      </Box>
    </ChakraProvider>
  );
};

export default CreateProduct;
