import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ChakraProvider,
  createStandaloneToast,
  Divider,
  extendTheme,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Link,
  Select,
  SimpleGrid,
  Stack,
  Textarea,
  VStack
} from '@chakra-ui/react';
import axios from 'axios';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import InputBase from '../../../../components/ChakraInput/Input';
import Header from '../../../../components/Header';
import SideBar from '../../../../components/SideBar';
import { BASE_URL } from '../../../../constants/urls';
import useProtectedPage from '../../../../hooks/useProtectedPage';
import { goToUser, goToUsers } from '../../../../routes/coordinator';
import { Fonts } from '../../../../styles/fonts';
import validaCpfCnpj from '../../../../utils/teste';
import { CPFandCNPJ } from '../../../../utils/validate';

const CreateUser = () => {
  useProtectedPage();
  console.error = () => {};
  const token = localStorage.getItem('token');
  const [isLoading, setIsLoading] = useState(false);
  const [color, setColor] = useState('red.200');
  const [cpf, setCpf] = useState('');
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors }
  } = useForm();

  const id = 'test-toast';

  const theme = extendTheme({
    fonts: {
      heading: 'Open Sans',
      body: 'Raleway'
    }
  });

  const toast = createStandaloneToast({ theme: theme });

  const onChangeCpf = useCallback((event) => {
    setCpf(CPFandCNPJ(event.target.value));
    let isValid = false;
    if (
      event.target.value.toString().replace(/[^0-9]/g, '').length === 11 ||
      event.target.value.toString().replace(/[^0-9]/g, '').length === 14
    ) {
      isValid = validaCpfCnpj(CPFandCNPJ(event.target.value));
      if (isValid) {
        setColor('green.200');
      } else {
        setColor('red.200');
      }
    } else {
      setColor('red.200');
    }
  }, []);

  const createCad = async (form) => {
    if (color === 'red.200') {
      toast({
        title: 'Preecha corretamente o campo de CPF/CNPJ!',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
    setIsLoading(true);

    let cpfOrCnpj = 0;

    if (cpf.toString().replace(/[^0-9]/g, '').length === 11) {
      cpfOrCnpj = 1;
    } else {
      cpfOrCnpj = 2;
    }

    const reqBody = {
      cpf_cnpj: form.cpf_cnpj,
      fantasy_name: form.fantasy_name,
      type_registration: cpfOrCnpj,
      company_name: form.company_name,
      contact: form.contact,
      phone: form.phone,
      phone2: form.phone2,
      email: form.email,
      zip_code: form.zip_code,
      address: form.address,
      number: form.number,
      complement: form.complement,
      neighborhood: form.neighborhood,
      city: form.city,
      state: form.state,
      obs: form.obs,
      follow_id: form.followup,
      office_id: form.office,
      position_id: form.position,
      activate: form.enable
    };

    const response = await axios
      .post(`${BASE_URL}/register`, reqBody, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        setIsLoading(false);
        toast({
          title: 'Cadastro efetuado com sucesso!',
          description:
            'Você será redirecionado para a página do cadastro automaticamente em alguns instantes',
          status: 'success',
          duration: 5000,
          isClosable: true
        });

        const userId = res?.data?.id;

        setTimeout(() => {
          goToUser(history, userId);
        }, 5000);
      })
      .catch((error) => {
        setIsLoading(false);
        const msg = error?.response?.data?.detail?.map((msg) => msg.msg);
        toast({
          title: 'Falha ao efetuar cadastro!',
          description: msg,
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      });
  };

  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <Box>
        <Header />

        <Flex
          as="form"
          onSubmit={handleSubmit(createCad)}
          w="100%"
          my="6"
          maxWidth={1480}
          mx="auto"
          px="6"
        >
          <SideBar />

          <Box flex="1" borderRadius={8} bg="gray.50" p={['6', '8']}>
            <Heading size="lg" fontWeight="normal">
              Cadastro Geral
            </Heading>

            <Divider my="6" borderColor="gray.700" />

            <VStack spacing="8">
              <SimpleGrid minChildWidth="240px" spacing={['6', '8']} w="100%">
                <InputBase
                  {...register('fantasy_name', { required: true })}
                  name="fantasy_name"
                  type="text"
                  label="Nome Fantasia"
                />

                <FormControl>
                  <FormLabel>CPF/CNPJ</FormLabel>
                  <Input
                    {...register('cpf_cnpj', { required: true })}
                    name={'cpf_cnpj'}
                    value={cpf}
                    onChange={onChangeCpf}
                    focusBorderColor={color}
                    bgColor={color}
                    variant="filled"
                    _hover={{
                      bgColor: 'grey.100'
                    }}
                    size="lg"
                  />
                </FormControl>
              </SimpleGrid>

              <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                <InputBase
                  {...register('contact')}
                  name="contact"
                  type="text"
                  label="Razão Social"
                />
                <InputBase
                  {...register('email', { required: true })}
                  name="email"
                  type="email"
                  label="E-mail"
                />
              </SimpleGrid>

              <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                <InputBase
                  {...register('phone')}
                  name="phone"
                  type="number"
                  label="Telefone 1"
                />
                <InputBase
                  {...register('phone2')}
                  name="phone2"
                  type="text"
                  label="Telefone 2"
                />
              </SimpleGrid>

              <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                <InputBase
                  {...register('company_name', { required: true })}
                  name="company_name"
                  type="text"
                  label="Nome Compania"
                />

                <FormControl>
                  <FormLabel>Ativo</FormLabel>
                  <Select
                    {...register('enable', { required: true })}
                    size="lg"
                    focusBorderColor="pink.500"
                    bgColor="gray.200"
                    variant="filled"
                    _hover={{
                      bgColor: 'grey.100'
                    }}
                    placeholder="Selecionar..."
                  >
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </Select>
                </FormControl>
              </SimpleGrid>

              <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                <FormControl>
                  <FormLabel>Área</FormLabel>
                  <Select
                    {...register('followup', { required: true })}
                    size="lg"
                    focusBorderColor="pink.500"
                    bgColor="gray.200"
                    variant="filled"
                    _hover={{
                      bgColor: 'grey.100'
                    }}
                    placeholder="Selecionar..."
                  >
                    <option value={1}>Não se Aplica</option>
                    <option value={2}>Jurídico</option>
                    <option value={3}>Indústria</option>
                    <option value={4}>Consultoria</option>
                    <option value={5}>Finanças</option>
                    <option value={6}>Comércio</option>
                    <option value={7}>Público</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel>Posição</FormLabel>
                  <Select
                    {...register('position', { required: true })}
                    size="lg"
                    focusBorderColor="pink.500"
                    bgColor="gray.200"
                    variant="filled"
                    _hover={{
                      bgColor: 'grey.100'
                    }}
                    placeholder="Selecionar..."
                  >
                    <option value={1}>Prospect</option>
                    <option value={2}>Cliente</option>
                    <option value={3}>Colaborador</option>
                    <option value={4}>Parceiro</option>
                  </Select>
                </FormControl>
              </SimpleGrid>

              <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                <FormControl>
                  <FormLabel>Cargo</FormLabel>
                  <Select
                    {...register('office', { required: true })}
                    size="lg"
                    focusBorderColor="pink.500"
                    bgColor="gray.200"
                    variant="filled"
                    _hover={{
                      bgColor: 'grey.100'
                    }}
                    placeholder="Selecionar..."
                  >
                    <option value={1}>Não se Aplica</option>
                    <option value={2}>CEO</option>
                    <option value={3}>Sócio</option>
                    <option value={4}>Diretor</option>
                    <option value={5}>Legal</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel>Observações</FormLabel>
                  <Textarea
                    {...register('obs')}
                    name="obs"
                    type="text"
                    focusBorderColor="pink.500"
                    bgColor="gray.200"
                    variant="filled"
                    _hover={{
                      bgColor: 'grey.100'
                    }}
                    size="lg"
                  />
                </FormControl>
              </SimpleGrid>
            </VStack>

            <Heading size="lg" marginTop="50" fontWeight="normal">
              Endereço
            </Heading>

            <Divider my="6" borderColor="gray.700" />

            <VStack spacing="8">
              <SimpleGrid minChildWidth="240px" spacing={['6', '8']} w="100%">
                <InputBase
                  {...register('zip_code', { required: true })}
                  name="zip_code"
                  type="number"
                  label="CEP"
                />
                <InputBase
                  {...register('state', { required: true, maxLength: 2 })}
                  name="state"
                  type="text"
                  maxLength="2"
                  label="Estado"
                />
              </SimpleGrid>

              <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                <InputBase
                  {...register('city', { required: true })}
                  name="city"
                  type="text"
                  label="Cidade"
                />
                <InputBase
                  {...register('neighborhood', { required: true })}
                  name="neighborhood"
                  type="text"
                  label="Bairro"
                />
              </SimpleGrid>

              <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                <InputBase
                  {...register('address', { required: true })}
                  name="address"
                  type="text"
                  label="Rua"
                />
                <InputBase
                  {...register('number')}
                  name="number"
                  type="text"
                  label="Número"
                />
              </SimpleGrid>

              <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                <FormControl>
                  <FormLabel>Complemento</FormLabel>
                  <Textarea
                    {...register('complement')}
                    name="complement"
                    type="text"
                    focusBorderColor="pink.500"
                    bgColor="gray.200"
                    variant="filled"
                    _hover={{
                      bgColor: 'grey.100'
                    }}
                    size="lg"
                  />
                </FormControl>
              </SimpleGrid>
            </VStack>

            <Flex mt="8" justify="flex-end">
              <HStack spacing="4">
                <Link onClick={() => goToUsers(history)}>
                  <Button as="a" colorScheme="pink">
                    Cancelar
                  </Button>
                </Link>
                <Button type="submit" isLoading={isLoading} colorScheme="blue">
                  Salvar
                </Button>
              </HStack>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </ChakraProvider>
  );
};

export default CreateUser;
