export const goToLogin = (history) => {
  history.push('/login');
};

export const goToHome = (history) => {
  history.push('/');
};

export const goToDetails = (history, id) => {
  history.push(`/caso/${id}`);
};

export const goToCreate = (history) => {
  history.push('/novo-caso');
};

export const goToSearch = (history) => {
  history.push('/search');
};

export const goToAdmin = (history) => {
  history.push('/cadgeral');
};

export const goToUsers = (history) => {
  history.push('/cadgeral/users');
};

export const goToUser = (history, id) => {
  history.push(`/cadgeral/users/${id}`);
};

export const goToCreateUser = (history) => {
  history.push(`/cadgeral/users/create`);
};

export const goToServices = (history) => {
  history.push('/cadgeral/services');
};

export const goToService = (history, id) => {
  history.push(`/cadgeral/services/${id}`);
};

export const goToCreateService = (history) => {
  history.push(`/cadgeral/services/create`);
};

export const goToProducts = (history) => {
  history.push('/cadgeral/products');
};

export const goToProduct = (history, id) => {
  history.push(`/cadgeral/products/${id}`);
};

export const goToCreateProduct = (history) => {
  history.push(`/cadgeral/products/create`);
};

export const goToContexts = (history) => {
  history.push('/cadgeral/contexts');
};

export const goToContext = (history, id) => {
  history.push(`/cadgeral/contexts/${id}`);
};

export const goToCreateContext = (history) => {
  history.push(`/cadgeral/contexts/create`);
};
