import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { H3 } from '../../styled';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: '100%',
    marginBottom: '10px'
  }
});

export function CardNegative({ negative, cpf_cnpj }) {
  const classes = useStyles();

  const returnCpfData = () => {
    return (
      <>
        <H3>
          Valor financeiro total de negativações: R${' '}
          {negative?.resumo_ocorrencia_debitos?.valor_acomulado} em{' '}
          {negative?.resumo_ocorrencia_debitos?.total_devedor} negativações
        </H3>
        <H3 style={{ marginTop: '15px' }}>Últimas Ocorrências</H3>
        {negative?.debitos.map((debito) => (
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h6">
                Empresa Negativadora: {debito?.informante}
              </Typography>
              {/* <Typography variant="h6">CNPJ: {cnpj}</Typography> */}
              <Typography color="textSecondary">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <p>
                      <strong>Valor:</strong> {debito?.valor}
                    </p>
                    <p>
                      <strong>Número do Contrato:</strong> {debito?.contrato}
                    </p>
                    <p>
                      <strong>Situação:</strong> {debito?.situacao}
                    </p>
                    <p>
                      <strong>Data da Inclusão:</strong>{' '}
                      {debito?.data_disponibilizacao}
                    </p>
                    <p>
                      <strong>Data da Ocorrência:</strong>{' '}
                      {debito?.data_ocorrencia}
                    </p>
                    <p>
                      <strong>Tipo de Ocorrência:</strong>{' '}
                      {debito?.tipo_ocorrencia}
                    </p>
                    <p>
                      <strong>Condição:</strong> {debito?.condicao}
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p>
                      <strong>Cidade:</strong> {debito?.cidade}
                    </p>
                    <p>
                      <strong>UF:</strong> {debito?.uf}
                    </p>
                    {/* <p>
                  <strong>Bairro:</strong> {debito?.condicao?.neighborhood}
                </p>
                <p>
                  <strong>Código Postal:</strong> {debito?.condicao?.postal_code}
                </p>
                <p>
                  <strong>Número:</strong> {debito?.condicao?.number}
                </p>
                <p>
                  <strong>Logradouro:</strong> {debito?.condicao?.street}
                </p> */}
                  </Grid>
                </Grid>
              </Typography>
            </CardContent>
          </Card>
        ))}
      </>
    );
  };

  const returnCnpjData = () => {
    return (
      <>
        <H3>
          Valor financeiro total de negativações: R${' '}
          {negative[0]?.pendencias_restricoes?.valor} em{' '}
          {negative[0]?.pendencias_restricoes?.total_pendencias} negativações
        </H3>
        <H3 style={{ marginTop: '15px' }}>Últimas Ocorrências</H3>
        {negative[0]?.pendencias_restricoes?.ultimas_ocorrencias.map(
          (debito) => (
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h6">
                  Empresa Negativadora: {debito?.informante}
                </Typography>
                {/* <Typography variant="h6">CNPJ: {cnpj}</Typography> */}
                <Typography color="textSecondary">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <p>
                        <strong>Valor:</strong> {debito?.valor}
                      </p>
                      <p>
                        <strong>Informante:</strong> {debito?.informante}
                      </p>
                      <p>
                        <strong>Data da Inclusão:</strong>{' '}
                        {debito?.disponivel_em}
                      </p>
                      <p>
                        <strong>Data da Ocorrência:</strong>{' '}
                        {debito?.data_debito}
                      </p>
                      <p>
                        <strong>Tipo de Ocorrência:</strong> {debito?.tipo}
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p>
                        <strong>Cidade:</strong> {debito?.cidade}
                      </p>
                      <p>
                        <strong>UF:</strong> {debito?.uf}
                      </p>
                      {/* <p>
                  <strong>Bairro:</strong> {debito?.condicao?.neighborhood}
                </p>
                <p>
                  <strong>Código Postal:</strong> {debito?.condicao?.postal_code}
                </p>
                <p>
                  <strong>Número:</strong> {debito?.condicao?.number}
                </p>
                <p>
                  <strong>Logradouro:</strong> {debito?.condicao?.street}
                </p> */}
                    </Grid>
                  </Grid>
                </Typography>
              </CardContent>
            </Card>
          )
        )}
      </>
    );
  };

  return (
    <>
      {cpf_cnpj.length === 14 ? (
        <>{returnCpfData()}</>
      ) : (
        <>{returnCnpjData()}</>
      )}
    </>
  );
}
