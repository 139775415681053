export const credit_recovery = (form) => {
  return [
    {
      name: 'Data do crédito',
      form_id: 'dataCred',
      response: form?.DataCred
    },
    {
      name: 'Data do vencimento/inadimplemento',
      form_id: 'dataVencIna',
      response: form?.DataVencIna
    },
    {
      name: 'Data do protesto',
      form_id: 'dataProtesto',
      response: form?.DataProtesto
    },
    {
      name: 'Data da distribuição do processo',
      form_id: 'dataDistProcess',
      response: form?.DataDistProcess
    },
    {
      name: 'Data da distribuição',
      form_id: 'dataDist',
      response: form?.DataDist
    },

    {
      name: 'Valor histórico da causa ou do crédito',
      form_id: 'valorHist',
      response: form?.ValorHist
    },
    {
      name: 'Data da citação dos réus/executados',
      form_id: 'dataCitacaoReus',
      response: form?.DataCitacaoReus
    },
    {
      name: 'Garantias prestadas',
      form_id: 'garantPrest',
      response: form?.GarantPrest
    },
    {
      name: 'Observações',
      form_id: 'observacoes',
      response: form?.Observacoes
    }
  ];
};
export const credit_recovery_jr = (form) => {
  return [
    {
      name: 'Devedores solidários',
      form_id: 'devSolid',
      response: form?.DevSolid
    },
    {
      name: 'Processo de RJ ou Falência',
      form_id: 'processRjFalen',
      response: form?.ProcessRjFalen
    },
    {
      name: 'Texto',
      form_id: 'texto',
      response: form?.Texto
    },
    {
      name: 'Data do crédito',
      form_id: 'dataCred',
      response: form?.DataCred
    },
    {
      name: 'Data do vencimento/inadimplemento',
      form_id: 'dataVencIna',
      response: form?.DataVencIna
    },
    {
      name: 'Data do protesto',
      form_id: 'dataProtesto',
      response: form?.DataProtesto
    },

    {
      name: 'Data da citação dos réus/executados',
      form_id: 'dataCitacaoReus',
      response: form?.DataCitacaoReus
    },
    {
      name: 'Valor histórico da causa ou do crédito',
      form_id: 'valorHist',
      response: form?.ValorHist
    },
    {
      name: 'Garantias prestadas',
      form_id: 'garantPrest',
      response: form?.GarantPrest
    },
    {
      name: 'Observações',
      form_id: 'observacoes',
      response: form?.Observacoes
    }
  ];
};
export const due_diligence_ma = (form) => {
  return [
    {
      name: 'Contexto negocial',
      form_id: 'contextoNeg',
      response: form?.ContextoNeg
    },
    {
      name: 'Data estimada para o negócio',
      form_id: 'dataEst',
      response: form?.DataEst
    },
    {
      name: 'Valor do negócio',
      form_id: 'valorNeg',
      response: form?.ValorNeg
    },
    {
      name: 'Garantias prestadas',
      form_id: 'garantPrest',
      response: form?.GarantPrest
    },
    {
      name: 'Observações',
      form_id: 'observacoes',
      response: form?.Observacoes
    }
  ];
};
export const preventive = (form) => {
  return [
    {
      name: 'Contexto negocial',
      form_id: 'contextoNeg',
      response: form?.ContextoNeg
    },
    {
      name: 'Objeto do negócio',
      form_id: 'objNeg',
      response: form?.ObjNeg
    },
    {
      name: 'Data estimada para o negócio',
      form_id: 'dataEst',
      response: form?.DataEst
    },
    {
      name: 'Valor do negócio',
      form_id: 'valorNeg',
      response: form?.ValorNeg
    },
    {
      name: 'Garantias',
      form_id: 'garantias',
      response: form?.Garantias
    },
    {
      name: 'Observações',
      form_id: 'observacoes',
      response: form?.Observacoes
    }
  ];
};
export const compliance = (form) => {
  return [
    {
      name: 'Denúncia',
      form_id: 'denuncia',
      response: form?.Denuncia
    },
    {
      name: 'Histórico ampliado',
      form_id: 'histAmp',
      response: form?.HistAmp
    },
    {
      name: 'Data de entrada na empresa ou de início do relacionamento',
      form_id: 'dataIni',
      response: form?.DataIni
    },
    {
      name: 'Início do período de não-conformidade',
      form_id: 'inicioPrd',
      response: form?.InicioPrd
    },
    {
      name: 'Fim do período de não-conformidade',
      form_id: 'fimPrd',
      response: form?.FimPrd
    },
    {
      name: 'Data de saída',
      form_id: 'dataSaida',
      response: form?.DataSaida
    },
    {
      name: 'Objetivos e escopo',
      form_id: 'objEsc',
      response: form?.ObjEsc
    },
    {
      name: 'Observações',
      form_id: 'observacoes',
      response: form?.Observacoes
    }
  ];
};
export const divorce = (form) => {
  return [
    {
      name: 'História do casal',
      form_id: 'histCasal',
      response: form?.HistCasal
    },
    {
      name: 'Inicio',
      form_id: 'inicio',
      response: form?.Inicio
    },
    {
      name: 'Fim',
      form_id: 'fim',
      response: form?.Fim
    },
    {
      name: 'Regime de bens',
      form_id: 'regimeBens',
      response: form?.RegimeBens
    },
    {
      name: 'Data do casamento',
      form_id: 'dataCas',
      response: form?.DataCas
    },
    {
      name: 'Bens conhecidos',
      form_id: 'bensConhec',
      response: form?.BensConhec
    },
    {
      name: 'Judicialização',
      form_id: 'judic',
      response: form?.Judic
    },
    {
      name: 'Rede principal de relacionamentos',
      form_id: 'redeRelac',
      response: form?.RedeRelac
    },
    {
      name: 'Objetivos e escopo',
      form_id: 'objEsc',
      response: form?.ObjEsc
    },
    {
      name: 'Observações',
      form_id: 'observacoes',
      response: form?.Observacoes
    }
  ];
};
export const inventary = (form) => {
  return [
    {
      name: 'Falecido (de cujus, autor da herança ou sucessão)',
      form_id: 'falecido',
      response: form?.Falecido
    },
    {
      name: 'Data de falecimento',
      form_id: 'dataObito',
      response: form?.DataObito
    },
    {
      name: 'Judicialização',
      form_id: 'judic',
      response: form?.Judic
    },
    {
      name: 'Bens conhecidos',
      form_id: 'bensConhec',
      response: form?.BensConhec
    },
    {
      name: 'Sucessores, credores e devedores',
      form_id: 'sucesCredDev',
      response: form?.SucesCredDev
    },
    {
      name: 'Regime de bens do de cujus',
      form_id: 'regimeBensCujus',
      response: form?.RegimeBensCujus
    },
    {
      name: 'Testamento',
      form_id: 'testamento',
      response: form?.Testamento
    },
    {
      name: 'Suspeitas de ocultamento',
      form_id: 'suspOcul',
      response: form?.SuspOcul
    },
    {
      name: 'Situação familiar',
      form_id: 'sitFam',
      response: form?.SitFam
    },
    {
      name: 'Objetivos e escopo',
      form_id: 'objEsc',
      response: form?.ObjEsc
    },
    {
      name: 'Observações',
      form_id: 'observacoes',
      response: form?.Observacoes
    }
  ];
};
export const docs = (form) => {
  return [
    {
      name: 'Pesquisa avulsa ou documento a ser solicitado',
      form_id: 'pesqAvuls',
      response: form?.PesqAvuls
    },
    {
      name: 'Objetivos e escopo',
      form_id: 'objEsc',
      response: form?.ObjEsc
    },
    {
      name: 'Observações',
      form_id: 'observacoes',
      response: form?.Observacoes
    }
  ];
};
export const joker = (form) => {
  return [
    {
      name: 'Objetivos e escopo',
      form_id: 'objEsc',
      response: form?.ObjEsc
    },
    {
      name: 'Observações',
      form_id: 'observacoes',
      response: form?.Observacoes
    }
  ];
};
export const imobiliary = (form) => {
  return [
    {
      name: 'Posição',
      form_id: 'posicao',
      response: form?.Posicao
    },
    {
      name: 'Imóvel',
      form_id: 'imovel',
      response: form?.Imovel
    },
    {
      name: 'Valor do Negócio',
      form_id: 'valorNeg',
      response: form?.ValorNeg
    },
    {
      name: 'Concessão de crédito',
      form_id: 'concessCred',
      response: form?.ConcessCred
    },
    {
      name: 'Observações',
      form_id: 'observacoes',
      response: form?.Observacoes
    }
  ];
};
export const due_diligence_pre = (form) => {
  return [
    {
      name: 'Contexto negocial',
      form_id: 'contNeg',
      response: form?.ContNeg
    },
    {
      name: 'Objeto do negócio',
      form_id: 'objNeg',
      response: form?.ObjNeg
    },
    {
      name: 'Data estimada para o negócio',
      form_id: 'dataEst',
      response: form?.DataEst
    },
    {
      name: 'Valor do negócio',
      form_id: 'valorNeg',
      response: form?.ValorNeg
    },
    {
      name: 'Garantias',
      form_id: 'garantias',
      response: form?.Garantias
    },
    {
      name: 'Observações',
      form_id: 'observacoes',
      response: form?.Observacoes
    }
  ];
};
export const credit_analysis = (form) => {
  return [
    {
      name: 'Valor do crédito',
      form_id: 'valorCred',
      response: form?.ValorCred
    },
    {
      name: 'Prazo',
      form_id: 'prazo',
      response: form?.Prazo
    },
    {
      name: 'Concessão de crédito',
      form_id: 'concessCred',
      response: form?.ConcessCred
    },
    {
      name: 'Tempo de relacionamento',
      form_id: 'tempoRelac',
      response: form?.TempoRelac
    },
    {
      name: 'Bem-sucedidas',
      form_id: 'numBemSuc',
      response: form?.NumBemSuc
    },
    {
      name: 'Intercorrências',
      form_id: 'numIntercor',
      response: form?.NumIntercor
    },
    {
      name: 'Observações',
      form_id: 'observacoes',
      response: form?.Observacoes
    }
  ];
};
