import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { primaryColor } from '../../../constants/colors';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: '#E6E6E6'
  },
  table: {
    width: '100%'
  }
}));

export default function RFsocios({ data }) {
  const classes = useStyles();

  return (
    <>
      <h3>Sócios da pessoa pesquisada:</h3>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Nome do Sócio</StyledTableCell>
              <StyledTableCell>CPF/CNPJ</StyledTableCell>
              <StyledTableCell>Empresa</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length ? (
              data.map(({ nome_socio, cnpj, company_name }) => (
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row">
                    {nome_socio}
                  </StyledTableCell>
                  <StyledTableCell align="left">{cnpj}</StyledTableCell>
                  <StyledTableCell align="left">{company_name}</StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell align="center" colSpan={3}>
                  <h3>
                    Não foram encontrados sócios da pessoa pesquisada em outras
                    pessoas jurídicas.
                  </h3>
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
