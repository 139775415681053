import React, { useRef, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import AddInfoAccordion from '../../components/AddInfoAccordion';
import {
  ButtonContainer,
  CloseContainer,
  InputNone,
  ProcButton,
  SendButton,
  StyledButton
} from './styled';
import {
  Button,
  MenuItem,
  Select,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../constants/urls';
import axios from 'axios';
import PublishIcon from '@material-ui/icons/Publish';
import { Cancel, Delete, Edit, Save } from '@material-ui/icons';
import ReactInputMask from 'react-input-mask';

const StyledTableCell = withStyles((theme) => ({
  head: {
    background: '#CF0865',
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%'
  },
  modal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #4a4a4a',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '98%',
    minHeight: '95%',
    maxHeight: '95%',
    overflowY: 'scroll',
    overflowX: 'hidden'
  }
}));

const UploadModal = ({ target, service }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedHall, setSelectedHall] = useState(null);
  const [onScreen, setOnscreen] = useState('none');
  const [dataForRegister, setDataForRegister] = useState([...service?.data]);
  const token = localStorage.getItem('token');
  const uploadRef = useRef();
  const myRef = useRef(null);

  const executeScroll = () => {
    setTimeout(() => {
      myRef.current.scrollIntoView();
    }, 50);
  };

  const handleOpen = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setOpen(false);
  };

  const sendForm = () => {
    const reqBody = {
      service_id: service.id,
      block_content: dataForRegister,
      target_id: target,
      dag_id: 'procuracoes'
    };
    axios
      .post(`${BASE_URL}/service/data/insert`, reqBody, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        toast.success(
          'Dados registrados com sucesso!, a página irá atualizar agora.'
        );
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      })
      .catch((error) => {
        toast.error('Erro ao registrar dados!');
      });
  };

  const readExcel = (file) => {
    if (/\.(xls?x)$/i.test(file?.name)) {
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
          e.stopPropagation();
          const buffferArray = e.target.result;

          const wb = XLSX.read(buffferArray, { type: 'buffer' });

          const wsname = wb.SheetNames[0];

          const ws = wb.Sheets[wsname];

          const data = XLSX.utils.sheet_to_json(ws);

          resolve(data);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });

      promise.then((data) => {
        const dataList = data
          .filter(
            (item) =>
              item['Nome'] &&
              item['CPF/CNPJ/OAB'] &&
              item['Identidade'] &&
              item['Cartório'] &&
              item['CNS'] &&
              item['Livro'] &&
              item['Folha'] &&
              item['Data do Ato'] &&
              item['Tipo Ato'] &&
              item['Nautreza da Escritura'] &&
              item['Natureza (Outra)'] &&
              item['Valor'] &&
              item['Litigio'] &&
              item['Acordo'] &&
              item['Partes']
          )
          .map((item) => {
            return {
              nome: item['Nome'] || '-',
              cpf_cnpj_oab: item['CPF/CNPJ/OAB'] || '-',
              identidade: item['Identidade'] || '-',
              cartorio: item['Cartório'] || '-',
              cns: item['CNS'] || '-',
              livro: item['Livro'] || '-',
              folha: item['Folha'] || '-',
              data_do_ato: item['Data do Ato'] || '-',
              tipo_ato: item['Tipo Ato'] || '-',
              natureza_escritura: item['Nautreza da Escritura'] || '-',
              natureza: item['Natureza (Outra)'] || '-',
              valor: item['Valor'] || '-',
              litigio: item['Litigio'] || '-',
              acordo: item['Acordo'] || '-',
              partes: item['Partes'] || '-'
            };
          });
        if (dataList.length) {
          setDataForRegister([...dataForRegister, ...dataList]);
          uploadRef.current.value = null;
        } else {
          toast.warning(
            'O documento deve conter as tabelas no formato válido!.'
          );
          uploadRef.current.value = null;
        }
      });
    } else {
      toast.error(
        'Envie um tipo de arquivo válido(Apenas formato .xls ou xlsx)'
      );
      uploadRef.current.value = null;
    }
  };

  const stateUpdate = (element) => {
    const updatedArray = dataForRegister.filter((value, index) => {
      return element !== index;
    });
    setDataForRegister(updatedArray);
  };

  const editValue = (item, index) => {
    setOnscreen('block');
    setSelectedHall({ ...item, index });
  };

  const onChangeTableValue = (e, fieldName) => {
    setSelectedHall({ ...selectedHall, [fieldName]: e.target.value });
  };

  const onBlurTableValue = (index) => {
    dataForRegister[index] = selectedHall;
    setDataForRegister(dataForRegister);
    setSelectedHall(null);
  };

  return (
    <>
      <ProcButton
        variant="outlined"
        size="small"
        onClick={(event) => handleOpen(event)}
      >
        Adicionar dados
      </ProcButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={(event) => handleClose(event)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        style={{ color: '#4a4a4a' }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <CloseContainer>
              <Button onClick={(event) => handleClose(event)}>
                <Cancel color="secondary" />
              </Button>
            </CloseContainer>
            <ButtonContainer style={{ marginBottom: '20px' }}>
              <h2 id="transition-modal-title">Enviar dados de procurações</h2>
              <SendButton color="primary" variant="outlined" onClick={sendForm}>
                Registrar
              </SendButton>
            </ButtonContainer>
            <ButtonContainer style={{ marginBottom: '40px' }}>
              <strong style={{ marginTop: 20 }}>
                Clique no botão para fazer o envio da planilha.
              </strong>
              <InputNone
                ref={uploadRef}
                type="file"
                accept=".xls,.xlsx,.xlsb"
                onChange={(e) => {
                  const file = e.target.files[0];
                  readExcel(file);
                }}
              />
              <Button
                style={{ marginTop: '10px' }}
                onClick={() => {
                  uploadRef.current.click();
                }}
                variant="outlined"
                color="primary"
              >
                <PublishIcon color="primary" />
              </Button>
            </ButtonContainer>
            {dataForRegister.length > 0 && (
              <TableContainer component={Paper} style={{ minHeight: '500px' }}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Nome</StyledTableCell>
                      <StyledTableCell align="center">
                        CPF/CNPJ/OAB
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Identidade
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Natureza Escritura
                      </StyledTableCell>
                      <StyledTableCell align="center">Natureza</StyledTableCell>
                      <StyledTableCell align="center">Valor</StyledTableCell>
                      <StyledTableCell align="center">Litígio</StyledTableCell>
                      <StyledTableCell align="center">Acordo</StyledTableCell>
                      <StyledTableCell align="center">Partes</StyledTableCell>
                      <StyledTableCell align="center">Cartório</StyledTableCell>
                      <StyledTableCell align="center">Cns</StyledTableCell>
                      <StyledTableCell align="center">Livro</StyledTableCell>
                      <StyledTableCell align="center">Folha</StyledTableCell>
                      <StyledTableCell align="center">
                        Data do Ato
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Tipo do Ato
                      </StyledTableCell>
                      <StyledTableCell align="center">Excluir</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {dataForRegister.map((item, index) => (
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell
                          align="center"
                          component="th"
                          scope="row"
                          value={item.nome}
                        >
                          {selectedHall?.index === index && (
                            <TextField
                              value={selectedHall?.nome}
                              style={{ display: `${onScreen}` }}
                              onChange={(e) => onChangeTableValue(e, 'nome')}
                            />
                          )}
                          {selectedHall?.index !== index &&
                            (item.nome ? item.nome : '-')}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          component="th"
                          scope="row"
                          value={item.cpf_cnpj_oab}
                        >
                          {selectedHall?.index === index && (
                            <TextField
                              value={selectedHall?.cpf_cnpj_oab}
                              style={{ display: `${onScreen}` }}
                              onChange={(e) =>
                                onChangeTableValue(e, 'cpf_cnpj_oab')
                              }
                            />
                          )}
                          {selectedHall?.index !== index &&
                            (item.cpf_cnpj_oab ? item.cpf_cnpj_oab : '-')}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          component="th"
                          scope="row"
                          value={item.identidade}
                        >
                          {selectedHall?.index === index && (
                            <TextField
                              value={selectedHall?.identidade}
                              style={{ display: `${onScreen}` }}
                              onChange={(e) =>
                                onChangeTableValue(e, 'identidade')
                              }
                            />
                          )}
                          {selectedHall?.index !== index &&
                            (item.identidade ? item.identidade : '-')}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          component="th"
                          scope="row"
                          value={item.natureza_escritura}
                        >
                          {selectedHall?.index === index && (
                            <TextField
                              value={selectedHall?.natureza_escritura}
                              style={{ display: `${onScreen}` }}
                              onChange={(e) =>
                                onChangeTableValue(e, 'natureza_escritura')
                              }
                            />
                          )}
                          {selectedHall?.index !== index &&
                            (item.natureza_escritura
                              ? item.natureza_escritura
                              : '-')}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          component="th"
                          scope="row"
                          value={item.natureza}
                        >
                          {selectedHall?.index === index && (
                            <TextField
                              value={selectedHall?.natureza}
                              style={{ display: `${onScreen}` }}
                              onChange={(e) =>
                                onChangeTableValue(e, 'natureza')
                              }
                            />
                          )}
                          {selectedHall?.index !== index &&
                            (item.natureza ? item.natureza : '-')}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          component="th"
                          scope="row"
                          value={item.valor}
                        >
                          {selectedHall?.index === index && (
                            <TextField
                              value={selectedHall?.valor}
                              style={{ display: `${onScreen}` }}
                              onChange={(e) => onChangeTableValue(e, 'valor')}
                            />
                          )}
                          {selectedHall?.index !== index &&
                            (item.valor ? item.valor : '-')}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          component="th"
                          scope="row"
                          value={item.litigio}
                        >
                          {selectedHall?.index === index && (
                            <TextField
                              value={selectedHall?.litigio}
                              style={{ display: `${onScreen}` }}
                              onChange={(e) => onChangeTableValue(e, 'litigio')}
                            />
                          )}
                          {selectedHall?.index !== index &&
                            (item.litigio ? item.litigio : '-')}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          component="th"
                          scope="row"
                          value={item.acordo}
                        >
                          {selectedHall?.index === index && (
                            <TextField
                              value={selectedHall?.acordo}
                              style={{ display: `${onScreen}` }}
                              onChange={(e) => onChangeTableValue(e, 'acordo')}
                            />
                          )}
                          {selectedHall?.index !== index &&
                            (item.acordo ? item.acordo : '-')}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          component="th"
                          scope="row"
                          value={item.partes}
                        >
                          {selectedHall?.index === index && (
                            <TextField
                              value={selectedHall?.partes}
                              style={{ display: `${onScreen}` }}
                              onChange={(e) => onChangeTableValue(e, 'partes')}
                            />
                          )}
                          {selectedHall?.index !== index &&
                            (item.partes ? item.partes : '-')}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          component="th"
                          scope="row"
                          value={item.cartorio}
                        >
                          {selectedHall?.index === index && (
                            <TextField
                              value={selectedHall?.cartorio}
                              style={{ display: `${onScreen}` }}
                              onChange={(e) =>
                                onChangeTableValue(e, 'cartorio')
                              }
                            />
                          )}
                          {selectedHall?.index !== index &&
                            (item.cartorio ? item.cartorio : '-')}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          component="th"
                          scope="row"
                          value={item.cns}
                        >
                          {selectedHall?.index === index && (
                            <TextField
                              value={selectedHall?.cns}
                              style={{ display: `${onScreen}` }}
                              onChange={(e) => onChangeTableValue(e, 'cns')}
                            />
                          )}
                          {selectedHall?.index !== index &&
                            (item.cns ? item.cns : '-')}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          component="th"
                          scope="row"
                          value={item.livro}
                        >
                          {selectedHall?.index === index && (
                            <TextField
                              value={selectedHall?.livro}
                              style={{ display: `${onScreen}` }}
                              onChange={(e) => onChangeTableValue(e, 'livro')}
                            />
                          )}
                          {selectedHall?.index !== index &&
                            (item.livro ? item.livro : '-')}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          component="th"
                          scope="row"
                          value={item.folha}
                        >
                          {selectedHall?.index === index && (
                            <TextField
                              value={selectedHall?.folha}
                              style={{ display: `${onScreen}` }}
                              onChange={(e) => onChangeTableValue(e, 'folha')}
                            />
                          )}
                          {selectedHall?.index !== index &&
                            (item.folha ? item.folha : '-')}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          component="th"
                          scope="row"
                          value={item.data_do_ato}
                        >
                          {selectedHall?.index === index && (
                            <ReactInputMask
                              variant="outlined"
                              mask="99/99/9999"
                              value={selectedHall?.data_do_ato}
                              style={{
                                display: `${onScreen}`,
                                marginBottom: '0.999rem'
                              }}
                              onChange={(e) =>
                                onChangeTableValue(e, 'data_do_ato')
                              }
                              fullWidth
                              margin={'normal'}
                              type={'text'}
                            >
                              {(inputProps) => (
                                <TextField {...inputProps} type="tel" />
                              )}
                            </ReactInputMask>
                          )}
                          {selectedHall?.index !== index &&
                            (item.data_do_ato ? item.data_do_ato : '-')}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          component="th"
                          scope="row"
                          value={item.tipo_ato}
                        >
                          {selectedHall?.index === index && (
                            <Select
                              value={selectedHall?.tipo_ato}
                              style={{ display: `${onScreen}` }}
                              onChange={(e) =>
                                onChangeTableValue(e, 'tipo_ato')
                              }
                            >
                              <MenuItem value="Escritura">Escritura</MenuItem>
                              <MenuItem value="Procuração">Procuração</MenuItem>
                              <MenuItem value="Substabelecimento">
                                Substabelecimento
                              </MenuItem>
                              <MenuItem value="Inventário">Inventário</MenuItem>
                              <MenuItem value="Retificação">
                                Retificação
                              </MenuItem>
                              <MenuItem value="Sobrepartilha">
                                Sobrepartilha
                              </MenuItem>
                              <MenuItem value="Divórcio Direto">
                                Divórcio Direto
                              </MenuItem>
                            </Select>
                          )}
                          {selectedHall?.index !== index &&
                            (item.tipo_ato ? item.tipo_ato : '-')}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          component="th"
                          scope="row"
                        >
                          <StyledButton
                            variant="outlined"
                            onClick={() => stateUpdate(index)}
                            color="secondary"
                            startIcon={<Delete />}
                            style={{ marginBottom: '20px' }}
                          >
                            Excluir
                          </StyledButton>
                          {selectedHall?.index !== index && (
                            <StyledButton
                              variant="outlined"
                              color="primary"
                              startIcon={<Edit />}
                              onClick={() => editValue(item, index)}
                            >
                              Editar
                            </StyledButton>
                          )}
                          {selectedHall?.index === index && (
                            <StyledButton
                              variant="outlined"
                              color="primary"
                              startIcon={<Save />}
                              onClick={() => onBlurTableValue(index)}
                            >
                              Finalizar Edição
                            </StyledButton>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                      <div style={{ height: 0 }} ref={myRef} />
                    </TableBody>
                  ))}
                </Table>
              </TableContainer>
            )}
            <AddInfoAccordion
              style={{ marginTop: '20px' }}
              data={dataForRegister}
              setData={setDataForRegister}
              executeScroll={executeScroll}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default UploadModal;
