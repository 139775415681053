import React from 'react';
import { Typography, AppBar, Tabs, Tab, Box, Badge } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { H3 } from '../styled';
import { CardScore } from './CardScore';
import { CardNegative } from './CardNegative';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

const StyledBadge = withStyles((theme) => ({
  badge: {
    left: 50,
    padding: '0 4px'
  }
}))(Badge);

export default function Quod({ data }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Tabs Processos"
        >
          <Tab
            label="Informações de Score"
            {...a11yProps(0)}
            icon={<StyledBadge color="secondary" />}
          />
          <Tab
            label="Negativações"
            {...a11yProps(1)}
            icon={<StyledBadge color="secondary" />}
          />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        {data.score_info ? (
          <CardScore score_info={data.score_info} />
        ) : (
          <H3>Sem informações de score.</H3>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {data.negative ? (
          <CardNegative negative={data.negative} />
        ) : (
          <H3>Sem informações de negativações.</H3>
        )}
      </TabPanel>
    </>
  );
}
