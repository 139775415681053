import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
`;

export const LogoImage = styled.img`
  width: auto;
  max-width: 350px;
  margin-bottom: 1rem;
`;

export const Card = styled.div`
  padding: 2rem;
  width: 50%;
  border-radius: 4px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const H1 = styled.h1`
  margin: 1rem 0;
  text-align: center;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
