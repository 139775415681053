import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Typography
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { primaryColor } from '../../../../constants/colors';
import { Paper } from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 12
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: '#E6E6E6'
  },
  table: {
    width: '100%'
  }
}));

const TableProcessos = ({ processes, status, values }) => {
  const classes = useStyles();

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={9}>
                <Typography variant="h6">
                  Processos {status ? 'Ativos' : 'Inativos'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={9}>
                <p>
                  Total de Processos no Polo Ativo:&nbsp;
                  {values?.polo_ativo &&
                    (status
                      ? `${values.polo_ativo.abertos.valor_total}
                        em ${values.polo_ativo.abertos.qtd} Processo(s)`
                      : `${values.polo_ativo.fechados.valor_total}
                        em ${values.polo_ativo.fechados.qtd} Processo(s)`)}
                </p>
                <p>
                  Total de Processos no Polo Passivo:&nbsp;
                  {values?.polo_passivo &&
                    (status
                      ? `${values.polo_passivo.abertos.valor_total}
                        em ${values.polo_passivo.abertos.qtd} Processo(s)`
                      : `${values.polo_passivo.fechados.valor_total}
                        em ${values.polo_passivo.fechados.qtd} Processo(s)`)}
                </p>
              </TableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Orgão Julgador</StyledTableCell>
              <StyledTableCell>Seção do Tribunal</StyledTableCell>
              <StyledTableCell>Distrito</StyledTableCell>
              <StyledTableCell>Data de Distribuição</StyledTableCell>
              <StyledTableCell>Nº do Processo</StyledTableCell>
              <StyledTableCell>Valor da Ação</StyledTableCell>
              {/* <StyledTableCell>Juiz</StyledTableCell> */}
              <StyledTableCell>Polo</StyledTableCell>
              <StyledTableCell>Área do Direito</StyledTableCell>
              <StyledTableCell>Classe</StyledTableCell>
              <StyledTableCell>Assunto</StyledTableCell>
              <StyledTableCell>Pessoas Relacionadas</StyledTableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {processes?.length ? (
              processes.map(
                ({
                  court,
                  court_section,
                  district,
                  distribution_date,
                  number,
                  declared_value,
                  pole,
                  nature,
                  kind,
                  subject,
                  related_people,
                  active
                }) =>
                  active === status && (
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        {court}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {court_section}
                      </StyledTableCell>
                      <StyledTableCell align="left">{district}</StyledTableCell>
                      <StyledTableCell align="left">
                        {distribution_date}
                      </StyledTableCell>
                      <StyledTableCell align="left">{number}</StyledTableCell>
                      <StyledTableCell align="left">
                        {declared_value}
                      </StyledTableCell>
                      {/* <StyledTableCell align="left">{judge}</StyledTableCell> */}
                      <StyledTableCell align="left">{pole}</StyledTableCell>
                      <StyledTableCell align="left">{kind}</StyledTableCell>
                      <StyledTableCell align="left">{nature}</StyledTableCell>
                      <StyledTableCell align="left">{subject}</StyledTableCell>
                      <StyledTableCell align="left">
                        {related_people.map((person) => (
                          <StyledTableRow>
                            <StyledTableCell align="left">
                              {person.name} ({person.role?.toLowerCase()})
                              {person.lawers?.length > 0 && (
                                <>
                                  <p
                                    style={{
                                      textAlign: 'center',
                                      fontWeight: 'bold',
                                      fontSize: 10
                                    }}
                                  >
                                    ADVOGADOS
                                  </p>
                                  {person.lawers.map((adv) => (
                                    <p>{adv}</p>
                                  ))}
                                </>
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
              )
            ) : (
              <StyledTableRow>
                <StyledTableCell align="center" colSpan={9}>
                  Nenhum Resultado Encontrado.
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableProcessos;
