import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { primaryColor } from '../../../constants/colors';
import {
  Container,
  StyledTable,
  StyledTableBody,
  StyledTableContainer
} from './styled';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

export default function SocialMedia({ data }) {
  return (
    <Container>
      <StyledTableContainer>
        <StyledTable>
          <TableHead>
            <TableRow>
              <StyledTableCell>Mídia Social</StyledTableCell>
              <StyledTableCell>URL</StyledTableCell>
            </TableRow>
          </TableHead>
          <StyledTableBody>
            {data.length ? (
              data.map(({ title, link }) => (
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row">
                    {title}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <a target="_blank" rel="noreferrer" href={link}>
                      {link}
                    </a>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell align="center" colSpan={2}>
                  Sem Resultados
                </StyledTableCell>
              </StyledTableRow>
            )}
          </StyledTableBody>
        </StyledTable>
      </StyledTableContainer>
    </Container>
  );
}
