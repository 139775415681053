import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { primaryColor } from '../../../constants/colors';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: '#E6E6E6'
  },
  table: {
    width: '100%'
  },
  td: {
    maxWidth: 300,
    wordWrap: 'break-word'
  }
}));

export default function Ecosystem({ data }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table
        className={classes.table}
        size="small"
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell>Nome:</StyledTableCell>
            <StyledTableCell>Relação</StyledTableCell>
            <StyledTableCell>CPF/CNPJ</StyledTableCell>
            <StyledTableCell>Idade / Abertura</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length ? (
            data.map(
              ({ name, relation, cpf_cnpj, birthdate }, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {relation}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {cpf_cnpj}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {birthdate}
                  </StyledTableCell>
                </StyledTableRow>
              )
            )
          ) : (
            <StyledTableRow>
              <StyledTableCell align="center" colSpan={3}>
                Sem Resultados
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}