import Header from '../../../components/Header';
import useProtectedPage from '../../../hooks/useProtectedPage';
import {
  Box,
  Button,
  ChakraProvider,
  Checkbox,
  extendTheme,
  Flex,
  Heading,
  Icon,
  Link,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react';
import SideBar from '../../../components/SideBar';
import { RiAddLine } from 'react-icons/ri';
import { Fonts } from '../../../styles/fonts';
import axios from 'axios';
import { BASE_URL } from '../../../constants/urls';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { goToCreateUser, goToUser } from '../../../routes/coordinator';

const Users = () => {
  useProtectedPage();
  console.error = () => {};
  const token = localStorage.getItem('token');
  const [clients, setClients] = useState([]);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const history = useHistory();

  const theme = extendTheme({
    fonts: {
      heading: 'Open Sans',
      body: 'Raleway'
    }
  });

  const getUsers = async () => {
    const response = await axios.get(`${BASE_URL}/register`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    setClients(response?.data?.data);
    setIsLoadingPage(false);
  };

  const onClickDetails = (id) => {
    goToUser(history, id);
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoadingPage(true);
        await getUsers();
      } catch (error) {}
    })();
  }, [token]);

  const returnClients = clients.map((client) => {
    return (
      <Tbody>
        <Tr>
          <Td>
            <Box>
              <Text fontWeight="bold">{client?.fantasy_name}</Text>
              <Text fontWeight="sm" color="gray.500">
                {client?.email}
              </Text>
            </Box>
          </Td>
          <Td>{client?.cpf_cnpj}</Td>
          <Td>{client?.position}</Td>
          <Td>{client?.phone ? client?.phone : 'Não informado'}</Td>
          <Td>
            <Link>
              <Button
                onClick={() => onClickDetails(client?.id)}
                as="a"
                size="sm"
                fontSize="sm"
                colorScheme="blue"
              >
                Ver Detalhes
              </Button>
            </Link>
          </Td>
        </Tr>
      </Tbody>
    );
  });

  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <Box>
        <Header />
        <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
          <SideBar />

          {isLoadingPage ? (
            <Box flex="1" borderRadius={8} bg="gray.50" p="8">
              <Progress isIndeterminate />
            </Box>
          ) : (
            <>
              <Box
                flex="1"
                borderRadius={8}
                bg="gray.50"
                p="8"
                overflowX="scroll"
              >
                <Flex mb="8" justify="space-between" align="center">
                  <Heading size="lg" fontWeight="normal">
                    Cadastro Geral
                  </Heading>
                  <Link>
                    <Button
                      as="a"
                      size="sm"
                      fontSize="sm"
                      colorScheme="pink"
                      leftIcon={<Icon as={RiAddLine} />}
                      onClick={() => goToCreateUser(history)}
                    >
                      Criar novo
                    </Button>
                  </Link>
                </Flex>

                <Table colorScheme="whiteAlpha">
                  <Thead>
                    <Tr>
                      <Th>Nome</Th>
                      <Th>CPF/CNPJ</Th>
                      <Th>Cargo</Th>
                      <Th>Telefone</Th>
                      <Th width="8"></Th>
                    </Tr>
                  </Thead>
                  {returnClients}
                </Table>
              </Box>
            </>
          )}
        </Flex>
      </Box>
    </ChakraProvider>
  );
};

export default Users;
