import styled from 'styled-components';
import GroupAddTwoToneIcon from '@material-ui/icons/GroupAddTwoTone';
import { Button } from '@material-ui/core';
import { primaryColor } from '../../constants/colors';

export const UserIcon = styled(GroupAddTwoToneIcon)`
  margin-left: 0.5rem;
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`;

export const ButtonContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledButton = styled(Button)`
  padding: 0.7rem;
  margin-right: 1rem;
  width: auto;
  @media screen and (max-width: 768px) {
    font-size: 0;
    padding: 1rem;
    height: 50px;
  }
`;

export const AddButton = styled(Button)`
  padding: 0.7rem;
  width: 80%;
`;

export const InputNone = styled.input`
  text-align: center;
  display: flex;
  margin-top: 2rem;
  align-items: center;
  width: auto;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  padding: 8px;
  justify-content: center;
  pointer-events: none;
  ::-webkit-file-upload-button {
    display: none;
  }
`;

export const SendButton = styled(Button)`
  padding: 0.7rem;
  background-color: ${primaryColor};
  margin-top: 2rem;
  color: white;
  width: 100%;
  :hover {
    background-color: ${primaryColor};
  }
`;

export const Strong = styled.strong`
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
