import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useProtectedPage from '../../../hooks/useProtectedPage';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast, ToastContainer } from 'react-toastify';
import api from '../../../services/api/api';
import { Card, Container, H1, Header } from './styled';
import ContextSmart from './Context';
import ParecerCreditRecovery from './Parecer/CreditRecovery';
import ParecerCreditAnalysis from './Parecer/CreditAnalysis';
import FrontsCreditRecovery from './Fronts/CreditRecovery';
import FrontsCreditAnalysis from './Fronts/CreditAnalysis';

export default function Smart() {
  useProtectedPage();
  const params = useParams();
  const token = localStorage.getItem('token');
  const [caseSmart, setCaseSmart] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);
        const response = await api.post(
          '/create_smart',
          { case_id: params.id },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setCaseSmart(response.data);
      } catch (error) {
        toast.error('Houve um erro ao criar o smart!');
      } finally {
        setIsLoading(false);
      }
    };
    loadData();
  }, [params, token]);

  return (
    <>
      <ToastContainer />
      <Container>
        {isLoading ? (
          <CircularProgress size={50} />
        ) : (
          caseSmart.smart && (
            <Card>
              <Header>
                <H1>REPORT SMART - Caso {caseSmart.name?.toUpperCase()}</H1>

                {caseSmart.smart.context.smart_id === "credit_recovery" && (
                  <>
                  <ParecerCreditRecovery context_id={caseSmart.smart.context.smart_id} />
                  {caseSmart.smart.fronts.map((front, index) => (
                    <Container key={index}>
                      <FrontsCreditRecovery front={front} />
                    </Container>
                  ))}
                  </>
                )}

                {caseSmart.smart.context.smart_id === "credit_analysis" && (
                  <>
                  <ParecerCreditAnalysis context_id={caseSmart.smart.context.smart_id} />
                  {caseSmart.smart.fronts.map((front, index) => (
                    <Container key={index}>
                      <FrontsCreditAnalysis front={front} />
                    </Container>
                  ))}
                  </>
                )}

                <ContextSmart caseDetail={caseSmart} />
              </Header>
            </Card>
          )
        )}
      </Container>
    </>
  );
}
