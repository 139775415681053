import {
  Box,
  Button,
  ChakraProvider,
  createStandaloneToast,
  Divider,
  extendTheme,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Link,
  Progress,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  VStack
} from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import InputBase from '../../../../components/ChakraInput/Input';
import Header from '../../../../components/Header';
import SideBar from '../../../../components/SideBar';
import { BASE_URL } from '../../../../constants/urls';
import useProtectedPage from '../../../../hooks/useProtectedPage';
import { goToContexts } from '../../../../routes/coordinator';
import { Fonts } from '../../../../styles/fonts';

const ContextDetails = () => {
  useProtectedPage();
  console.error = () => {};
  const token = localStorage.getItem('token');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [context, setContext] = useState([]);
  const history = useHistory();
  const params = useParams();

  const {
    register,
    reset,
    handleSubmit,
    formState,
    formState: { errors }
  } = useForm({ defaultValues: context });

  const id = 'test-toast';

  const theme = extendTheme({
    fonts: {
      heading: 'Open Sans',
      body: 'Raleway'
    }
  });

  const toast = createStandaloneToast({ theme: theme });

  const getData = async () => {
    const response = await axios.get(
      `${BASE_URL}/context/${params.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      },
      [reset]
    );
    setIsLoadingPage(false);
    setContext(response?.data);
    reset(response?.data);
  };

  const updateContext = async (form) => {
    setIsLoading(true);

    const userId = params.id;

    const reqBody = {
      name: form.name ? form.name : context?.name,
      label: form.label ? form.label : context?.label,
      smart_id: form.smart_id ? form.name : context?.smart_id
    };

    const response = await axios
      .put(`${BASE_URL}/context/${userId}`, reqBody, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        setIsLoading(false);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: 'Contexto atualizado com sucesso!',
            description: 'A página irá atualizar em alguns instantes',
            status: 'success',
            duration: 9000,
            isClosable: true
          });
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        const msg = error?.response?.data?.detail?.map((msg) => msg.msg);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: 'Houve um erro ao atualizar o contexto',
            description: msg,
            status: 'error',
            duration: 9000,
            isClosable: true
          });
          setIsLoading(false);
        }
      });
  };

  const returnProducts = context.products?.map((product) => {
    return (
      <Tbody>
        <Tr>
          <Td>
            <Box>
              <Text fontWeight="bold">{product?.name}</Text>
            </Box>
          </Td>
          <Td>{product?.id}</Td>
          <Td>{product?.label}</Td>
        </Tr>
      </Tbody>
    );
  });

  useEffect(() => {
    (async () => {
      try {
        setIsLoadingPage(true);
        await getData();
      } catch (error) {}
    })();
  }, [token]);

  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <Box>
        <Header />

        <Flex
          as="form"
          noValidate
          onSubmit={handleSubmit(updateContext)}
          w="100%"
          my="6"
          maxWidth={1480}
          mx="auto"
          px="6"
        >
          <SideBar />

          <Box flex="1" borderRadius={8} bg="gray.50" p={['6', '8']}>
            {isLoadingPage ? (
              <Progress isIndeterminate />
            ) : (
              <>
                <Heading size="lg" fontWeight="normal">
                  Dados gerais
                </Heading>

                <Divider my="6" borderColor="gray.700" />

                <VStack spacing="8">
                  <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                    <FormControl>
                      <FormLabel>Nome</FormLabel>
                      <Textarea
                        {...register('name')}
                        name="Name"
                        type="text"
                        focusBorderColor="pink.500"
                        bgColor="gray.200"
                        variant="filled"
                        _hover={{
                          bgColor: 'grey.100'
                        }}
                        size="lg"
                      />
                    </FormControl>
                  </SimpleGrid>
                </VStack>

                <VStack spacing="8">
                  <SimpleGrid minChildWidth="240px" mt="8" spacing="8" w="100%">
                    <FormControl>
                      <FormLabel>Label</FormLabel>
                      <Textarea
                        {...register('label')}
                        name="Label"
                        type="text"
                        focusBorderColor="pink.500"
                        bgColor="gray.200"
                        variant="filled"
                        _hover={{
                          bgColor: 'grey.100'
                        }}
                        size="lg"
                      />
                    </FormControl>
                  </SimpleGrid>
                </VStack>

                <VStack spacing="8">
                  <SimpleGrid minChildWidth="240px" mt="8" spacing="8" w="100%">
                    <InputBase
                      {...register('smart_id')}
                      name="smart_id"
                      type="text"
                      label="Smart Id"
                    />
                  </SimpleGrid>
                </VStack>

                <Heading size="lg" marginTop="50" fontWeight="normal">
                  Produtos com este contexto
                </Heading>

                <Divider my="6" borderColor="gray.700" />

                <VStack spacing="8">
                  <Table colorScheme="whiteAlpha">
                    <Thead>
                      <Tr>
                        <Th>PRODUTO</Th>
                        <Th>ID</Th>
                        <Th>LABEL</Th>
                        <Th width="8"></Th>
                      </Tr>
                    </Thead>
                    {returnProducts}
                  </Table>
                </VStack>

                <Flex mt="8" justify="flex-end">
                  <HStack spacing="4">
                    <Link onClick={() => goToContexts(history)}>
                      <Button as="a" colorScheme="pink">
                        Cancelar
                      </Button>
                    </Link>
                    <Button
                      isLoading={isLoading}
                      type="submit"
                      colorScheme="blue"
                    >
                      Salvar
                    </Button>
                  </HStack>
                </Flex>
              </>
            )}
          </Box>
        </Flex>
      </Box>
    </ChakraProvider>
  );
};

export default ContextDetails;
