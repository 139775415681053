import {
  Avatar,
  Box,
  Button,
  ChakraProvider,
  createStandaloneToast,
  Divider,
  extendTheme,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Link,
  Progress,
  Select,
  SimpleGrid,
  Text,
  Textarea,
  VStack
} from '@chakra-ui/react';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import InputBase from '../../../../components/ChakraInput/Input';
import Header from '../../../../components/Header';
import ModalCadUsuer from '../../../../components/ModalCadUser';
import SideBar from '../../../../components/SideBar';
import { BASE_URL } from '../../../../constants/urls';
import useProtectedPage from '../../../../hooks/useProtectedPage';
import { goToUsers } from '../../../../routes/coordinator';
import { Fonts } from '../../../../styles/fonts';
import validaCpfCnpj from '../../../../utils/teste';
import { CPFandCNPJ } from '../../../../utils/validate';

const UserDetails = () => {
  useProtectedPage();
  console.error = () => {};
  const token = localStorage.getItem('token');
  const [client, setClient] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [color, setColor] = useState('red.500');
  const [cpf, setCpf] = useState('');
  const history = useHistory();
  const params = useParams();
  const [value, setValue] = useState('');
  const handleChange = (event) => setValue(event.target.value);

  const {
    register,
    reset,
    handleSubmit,
    formState,
    formState: { errors }
  } = useForm({ defaultValues: client });

  const id = 'test-toast';

  const theme = extendTheme({
    fonts: {
      heading: 'Open Sans',
      body: 'Raleway'
    }
  });

  const onChangeCpf = useCallback((event) => {
    setCpf(CPFandCNPJ(event.target.value));
    let isValid = false;
    if (
      event.target.value.toString().replace(/[^0-9]/g, '').length === 11 ||
      event.target.value.toString().replace(/[^0-9]/g, '').length === 14
    ) {
      isValid = validaCpfCnpj(CPFandCNPJ(event.target.value));
      if (isValid) {
        setColor('green.500');
      } else {
        setColor('red.500');
      }
    } else {
      setColor('red.500');
    }
  }, []);

  const toast = createStandaloneToast({ theme: theme });

  const getData = async () => {
    const response = await axios.get(
      `${BASE_URL}/register/${params.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      },
      [reset]
    );
    setIsLoadingPage(false);
    setClient(response?.data);
    setCpf(response?.data?.cpf_cnpj);
    reset(response?.data);
  };

  const updateUser = async (form) => {
    setIsLoading(true);

    let cpfOrCnpj = 0;

    if (form.cpf_cnpj) {
      if (cpf.toString().replace(/[^0-9]/g, '').length === 11) {
        cpfOrCnpj = 1;
      } else {
        cpfOrCnpj = 2;
      }
    } else {
      if (client?.cpf_cnpj.toString().replace(/[^0-9]/g, '').length === 11) {
        cpfOrCnpj = 1;
      } else {
        cpfOrCnpj = 2;
      }
    }

    const reqBody = {
      cpf_cnpj: form.cpf_cnpj ? form.cpf_cnpj : client?.cpf_cnpj,
      fantasy_name: form.fantasy_name
        ? form.fantasy_name
        : client?.fantasy_name,
      type_registration: cpfOrCnpj,
      company_name: form.company_name
        ? form.company_name
        : client?.company_name,
      contact: form.contact ? form.contact : client?.contact,
      phone: form.phone ? form.phone : client?.phone,
      phone2: form.phone2 ? form.phone2 : client?.phone2,
      email: form.email ? form.email : client?.email,
      zip_code: form.zip_code ? form.zip_code : client?.zip_code,
      address: form.address ? form.address : client?.address,
      number: form.number ? form.number : client?.number,
      complement: form.complement ? form.complement : client?.complement,
      neighborhood: form.neighborhood
        ? form.neighborhood
        : client?.neighborhood,
      city: form.city ? form.city : client?.city,
      state: form.state ? form.state : client?.state,
      obs: form.obs ? form.obs : client?.obs,
      follow_id: form.followup ? form.followup?.id : client?.followup?.id,
      office_id: form.office ? form.office?.id : client?.office?.id,
      position_id: form.position ? form.position?.id : client?.position?.id,
      activate: form.enable ? form.enable : client?.enable
    };

    const userId = params.id;

    const response = await axios
      .put(`${BASE_URL}/register/${userId}`, reqBody, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        setIsLoading(false);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: 'Cadastro atualizado com sucesso!',
            description: 'A página irá atualizar em alguns instantes',
            status: 'success',
            duration: 9000,
            isClosable: true
          });
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        const msg = error?.response?.data?.detail?.map((msg) => msg.msg);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: 'Houve um erro ao atualizar o cadastro',
            description: msg,
            status: 'error',
            duration: 9000,
            isClosable: true
          });
          setIsLoading(false);
        }
      });
  };

  const modifyUserStatus = async (userId) => {
    setIsLoading(true);

    const response = await axios
      .patch(`${BASE_URL}/register/${params.id}/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(() => {
        if (!toast.isActive(id)) {
          toast({
            id,
            title: 'Usuário atualizado com sucesso!',
            status: 'success',
            duration: 9000,
            isClosable: true
          });
          setTimeout(() => {
            getData();
            setIsLoading(false);
          }, 2000);
        }
      })
      .catch((error) => {
        setIsLoading(false);

        const msg = error?.response?.data?.detail?.map((msg) => msg.msg);
        if (!toast.isActive(id)) {
          toast({
            title: 'Falha ao atualizar usuário!',
            description: msg,
            status: 'error',
            duration: 5000,
            isClosable: true
          });
        }
      });
  };

  const returnButton = (active, id) => {
    if (active) {
      return (
        <>
          <Button
            isLoading={isLoading}
            onClick={() => modifyUserStatus(id)}
            size="xs"
            colorScheme="red"
          >
            {active ? 'Desabilitar' : 'Habilitar'}
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button
            isLoading={isLoading}
            onClick={() => modifyUserStatus(id)}
            size="xs"
            colorScheme="green"
          >
            {active ? 'Desabilitar' : 'Habilitar'}
          </Button>
        </>
      );
    }
  };

  const returnUsers = client.users?.map((user) => {
    return (
      <SimpleGrid minChildWidth="240px" spacing={['6', '8']} w="100%">
        <Flex>
          <Avatar src="" size="xl" />
          <Box ml="3">
            <Text fontWeight="bold">{user?.username}</Text>
            <Text fontSize="sm">Id: {user?.id}</Text>
            <Text fontSize="sm">Ativo: {user?.enable ? 'Sim' : 'Não'}</Text>
            {returnButton(user?.enable, user?.id)}
          </Box>
        </Flex>
      </SimpleGrid>
    );
  });

  useEffect(() => {
    (async () => {
      try {
        setIsLoadingPage(true);
        await getData();
      } catch (error) {}
    })();
  }, [token]);

  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <Box>
        <Header />

        <Flex
          as="form"
          noValidate
          onSubmit={handleSubmit(updateUser)}
          w="100%"
          my="6"
          maxWidth={1480}
          mx="auto"
          px="6"
        >
          <SideBar />

          <Box flex="1" borderRadius={8} bg="gray.50" p={['6', '8']}>
            {isLoadingPage ? (
              <Progress isIndeterminate />
            ) : (
              <>
                <Heading size="lg" fontWeight="normal">
                  Dados gerais
                </Heading>

                <Divider my="6" borderColor="gray.700" />

                <VStack spacing="8">
                  <SimpleGrid
                    minChildWidth="240px"
                    spacing={['6', '8']}
                    w="100%"
                  >
                    <InputBase
                      {...register('fantasy_name')}
                      name="fantasy_name"
                      type="text"
                      label="Nome Fantasia"
                      onChange={handleChange}
                    />

                    <FormControl>
                      <FormLabel>CPF/CNPJ</FormLabel>
                      <Input
                        {...register('cpf_cnpj')}
                        placeholder={client?.cpf_cnpj}
                        name={'cpf_cnpj'}
                        value={cpf}
                        onChange={onChangeCpf}
                        focusBorderColor="pink.500"
                        bgColor="gray.200"
                        variant="filled"
                        _placeholder={{ color: 'black' }}
                        _hover={{
                          bgColor: 'grey.100'
                        }}
                        size="lg"
                      />
                    </FormControl>
                  </SimpleGrid>

                  <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                    <InputBase
                      {...register('contact')}
                      placeholder={client?.contact}
                      name="contact"
                      type="text"
                      label="Razão Social"
                    />
                    <InputBase
                      {...register('email')}
                      placeholder={client?.email}
                      name="email"
                      type="email"
                      label="E-mail"
                    />
                  </SimpleGrid>

                  <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                    <InputBase
                      {...register('phone')}
                      placeholder={client?.phone}
                      name="phone"
                      type="number"
                      label="Telefone 1"
                    />
                    <InputBase
                      {...register('phone2')}
                      placeholder={client?.phone2}
                      name="phone2"
                      type="text"
                      label="Telefone 2"
                    />
                  </SimpleGrid>

                  <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                    <InputBase
                      {...register('company_name')}
                      placeholder={client?.company_name}
                      name="company_name"
                      type="text"
                      label="Nome Compania"
                    />

                    <FormControl>
                      <FormLabel>Ativo</FormLabel>
                      <Select
                        {...register('enable')}
                        size="lg"
                        focusBorderColor="pink.500"
                        bgColor="gray.200"
                        variant="filled"
                        _hover={{
                          bgColor: 'grey.100'
                        }}
                        placeholder="Selecionar..."
                      >
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                      </Select>
                    </FormControl>
                  </SimpleGrid>

                  <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                    <FormControl>
                      <FormLabel>Área</FormLabel>
                      <Select
                        {...register('followup')}
                        size="lg"
                        focusBorderColor="pink.500"
                        bgColor="gray.200"
                        variant="filled"
                        _hover={{
                          bgColor: 'grey.100'
                        }}
                        placeholder={client?.followup?.name}
                        defaultValue={client?.followup?.id}
                      >
                        <option value="1">Não se Aplica</option>
                        <option value="2">Jurídico</option>
                        <option value="3">Indústria</option>
                        <option value="4">Consultoria</option>
                        <option value="5">Finanças</option>
                        <option value="6">Comércio</option>
                        <option value="7">Público</option>
                      </Select>
                    </FormControl>

                    <FormControl>
                      <FormLabel>Posição</FormLabel>
                      <Select
                        {...register('position')}
                        size="lg"
                        focusBorderColor="pink.500"
                        bgColor="gray.200"
                        variant="filled"
                        _hover={{
                          bgColor: 'grey.100'
                        }}
                        placeholder={client?.position?.name}
                        defaultValue={client?.position?.id}
                      >
                        <option value="1">Prospect</option>
                        <option value="2">Cliente</option>
                        <option value="3">Colaborador</option>
                        <option value="4">Parceiro</option>
                      </Select>
                    </FormControl>
                  </SimpleGrid>

                  <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                    <FormControl>
                      <FormLabel>Cargo</FormLabel>
                      <Select
                        {...register('office')}
                        size="lg"
                        focusBorderColor="pink.500"
                        bgColor="gray.200"
                        variant="filled"
                        _hover={{
                          bgColor: 'grey.100'
                        }}
                        placeholder={client?.office?.name}
                        defaultValue={client?.office?.id}
                      >
                        <option value="1">Não se Aplica</option>
                        <option value="2">CEO</option>
                        <option value="3">Sócio</option>
                        <option value="4">Diretor</option>
                        <option value="5">Legal</option>
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Observações</FormLabel>
                      <Textarea
                        {...register('obs')}
                        placeholder={client?.obs}
                        name="obs"
                        type="text"
                        focusBorderColor="pink.500"
                        bgColor="gray.200"
                        variant="filled"
                        _placeholder={{ color: 'black' }}
                        _hover={{
                          bgColor: 'grey.100'
                        }}
                        size="lg"
                      />
                    </FormControl>
                  </SimpleGrid>
                </VStack>

                <Flex mt="16" justify="space-between" align="center">
                  <Heading size="lg" fontWeight="normal">
                    Usuários Registrados
                  </Heading>
                  <Link>
                    <ModalCadUsuer id={params.id} />
                  </Link>
                </Flex>

                <Divider my="6" borderColor="gray.700" />

                <VStack spacing="8">
                  {client.users?.length > 0 ? (
                    <>{returnUsers}</>
                  ) : (
                    <Text fontSize="3xl">Não há registros</Text>
                  )}
                </VStack>

                <Heading size="lg" marginTop="50" fontWeight="normal">
                  Endereço
                </Heading>

                <Divider my="6" borderColor="gray.700" />

                <VStack spacing="8">
                  <SimpleGrid
                    minChildWidth="240px"
                    spacing={['6', '8']}
                    w="100%"
                  >
                    <InputBase
                      {...register('zip_code')}
                      placeholder={client?.zip_code}
                      name="zip_code"
                      type="number"
                      label="CEP"
                    />
                    <InputBase
                      {...register('state', { maxLength: 2 })}
                      placeholder={client?.state}
                      name="state"
                      type="text"
                      maxLength="2"
                      label="Estado"
                    />
                  </SimpleGrid>

                  <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                    <InputBase
                      {...register('city')}
                      placeholder={client?.city}
                      name="city"
                      type="text"
                      label="Cidade"
                    />
                    <InputBase
                      {...register('neighborhood')}
                      placeholder={client?.neighborhood}
                      name="neighborhood"
                      type="text"
                      label="Bairro"
                    />
                  </SimpleGrid>

                  <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                    <InputBase
                      {...register('address')}
                      placeholder={client?.address}
                      name="address"
                      type="text"
                      label="Rua"
                    />
                    <InputBase
                      {...register('number')}
                      placeholder={client?.number}
                      name="number"
                      type="text"
                      label="Número"
                    />
                  </SimpleGrid>

                  <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                    <FormControl>
                      <FormLabel>Complemento</FormLabel>
                      <Textarea
                        {...register('complement')}
                        _placeholder={{ color: 'black' }}
                        placeholder={client?.complement}
                        name="complement"
                        type="text"
                        focusBorderColor="pink.500"
                        bgColor="gray.200"
                        variant="filled"
                        _hover={{
                          bgColor: 'grey.100'
                        }}
                        size="lg"
                      />
                    </FormControl>
                  </SimpleGrid>
                </VStack>

                <Flex mt="8" justify="flex-end">
                  <HStack spacing="4">
                    <Link onClick={() => goToUsers(history)}>
                      <Button as="a" colorScheme="pink">
                        Cancelar
                      </Button>
                    </Link>
                    <Button
                      isLoading={isLoading}
                      type="submit"
                      colorScheme="blue"
                    >
                      Salvar
                    </Button>
                  </HStack>
                </Flex>
              </>
            )}
          </Box>
        </Flex>
      </Box>
    </ChakraProvider>
  );
};

export default UserDetails;
