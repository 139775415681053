import { Card, Typography, CardContent, Avatar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { H3 } from '../../styled';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  avatar: {
    backgroundColor: 'gray',
    width: theme.spacing(8),
    height: theme.spacing(8)
  }
}));

export function CardScore({ score_info }) {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      {score_info.error_message && (
        <CardContent>
          <Alert severity="warning">
            <AlertTitle>Aviso!</AlertTitle>
            <strong>{score_info.error_message}</strong>
          </Alert>
        </CardContent>
      )}
      <CardContent align="center">
        <H3>Score</H3>
        <Avatar aria-label="recipe" className={classes.avatar}>
          <h4>{score_info.score}</h4>
        </Avatar>
      </CardContent>
      <CardContent align="center">
        <H3>Motivos:</H3>
        {score_info.reasons?.length ? (
          score_info.reasons.map((reason) => (
            <Typography color="textSecondary">{reason};</Typography>
          ))
        ) : (
          <Typography color="textSecondary">Sem resultados.</Typography>
        )}
      </CardContent>
    </Card>
  );
}
