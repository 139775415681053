import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  Grid,
  Typography,
  CardContent,
  Divider
} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: '100%',
    marginBottom: '10px'
  }
});

export default function CardArquivamentos({ arquivamentos }) {
  const classes = useStyles();

  return arquivamentos?.length > 0 ? (
    arquivamentos.map(
      ({
        entidade,
        data,
        resumo_analise,
        descricao_do_ato,
        nome_da_pessoa_juridica,
        cnpj,
        capital_social
      }) => (
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography
              variant="h6"
              component="div"
              style={{ marginBottom: '2rem' }}
            >
              {entidade}
            </Typography>
            <Typography color="textSecondary">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <p>
                    <strong>Data:</strong> {data}
                  </p>
                  <p>
                    <strong>Capital Social:</strong> {capital_social}
                  </p>
                  <p>
                    <strong>Resumo/Analise:</strong> {resumo_analise}
                  </p>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <p>
                    <strong>Nome da Pessoa Pesquisada:</strong>{' '}
                    {nome_da_pessoa_juridica}
                  </p>
                  <p>
                    <strong>CNPJ:</strong> {cnpj}
                  </p>
                </Grid>
              </Grid>
            </Typography>
            {descricao_do_ato.length > 0 && (
              <Grid align="justify" style={{ marginTop: 12 }}>
                <Divider />
                <Typography
                  className={classes.dividerInset}
                  color="textSecondary"
                  display="block"
                  variant="caption"
                  align="center"
                  style={{ marginBottom: '2rem', marginTop: '2rem' }}
                >
                  Descrição do Ato
                </Typography>
                {descricao_do_ato.map((item) => (
                  <Typography
                    className={classes.root}
                    style={{ marginBottom: '2rem' }}
                  >
                    {item}
                  </Typography>
                ))}
              </Grid>
            )}
          </CardContent>
        </Card>
      )
    )
  ) : (
    <Typography variant="body2" component="p">
      Nenhum resultado encontrado.
    </Typography>
  );
}
