import Typography from '@material-ui/core/Typography';
import ParecerAmarelo from '../../../../../assets/ParecerAmarelo.png';
import { LogoImage, H1 } from '../../styled';

export default function ParecerCreditRecovery({ context_id }) {
  const currentDate = () => {
    const date = new Date();
    const monthName = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro'
    ];

    return (
      date.getDate() +
      ' de ' +
      monthName[date.getMonth()] +
      ' de ' +
      date.getFullYear()
    );
  };

  return (
    <>
      <Typography variant="h6" align="center" gutterBottom>
        PARECER
      </Typography>
      <LogoImage src={ParecerAmarelo} />
      <Typography variant="body1" align="justify" gutterBottom>
        Entendemos que a recuperação do crédito do caso em referência parece ser{' '}
        <strong>desafiadora</strong> ou incerta. Não se vislumbra, neste
        momento, bens óbvios ou medidas imediatas e com chance considerável de
        retorno. Por outro lado, há dados e informações que, se aprofundados,
        podem levar a medidas que se mostrem frutíferas, conforme indicado
        abaixo, de modo que ainda é cedo para afirmar que o caso é
        irrecuperável. Assim sendo, caso haja suficiente interesse e
        disponibilidade de recursos, recomenda-se seguir com cautela com as
        medidas de recuperação de crédito cabível e, se possível, investir mais
        no caso adquirindo relatórios ou contratando pesquisas mais
        aprofundadas.
      </Typography>
      <Typography variant="body1" align="justify" gutterBottom>
        Se desejar auxílio para aprofundamento deste relatório, para efetivação
        dos destaques e medidas que estão sendo indicadas (
        <strong>ver abaixo</strong>), para dar feedback, sugerir melhorias, ou
        para tirar qualquer dúvida sobre este relatório,{' '}
        <strong>clique aqui</strong>.
      </Typography>
      <Typography variant="body1" gutterBottom style={{ marginBottom: '20px' }}>
        São Paulo/SP, <strong>{currentDate()}</strong>.
      </Typography>
      <Typography
        variant="body1"
        align="justify"
        gutterBottom
        style={{ marginBottom: '20px' }}
      >
        <strong>Obs.</strong>: Essa é a conclusão As informações, destaques,
        conclusões e todo o conteúdo presente neste relatório foi obtida
        exclusivamente com a análise dos dados que apareceram nos obtidos com o
        produto “<strong>REPORTS</strong>” gerados (conforme pesquisas abaixo).
        Tendo em vista se tratar de coletânea, organização e análise de dados
        preliminares e públicos, obtidos em repartições públicas, bureaus de
        dados e afins, é natural que haja certa margem de imprecisões, falsos
        positivos ou falsos negativos. Para análise mais profundaassertiva,
        recomenda-se fortemente a realização de pesquisas mais aprofundadas, o
        que pode ser solicitado aqui. No mais, a utilização deste dossiê
        pressupõe concordância irrestrita com os termos de uso, que podem ser
        acessados&nbsp;
        <a href="https://www.dropbox.com/s/30qzuong4uyhdq8/Termos%20de%20uso%20e%20avisos%20legais%20-%20v1.pdf?dl=0">
          aqui
        </a>
        .
      </Typography>
      <H1>Destaques e Recomendações Técnicas</H1>
      <Typography variant="body1" align="center" gutterBottom>
        Tendo em vista os dados encontrados, pode-se indicar os seguintes{' '}
        <strong>destaques</strong>:
      </Typography>
    </>
  );
}
