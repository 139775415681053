import * as vanillaMasker from '../assets/vanilla-min';

const cnpjPattern = '99.999.999/9999-99';
const cpfPattern = '999.999.999-99';

export const onlyNumsString = (value) =>
  value.toString().replace(/[^0-9]/g, '');

export const CNPJ = (value) =>
  vanillaMasker.toPattern(onlyNumsString(value), cnpjPattern);

export const CPF = (value) =>
  vanillaMasker.toPattern(onlyNumsString(value), cpfPattern);

export const CPFandCNPJ = (value) =>
  value && onlyNumsString(value).length < 12 ? CPF(value) : CNPJ(value);
