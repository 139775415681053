import { Grid } from '@material-ui/core';
import { StyledP } from './styled';

export default function Credilink({ data }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <p>
          <strong>Nome:</strong> {data?.name}
        </p>
        <StyledP>
          <strong>CPF/CNPJ:</strong> {data?.cpf_cnpj}
        </StyledP>
        <StyledP>
          <strong>Status Receita:</strong> {data?.status_receita}
        </StyledP>
        {data?.cpf_cnpj?.length === 18 && (
          <>
            <StyledP>
              <strong>Nome Fantasia:</strong> {data?.fantasy_name}
            </StyledP>
            <StyledP>
              <strong>Situação:</strong> {data?.cadastral.situation}
            </StyledP>
            <StyledP>
              <strong>Natureza legal:</strong> {data?.legal_nature}
            </StyledP>
            <StyledP>
              <strong>Capital Social:</strong> {data?.capital_social}
            </StyledP>
            <StyledP>
              <strong>E-mail:</strong> {data?.email_company}
            </StyledP>
            <StyledP>
              <strong>Endereço:</strong>{' '}
              <p>
                {data?.address_company.complement} -{' '}
                {data?.address_company.number} -{' '}
                {data?.address_company.neighborhood}-{' '}
                {data?.address_company.city} - {data?.address_company.province}{' '}
                - {data?.address_company.zipcode}
              </p>
            </StyledP>
            <StyledP>
              <strong>Telefones:</strong>{' '}
              {data?.phones_company.map((phone) => phone)}
            </StyledP>
            <StyledP>
              <strong>Data? de abertura:</strong> {data?.start_date}
            </StyledP>
            <StyledP>
              <strong>CNAE Fiscal:</strong> <p>{data?.cnae}</p>
            </StyledP>
            <StyledP>
              <strong>CNAE Secundário:</strong>{' '}
              {data?.cnae_secundaria.map((cnae) => (
                <p>{cnae}</p>
              ))}
            </StyledP>
            <StyledP>
              <strong>QSA:</strong>
              {data?.qsa.map((qsa) => (
                <p>
                  {qsa.name} - {qsa.cpf_cnpj} ({qsa.type})
                </p>
              ))}
            </StyledP>
          </>
        )}
        {data?.cpf_cnpj?.length === 14 && (
          <>
            <StyledP>
              <strong>RG:</strong> {data?.identity_number}
            </StyledP>
            <StyledP>
              <strong>Sexo:</strong> {data?.sex}
            </StyledP>
            <StyledP>
              <strong>Endereços:</strong>{' '}
              {data?.address.map((addres) => (
                <p>
                  {addres.address} - {addres.number} - {addres.neighborhood}-{' '}
                  {addres.city} - {addres.province} - {addres.zipcode}
                </p>
              ))}
            </StyledP>
            <StyledP>
              <strong>Nome da Mãe:</strong> {data?.mother_name}
            </StyledP>
            <StyledP>
              <strong>Data de Nascimento:</strong> {data?.birthdate}
            </StyledP>
            <StyledP>
              <strong>Óbito:</strong> {data?.date_death}
            </StyledP>
            <StyledP>
              <strong>Telefones:</strong>{' '}
              {data?.phones.map((phone) => (
                <p>{phone}</p>
              ))}
            </StyledP>
            <StyledP>
              <strong>E-mails:</strong>{' '}
              {data?.emails.map((email) => (
                <p> {email}</p>
              ))}
            </StyledP>
          </>
        )}
      </Grid>
    </Grid>
  );
}
