import React from 'react';
import { Typography, AppBar, Tabs, Tab, Box, Badge } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { CardRf } from './CardRf';
import { H3 } from '../styled';
import TableFiliais from './TableFiliais';
import EconomicGroup from './EconomicGroup';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

const StyledBadge = withStyles((theme) => ({
  badge: {
    left: 50,
    padding: '0 4px'
  }
}))(Badge);

export default function Rf({ data }) {
  const [value, setValue] = React.useState(0);
  const qtdActual = data.actual ? data.actual.length : 0;
  const qtdHolders = data.holders ? data.holders.length : 0;
  const qtdFiliais = data.filiais ? data.filiais.length : 0;
  const qtdIdpj = data.idpj ? data.idpj.length : 0;
  const qtdGroup = data.group?.group ? data.group.group.length : 0;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Tabs Receita Federal"
        >
          <Tab
            label="Empresas Atuais"
            {...a11yProps(0)}
            icon={<StyledBadge color="secondary" badgeContent={qtdActual} />}
          />
          <Tab
            label="Empresas Não Atuais"
            {...a11yProps(1)}
            icon={<StyledBadge color="secondary" badgeContent={qtdHolders} />}
          />
          <Tab
            label="Filiais"
            {...a11yProps(2)}
            icon={<StyledBadge color="secondary" badgeContent={qtdFiliais} />}
          />
          <Tab
            label="IDPJ"
            {...a11yProps(3)}
            icon={<StyledBadge color="secondary" badgeContent={qtdIdpj} />}
          />
          <Tab
            label="Grupo Econômico"
            {...a11yProps(4)}
            icon={<StyledBadge color="secondary" badgeContent={qtdGroup} />}
          />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <H3>
          Pessoas jurídicas nas quais o(a) pesquisado(a) tem participação ou
          relação:
        </H3>
        <CardRf data={data.actual} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <H3>
          Pessoas jurídicas nas quais o(a) pesquisado(a) teve participação ou
          relação:
        </H3>
        <CardRf data={data.holders} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        {data.filiais ? (
          <>
            <H3>CNPJs vinculados à mesma pessoa jurídica:</H3>
            <TableFiliais filiais={data.filiais} />
          </>
        ) : (
          <p>Nenhum resultado encontrado.</p>
        )}
      </TabPanel>
      <TabPanel value={value} index={3}>
        <CardRf data={data.idpj} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        {data.group ? (
          <EconomicGroup data={data.group} />
        ) : (
          <p>Nenhum resultado encontrado.</p>
        )}
      </TabPanel>
    </>
  );
}
