import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { primaryColor } from '../../../../../../constants/colors';
import { Paper } from '@material-ui/core';

import { Li } from '../styled';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%'
  },
  markTarget: {
    fontWeight: 'bold',
    color: 'red'
  }
}));

export default function FrontProcessActive({ front }) {
  const classes = useStyles();

  return (
    <>
      <li>
        <h2>{front.front}</h2>
      </li>
      <ul>
        <Li>
          <strong>Considerações e/ou fatos relevantes:</strong>
          <ul>
            <Li>
              Considerando que em nome de um ou mais pesquisado(s) foi(ram)
              encontrado(s) os seguintes processos aparentemente ativos (isto é,
              nos quais o(a) pesquisado(a) possivelmente terá créditos a
              receber):
            </Li>
          </ul>
        </Li>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Data de Distribuição</StyledTableCell>
                <StyledTableCell>Nº do Processo</StyledTableCell>
                <StyledTableCell>Orgão Julgador</StyledTableCell>
                <StyledTableCell>Valor da Ação</StyledTableCell>
                <StyledTableCell>Polo</StyledTableCell>
                <StyledTableCell>Pessoas Relacionadas</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {front.rule.map(
                ({
                  distribution_date,
                  number,
                  court,
                  declared_value,
                  pole,
                  related_people
                }) => (
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                      {distribution_date}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <a
                        href={`https://www.jusbrasil.com.br/consulta-processual/busca?q=${number}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {number}
                      </a>
                    </StyledTableCell>
                    <StyledTableCell align="left">{court}</StyledTableCell>
                    <StyledTableCell align="left">
                      {declared_value}
                    </StyledTableCell>
                    <StyledTableCell align="left">{pole}</StyledTableCell>
                    <StyledTableCell align="left">
                      {related_people.map((person) => (
                        <p className={person.target && classes.markTarget}>
                          {person.name.toUpperCase()} ({person.role})
                        </p>
                      ))}
                    </StyledTableCell>
                  </StyledTableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Li>
          <strong>Conclusão/recomendações</strong>. Em razão dessas
          considerações, recomenda-se consultar a viabilidade jurídica e avaliar
          a conveniência e oportunidade estratégica de se promover as seguintes
          medidas:
          <ul>
            <Li>
              Verificar a possibilidade de se requerer penhora no rosto dos
              autos.
            </Li>
          </ul>
        </Li>
        <Li>
          Caso haja interesse em solicitar auxílio para tais tarefas e análises,{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://forms.monday.com/forms/53cda30c0b17fcb6c85e4a23a3ec7fec?r=use1"
          >
            <strong>clique aqui</strong>
          </a>
          .
        </Li>
      </ul>
    </>
  );
}
