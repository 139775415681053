import {
  Box,
  Button,
  ChakraProvider,
  createStandaloneToast,
  Divider,
  extendTheme,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Link,
  Progress,
  Select,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import InputBase from '../../../../components/ChakraInput/Input';
import Header from '../../../../components/Header';
import SideBar from '../../../../components/SideBar';
import { BASE_URL } from '../../../../constants/urls';
import useProtectedPage from '../../../../hooks/useProtectedPage';
import { goToProducts } from '../../../../routes/coordinator';
import { Fonts } from '../../../../styles/fonts';
import AddInfoModal from '../AddInfoModal';
import DeleteModal from './DeleteModal';
import { useCurrency as currencyFormatter } from 'react-hook-currency';

const ProductDetails = () => {
  useProtectedPage();
  console.error = () => {};
  const token = localStorage.getItem('token');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [product, setProduct] = useState([]);
  const [services, setServices] = useState([]);
  const [contexts, setContexts] = useState([]);
  const history = useHistory();
  const params = useParams();
  const currency = {
    style: 'currency',
    precision: 2,
    locale: 'pt-BR',
    currency: 'BRL'
  };
  const { onChange, format } = currencyFormatter(currency);
  const defaultValue = format('000');

  const {
    register,
    reset,
    handleSubmit,
    formState,
    formState: { errors }
  } = useForm({ defaultValues: product });

  const id = 'test-toast';

  const theme = extendTheme({
    fonts: {
      heading: 'Open Sans',
      body: 'Raleway'
    }
  });

  const toast = createStandaloneToast({ theme: theme });

  const filterNumbers = (string) => {
    var numsStr = string.replace(/[^0-9]/g, '');
    return parseInt(numsStr);
  };

  const getData = async () => {
    const response = await axios.get(
      `${BASE_URL}/product/${params.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      },
      [reset]
    );
    setIsLoadingPage(false);
    setProduct(response?.data);
    setServices(response?.data?.services);
    setContexts(response?.data?.contexts);
    reset(response?.data);
  };

  const updateProduct = async (form) => {
    setIsLoading(true);

    const productId = params.id;

    const reqBody = {
      name: form.name ? form.name : product?.name,
      label: form.label ? form.label : product?.label,
      value: form.value
        ? filterNumbers(form.value)
        : filterNumbers(product?.value),
      enable: form.enable ? form.enable : product?.enable
    };

    const response = await axios
      .put(`${BASE_URL}/product/${productId}`, reqBody, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        setIsLoading(false);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: 'Produto atualizado com sucesso!',
            description: 'A página irá atualizar em alguns instantes',
            status: 'success',
            duration: 9000,
            isClosable: true
          });
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        const msg = error?.response?.data?.detail?.map((msg) => msg.msg);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: 'Houve um erro ao atualizar o produto',
            description: msg,
            status: 'error',
            duration: 9000,
            isClosable: true
          });
          setIsLoading(false);
        }
      });
  };

  const returnContexts = contexts?.map((context) => {
    return (
      <Tbody>
        <Tr>
          <Td>
            <Box>
              <Text fontWeight="bold">{context?.name}</Text>
            </Box>
          </Td>
          <Td>{context?.label}</Td>
          <Td>{context?.smart_id}</Td>
          <Td>
            <Link>
              <DeleteModal
                initialState={contexts}
                changeState={setContexts}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                typeId={context?.id}
                type="Contexto"
              />
            </Link>
          </Td>
        </Tr>
      </Tbody>
    );
  });

  const returnServices = services?.map((service) => {
    return (
      <Tbody>
        <Tr>
          <Td>
            <Box>
              <Text fontWeight="bold">{service?.name}</Text>
            </Box>
          </Td>
          <Td>{service?.dag_id}</Td>
          <Td>
            <Link>
              <DeleteModal
                initialState={services}
                changeState={setServices}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                typeId={service?.id}
                type="Serviço"
              />
            </Link>
          </Td>
        </Tr>
      </Tbody>
    );
  });

  useEffect(() => {
    (async () => {
      try {
        setIsLoadingPage(true);
        await getData();
      } catch (error) {}
    })();
  }, [token]);

  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <Box>
        <Header />

        <Flex
          as="form"
          noValidate
          onSubmit={handleSubmit(updateProduct)}
          w="100%"
          my="6"
          maxWidth={1480}
          mx="auto"
          px="6"
        >
          <SideBar />

          <Box flex="1" borderRadius={8} bg="gray.50" p={['6', '8']}>
            {isLoadingPage ? (
              <Progress isIndeterminate />
            ) : (
              <>
                <Heading size="lg" fontWeight="normal">
                  Dados gerais
                </Heading>

                <Divider my="6" borderColor="gray.700" />

                <VStack spacing="8">
                  <SimpleGrid minChildWidth="240px" mt="8" spacing="8" w="100%">
                    <InputBase
                      {...register('name')}
                      name="name"
                      type="text"
                      label="Nome"
                    />
                    <InputBase
                      {...register('label')}
                      name="label"
                      type="text"
                      label="Label"
                    />
                  </SimpleGrid>
                </VStack>

                <VStack spacing="8">
                  <SimpleGrid minChildWidth="240px" mt="8" spacing="8" w="100%">
                    <InputBase
                      {...register('value')}
                      name="value"
                      type="text"
                      label="Valor"
                      onChange={onChange}
                      defaultValue={defaultValue}
                    />
                    <FormControl>
                      <FormLabel>Ativo</FormLabel>
                      <Select
                        {...register('enable')}
                        size="lg"
                        focusBorderColor="pink.500"
                        bgColor="gray.200"
                        variant="filled"
                        _hover={{
                          bgColor: 'grey.100'
                        }}
                        placeholder="Selecionar..."
                      >
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                      </Select>
                    </FormControl>
                  </SimpleGrid>
                </VStack>

                <Flex
                  mb="8"
                  justify="space-between"
                  marginTop="50"
                  align="center"
                >
                  <Heading size="lg" fontWeight="normal">
                    Contextos
                  </Heading>
                  <Link>
                    <AddInfoModal
                      type="Contexto"
                      initialState={contexts}
                      changeState={setContexts}
                    />
                  </Link>
                </Flex>

                <Divider my="6" borderColor="gray.700" />

                <VStack spacing="8">
                  <Table colorScheme="whiteAlpha">
                    <Thead>
                      <Tr>
                        <Th>NOME</Th>
                        <Th>LABEL</Th>
                        <Th>SMART ID</Th>
                        <Th>EXCLUIR</Th>
                        <Th width="8"></Th>
                      </Tr>
                    </Thead>
                    {returnContexts}
                  </Table>
                </VStack>

                <Flex
                  mb="8"
                  justify="space-between"
                  marginTop="50"
                  align="center"
                >
                  <Heading size="lg" fontWeight="normal">
                    Serviços
                  </Heading>
                  <Link>
                    <AddInfoModal
                      type="Serviço"
                      initialState={services}
                      changeState={setServices}
                    />
                  </Link>
                </Flex>

                <Divider my="6" borderColor="gray.700" />

                <VStack spacing="8">
                  <Table colorScheme="whiteAlpha">
                    <Thead>
                      <Tr>
                        <Th>NOME</Th>
                        <Th>DAG ID</Th>
                        <Th>EXCLUIR</Th>
                        <Th width="8"></Th>
                      </Tr>
                    </Thead>
                    {returnServices}
                  </Table>
                </VStack>

                <Flex mt="8" justify="flex-end">
                  <HStack spacing="4">
                    <Link onClick={() => goToProducts(history)}>
                      <Button as="a" colorScheme="pink">
                        Cancelar
                      </Button>
                    </Link>
                    <Button
                      isLoading={isLoading}
                      type="submit"
                      colorScheme="blue"
                    >
                      Salvar
                    </Button>
                  </HStack>
                </Flex>
              </>
            )}
          </Box>
        </Flex>
      </Box>
    </ChakraProvider>
  );
};

export default ProductDetails;
