import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header';
import useProtectedPage from '../../hooks/useProtectedPage';
import {
  Card,
  Container,
  H1,
  H4,
  ButtonContainer,
  StyledFormControl,
  StyledButton,
  GrafoIcon,
  SmartIcon,
  RefreshContainer,
  StyledRefresh,
  StyledSendData,
  ExcelIcon
} from './styled';
import { toast, ToastContainer } from 'react-toastify';
import TargetTabs from '../../components/Tabs';
import api from '../../services/api/api';
import {
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
  Tooltip
} from '@material-ui/core';
import axios from 'axios';
import { BASE_URL, GRAPH_URL } from '../../constants/urls';
import SimpleModal from '../../components/Modal';
import ContextFormAccordion from '../../components/ContextFormAccordion';
import { CloudUpload, Refresh } from '@material-ui/icons';
import ModalSendData from '../../components/ModalSendBlocks';
import ModalDocx from '../../components/ModalDocx';

const CaseDetailsPage = () => {
  useProtectedPage();
  const params = useParams();
  const token = localStorage.getItem('token');
  const [caseDetails, setCaseDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);
        const response = await api.get(`/case/${params.id}`);
        console.log({case: response.data})
        setCaseDetails(response.data);
      } catch (error) {
        toast.error('Houve um erro ao carregar o caso!.');
      } finally {
        setIsLoading(false);
      }
    };
    loadData();
  }, [params, token]);

  const listTargets = caseDetails.targets?.map((target) => target.id);

  const CpfList = caseDetails.targets?.map((target) => target.cpf_cnpj);

  const TargetsName = caseDetails.targets?.map((target) => {
    return {
      name: target.name,
      cpf_cnpj: target.cpf_cnpj
    };
  });

  const exportProcess = (event) => {
    const alert = caseDetails.targets?.every((target) =>
      target.services
        ?.filter(
          (service) =>
            service?.dag_id === 'jusbrasil' && service?.dag_id === 'processos'
        )
        .every((service) => service.success)
    );

    if (!alert) {
      toast.error(
        'Os serviços de processos ainda não foram finalizados, por favor tente novamente mais tarde.'
      );
    } else {
      let smart = false;

      if (event.target.value === 2) {
        smart = true;
      }

      axios
        .post(
          `${BASE_URL}/case/${params.id}/report/proccess`,
          {
            targets: listTargets,
            smart: smart
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then((response) => {
          toast.success(`Email enviado com sucesso para ${response.data.msg}`);
        })
        .catch((error) => {
          toast.error('Houve um erro ao enviar o Email!.');
        });
    }
  };

  const refreshEcoSystem = () => {
    setIsLoading(true);
    axios
      .post(
        `${BASE_URL}/graph`,
        {
          case_id: params.id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then((response) => {
        toast.success(`Ecosistema atualizado com sucesso!`);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error('Houve um erro ao atualizar o ecosistema!.');
        setIsLoading(false);
      });
  };

  console.error = () => {};

  return (
    <>
      <Header />
      <ToastContainer />
      <Container>
        {isLoading ? (
          <CircularProgress size={80} />
        ) : (
          <Card>
            <H1>{caseDetails.name}</H1>
            <H4>Cliente: {caseDetails.client?.name}</H4>
            <H4>E-mail: {caseDetails.client?.email}</H4>
            <H4>CPF/CNPJ: {caseDetails.client?.cpf_cnpj}</H4>
            <H4>Tipo: {caseDetails.product?.name}</H4>
            <H4>Criado em: {caseDetails.created_at}</H4>
            {caseDetails.context && (
              <>
                <H4>Contexto: {caseDetails.context.name}</H4>
                <RefreshContainer>
                  <ModalSendData
                    name={caseDetails.name}
                    targets={TargetsName}
                  />
                  <StyledRefresh
                    variant="outlined"
                    color="primary"
                    onClick={refreshEcoSystem}
                    endIcon={<Refresh />}
                  >
                    Atualizar Grapho
                  </StyledRefresh>
                </RefreshContainer>
                {caseDetails.context_form?.length === 0 ? (
                  <></>
                ) : (
                  <ContextFormAccordion form={caseDetails.context_form} />
                )}
                <ButtonContainer style={{ marginTop: '20px' }}>
                  <SimpleModal uuid={caseDetails.uuid} CpfList={CpfList} />

                  <a
                    href={`${GRAPH_URL}/${caseDetails.uuid}`}
                    rel="noreferrer"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    target="_blank"
                  >
                    <StyledButton
                      variant="outlined"
                      color="primary"
                      size="small"
                    >
                      Abrir Grafo
                      <GrafoIcon />
                    </StyledButton>
                  </a>

                  {caseDetails.status === 'Processado' ? (
                    <a
                      href={`/smart/${caseDetails.uuid}`}
                      rel="noreferrer"
                      style={{ textDecoration: 'none', color: 'inherit' }}
                      target="_blank"
                    >
                      <StyledButton
                        variant="outlined"
                        color="primary"
                        size="small"
                      >
                        Visualizar Smart
                        <SmartIcon />
                      </StyledButton>
                    </a>
                  ) : (
                    <Tooltip
                      title="Aguarde até que o caso esteja processado."
                      placement="top"
                      arrow
                    >
                      <span>
                        <StyledButton variant="outlined" size="small" disabled>
                          Visualizar Smart
                          <SmartIcon />
                        </StyledButton>
                      </span>
                    </Tooltip>
                  )}

                  <StyledButton
                    onClick={exportProcess}
                    variant="outlined"
                    color="primary"
                    size="small"
                  >
                    Exportar Processos
                    <ExcelIcon />
                  </StyledButton>

                  <ModalDocx caseDetails={caseDetails} />
                </ButtonContainer>
              </>
            )}
            <TargetTabs targets={caseDetails.targets} />
          </Card>
        )}
      </Container>
    </>
  );
};

export default CaseDetailsPage;
