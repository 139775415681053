import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

import { primaryColor } from '../../../../../../constants/colors';
import { Li } from '../styled';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%'
  }
}));

export default function FrontProperties({ front }) {
  const classes = useStyles();

  return (
    <>
      <li>
        <h2>{front.front}</h2>
      </li>
      <ul>
        {front.rule.map((r) => (
          <>
            <Li>
              Considerando que nas pesquisas realizadas foram encontrados os
              seguintes imóveis em nome de{' '}
              <strong>
                {r.name} - {r.cpf_cnpj}
              </strong>
              :
              <TableContainer component={Paper} style={{ marginTop: '10px' }}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Nome do Cartório</StyledTableCell>
                      <StyledTableCell>Matricula</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {r.result.map(({ nome_cartorio, matricula }) => (
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {nome_cartorio}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {matricula}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Li>
          </>
        ))}
        <Li>
          Sugere-se estudar a viabilidade das seguintes medidas:
          <ul>
            <Li>
              Obter as matrículas indicadas e analisá-las para saber os direitos
              que efetivamente o(a) pesquisado(a) tem atualmente sobre os
              imóveis referenciados e se há ônus/gravames/constrições/penhoras
              ou outros direitos ou fatos relevantes. Recomenda-se, ainda,
              elaborar análise atualizada de valor de mercado desses bens,
              entendendo-se assim o valor efetivo desses ativos. Finalmente, se
              possível, é importante diligência in loco com especialista para
              entender o estado de conservação atual dos bens.
            </Li>
          </ul>
        </Li>
        <Li>
          Caso haja interesse em solicitar auxílio para tais tarefas e análises,{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://forms.monday.com/forms/53cda30c0b17fcb6c85e4a23a3ec7fec?r=use1"
          >
            <strong>clique aqui</strong>
          </a>
          .
        </Li>
      </ul>
    </>
  );
}
