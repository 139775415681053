import FrontLandVehicles from "./FrontLandVehicles";
import FrontEcosystem from "./FrontEcosystem";
import FrontAddresses from "./FrontAddresses";
import FrontCnaeCommercial from "./FrontCnaeCommercial";
import FrontProperties from "./FrontProperties";
import FrontDomainsVirtualAddresses from "./FrontDomainsVirtualAddresses";
import FrontRuralProperties from "./FrontRuralProperties";
import FrontProcessActive from "./FrontProcessActive";
import FrontProcessPassive from "./FrontProcessPassive";
import FrontInaptaBaixada from "./FrontInaptaBaixada";
import FrontPropertiesAdvancedSearch from "./FrontPropertiesAdvancedSearch";
import FrontBranches from "./FrontBranches";
import FrontDeathSearchedPerson from "./FrontDeathSearchedPerson";
import FrontIndividualCompanyPartners from "./FrontIndividualCompanyPartners";


export default function FrontsCreditRecovery({front}) {
    return (
        <ul>
            {front.front_slug === 'front_land_vehicles' && (
                <FrontLandVehicles front={front} />
            )}
            {front.front_slug === 'front_ecosystem' && (
                <FrontEcosystem front={front} />
            )}
            {front.front_slug === 'front_addresses' && (
                <FrontAddresses front={front} />
            )}
            {front.front_slug === 'front_cnae_commercial' && (
                <FrontCnaeCommercial front={front} />
            )}
            {front.front_slug === 'front_properties' && (
                <FrontProperties front={front} />
            )}
            {front.front_slug === 'front_domains_virtual_addresses' && (
                <FrontDomainsVirtualAddresses front={front} />
            )}
            {front.front_slug === 'front_rural_properties' && (
                <FrontRuralProperties front={front} />
            )}
            {front.front_slug === 'front_process_active' && (
                <FrontProcessActive front={front} />
            )}
            {front.front_slug === 'front_process_passive' && (
                <FrontProcessPassive front={front} />
            )}
            {front.front_slug === 'front_inapta_baixada' && (
                <FrontInaptaBaixada front={front} />
            )}
            {front.front_slug === 'front_properties_advanced_search' && (
                <FrontPropertiesAdvancedSearch front={front} />
            )}
            {front.front_slug === 'front_branches' && (
                <FrontBranches front={front} />
            )}
            {front.front_slug === 'front_death_searched_person' && (
                <FrontDeathSearchedPerson front={front} />
            )}
            {front.front_slug === 'front_individual_company_partners' && (
                <FrontIndividualCompanyPartners front={front} />
            )}
        </ul>
        
    )
}