import { VerdeEscuro, VermelhoEscuro } from "../../../Parecer/CreditAnalysis/styled";
import { Body1, Li } from "../styled";


export default function AveriguacaoProcessosAtivosPassivos({front}) {
    return (
        <>
            <h2>
                <li><strong>{front.front}</strong></li>
            </h2>
            <Body1>
                De acordo com as pesquisas realizadas, a tabela abaixo resume os resultados sobre
                a relação entre processos ativos e passivos encontrados:
            </Body1>

            {front.rule.map(({
                name,
                cpf_cnpj,
                capital_social,
                process_doc,
                process_name,
                porcent_process,
                total_process_passivos,
                parecer
            }, index) => (
                <div key={index}>
                <Li style={{marginLeft: '4rem'}}>
                    <strong>{name} - {cpf_cnpj}</strong>
                    <ul>
                        {process_doc && (
                            <Li>
                                <strong>Pesquisa de processos judiciais e administrativos por CPF/CNPJ</strong>
                                <ul>
                                    <Li>
                                        Total de Processos Ativos:{' '}
                                        {process_doc.polo_ativo?.abertos.valor_total} em {process_doc.polo_ativo?.abertos.qtd} processo(s)
                                    </Li>
                                    <Li>
                                        Total de Processos Passivos:{' '}
                                        {process_doc.polo_passivo?.abertos.valor_total} em {process_doc.polo_passivo?.abertos.qtd} processo(s)
                                    </Li>
                                    <Li>
                                        <strong>Diferença entre ativos e passivos</strong>:{' '}
                                        {process_doc.diff?.aberto}
                                    </Li>
                                </ul>
                            </Li>
                        )}
                        {process_name && (
                        <Li>
                            <strong>Pesquisa de processos judiciais e administrativos por nome</strong>
                            <ul>
                                <Li>
                                    Total de Processos Ativos:{' '}
                                    {process_name.polo_ativo?.abertos.valor_total} em {process_name.polo_ativo?.abertos.qtd} processo(s)
                                </Li>
                                <Li>
                                    Total de Processos Passivos:{' '}
                                    {process_name.polo_passivo?.abertos.valor_total} em {process_name.polo_passivo?.abertos.qtd} processo(s)
                                </Li>
                                <Li>
                                    <strong>Diferença entre ativos e passivos</strong>:{' '}
                                    {process_name.diff?.aberto}
                                </Li>
                            </ul>
                        </Li>
                        )}
                    </ul>
                </Li>
                {parecer === "POSITIVO" ? (
                    <Body1>
                        <strong>PROPOSTA DE PARECER</strong>: portanto, com relação à <strong>averiguação sobre a relação entre
                        processos ativos e passivos</strong>, considerando que <VerdeEscuro>não há qualquer processo passivo
                        encontrado em nome do(s) pesquisado(s)</VerdeEscuro>, recomenda-se considerar este como <VerdeEscuro>um ponto
                        positivo para a concessão do crédito</VerdeEscuro>.
                    </Body1>
                ) : (
                    <Body1>
                        <strong>PROPOSTA DE PARECER</strong>: portanto, com relação à <strong>averiguação sobre a relação entre processos ativos e passivos</strong>, 
                        {capital_social && (
                            <> considerando que o capital social da empresa <strong>{name} - {cpf_cnpj}</strong> é de <strong>{capital_social}</strong>;</>
                        )} considerando que o resultado final dos processos encontrados para essa pessoa é <VermelhoEscuro>{total_process_passivos}</VermelhoEscuro>; 
                        {porcent_process && (
                            <> considerando que isso resulta em um passivo que <VermelhoEscuro>compromete {porcent_process}%</VermelhoEscuro> do capital social declarado da empresa;</>
                        )} recomenda-se considerar este como um <VermelhoEscuro>ponto de alerta para a concessão do crédito</VermelhoEscuro>.
                    </Body1>
                )}
                </div>
            ))}
            
            <Li>
                <strong>OBSERVAÇÃO n. 1</strong>: a informação acima é produzida automaticamente de
                acordo com a forma como os processos foram cadastrados perante os
                tribunais. É possível haver imprecisão significativa na somatória. Caso este seja
                uma informação essencial, recomenda-se fazer rechecagem e aprofundar
                no objeto e pé de cada processo.
            </Li>
            <Li>
                <strong>OBSERVAÇÃO n. 2</strong>: caso se deseje consultar a lista completa de processos
                encontradas, pode-se olhar na parte de processos de cada pessoa
                pesquisada abaixo ou, se a lista de processos for excessivamente grande, na
                lista de processos enviada em anexo.
            </Li>
        </>
    )
}