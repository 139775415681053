import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import InputMask from 'react-input-mask';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import axios from 'axios';
import { BASE_URL } from '../../constants/urls';
import { toast, ToastContainer } from 'react-toastify';
import {
  CardTarget,
  CheckBoxContainer,
  DeleteTargetButton,
  StyledTextField
} from '../../pages/NewCase/styled';
import { Delete } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CPFandCNPJ } from '../../utils/validate';
import validaCpfCnpj from '../../utils/teste';
import {
  AddButton,
  ButtonContainer,
  SendButton,
  StyledButton,
  UserIcon
} from './styled';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #4a4a4a',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '25rem',
    maxHeight: '645px',
    overflowY: 'scroll'
  }
}));

const SimpleModal = ({ CpfList, uuid }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isValid, setIsValid] = useState(false);
  const [currentName, setCurrentName] = useState('');
  const token = localStorage.getItem('token');
  const [cpf, setCpf] = useState('');
  const [targets, setTargets] = useState([]);
  const [button, setButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCPF, setIsLoadingCPF] = useState(false);
  const [checked, setChecked] = useState(false);
  const [reason, setReason] = useState({
    reason: ''
  });
  const [color, setColor] = useState('secondary');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentName('');
    setCpf('');
    setTargets([]);
    setButton(false);
  };

  const handleChangeCheckBox = (event) => {
    setChecked(event.target.checked);
  };

  const getDataByCpf = (cpf) => {
    setIsLoadingCPF(true);
    axios
      .post(`${BASE_URL}/person/search/${cpf}`, null, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        if (response.data.name === '') {
          toast.warning('Não foram retornados dados para o CPF/CNPJ digitado');
          setIsLoadingCPF(false);
        }
        setIsLoadingCPF(false);
        setIsValid(true);
        setCurrentName(response.data.name);
      })
      .catch((err) => {
        setIsLoadingCPF(false);
        setIsValid(false);
        toast.error('Dados não encontrados.');
      });
  };

  const reasonChange = (event) => {
    setReason({
      ...reason,
      [event.target?.name]: event.target?.value
    });
  };

  const checkTargetExists = (target) => {
    const found_cpf_cnpj = targets.filter((item) => {
      return target.cpf_cnpj === item.cpf_cnpj;
    });

    if (found_cpf_cnpj.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  // const checkExistsCase = (target) => {
  //   const found_cpf_cnpj = CpfList.filter((item) => {
  //     const formated = item.replace(/[^\d]+/g, '');
  //     return target.cpf_cnpj === formated;
  //   });

  //   if (found_cpf_cnpj.length === 0) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };

  const addTarget = () => {
    const targetForRegister = {
      name: currentName,
      cpf_cnpj: cpf.replace(/[^\d]+/g, ''),
      secundary: checked,
      reason: reason.reason
    };

    const targetExists = checkTargetExists(targetForRegister);
    let isValid = false;
    if (
      targetForRegister.cpf_cnpj.toString().replace(/[^0-9]/g, '').length ===
        11 ||
      targetForRegister.cpf_cnpj.toString().replace(/[^0-9]/g, '').length === 14
    ) {
      isValid = validaCpfCnpj(CPFandCNPJ(targetForRegister.cpf_cnpj));
      if (cpf === '' || !isValid) {
        toast.error('Preencha o campo de CPF/CNPJ corretamente!');
      } else if (checked === true && reason.reason?.trim() === '') {
        toast.error('Targets secundários devem ter o motivo especificado.');
      } else if (targetExists) {
        toast.error('Este target já está cadastrado no caso.');
        setCurrentName('');
        setReason({
          reason: ''
        });
        setCpf('');
        setChecked(false);
      } else {
        setTargets([...targets, targetForRegister]);
        setCurrentName('');
        setReason({
          reason: ''
        });
        setCpf('');
        setChecked(false);
        setButton(true);
      }
    }
  };

  const removeTargetFromArray = (target) => {
    const filteredArray = targets.filter((value) => {
      return value.cpf_cnpj !== target.cpf_cnpj;
    });

    setTargets([...filteredArray]);

    debugger;

    if (targets.length <= 1) {
      setButton(false);
    }
  };

  const handleChange = useCallback(
    (event) => {
      setCpf(CPFandCNPJ(event.target.value));
      const cpfFormat = event.target.value.replace(/[^\d]+/g, '');
      let isValid = false;
      if (
        event.target.value.toString().replace(/[^0-9]/g, '').length === 11 ||
        event.target.value.toString().replace(/[^0-9]/g, '').length === 14
      ) {
        isValid = validaCpfCnpj(CPFandCNPJ(event.target.value));
        if (isValid) {
          setColor('primary');
          getDataByCpf(cpfFormat);
        } else {
          setColor('secondary');
          setCurrentName('');
        }
      } else {
        setColor('secondary');
        setCurrentName('');
      }
    },
    [getDataByCpf]
  );

  const submitTarget = (targets) => {
    setIsLoading(true);
    const reqBody = {
      targets: targets
    };
    axios
      .patch(`${BASE_URL}/case/${uuid}`, reqBody, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((response) => {
        setIsLoading(false);
        toast.success('Target(s) cadastrado(s) com sucesso!');
        setOpen(false);
        setCurrentName('');
        setCpf('');
        window.location.reload();
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error('Houve um erro ao cadastrar o(s) target(s)!.');
      });
  };

  return (
    <>
      <ToastContainer />
      <div>
        {isLoadingCPF ? (
          <CircularProgress color={'inherit'} size={30} />
        ) : (
          <StyledButton
            onClick={handleOpen}
            variant="outlined"
            color="primary"
            size="small"
          >
            Adicionar Target
            <UserIcon />
          </StyledButton>
        )}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <h1>Adicionar Targets</h1>
              {targets.map((target, index) => (
                <CardTarget key={index}>
                  <DeleteTargetButton
                    onClick={() => removeTargetFromArray(target)}
                  >
                    <Delete />
                  </DeleteTargetButton>
                  <InputMask
                    mask={
                      target.cpf_cnpj.length === 11
                        ? '999.999.999-99'
                        : '99.999.999/9999-99'
                    }
                    maskChar=""
                    value={target.cpf_cnpj}
                    disabled
                  >
                    {() => (
                      <TextField
                        label={'CPF/CNPJ'}
                        name={target.cpf_cnpj}
                        margin="normal"
                        type="text"
                        disabled
                      />
                    )}
                  </InputMask>
                  <TextField
                    label="Nome"
                    name={target.name}
                    margin="normal"
                    value={target.name}
                    disabled
                  />
                  {target.secundary ? (
                    <TextField
                      label="Secundário"
                      name={target.secundary}
                      margin="normal"
                      value={'Sim'}
                      disabled
                    />
                  ) : (
                    <TextField
                      label="Secundário"
                      name={target.secundary}
                      margin="normal"
                      value={'Não'}
                      disabled
                    />
                  )}

                  {!target.secundary ? (
                    <></>
                  ) : (
                    <>
                      <TextField
                        label="Motivo"
                        name={target.reason}
                        margin="normal"
                        value={target.reason}
                        disabled
                      />
                    </>
                  )}
                </CardTarget>
              ))}
              <TextField
                label={'CPF ou CNPJ (Somente números)'}
                name={cpf}
                color={color}
                value={cpf}
                onChange={handleChange}
                margin="normal"
                required
                type="text"
              />
              {currentName !== '' && (
                <TextField
                  label={'Nome'}
                  name={'nome'}
                  margin="normal"
                  type="text"
                  value={currentName}
                />
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    label={'Target Secundário'}
                    checked={checked}
                    onChange={handleChangeCheckBox}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label="Target secundário"
              />
              {!checked ? (
                <></>
              ) : (
                <CheckBoxContainer>
                  <StyledTextField
                    name={'reason'}
                    disabled={!checked}
                    label={'Motivo'}
                    onChange={reasonChange}
                    fullWidth
                    margin={'normal'}
                    required
                    type={'text'}
                  />
                </CheckBoxContainer>
              )}
              <ButtonContainer>
                {isLoading ? (
                  <CircularProgress color={'inherit'} size={30} />
                ) : (
                  <>
                    {isLoadingCPF ? (
                      <CircularProgress size={30} />
                    ) : (
                      <>
                        <AddButton
                          type="button"
                          onClick={addTarget}
                          variant="outlined"
                          color="primary"
                          size="small"
                        >
                          ADICIONAR TARGET
                        </AddButton>
                        {button && (
                          <SendButton
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={() => submitTarget(targets)}
                          >
                            Enviar Targets
                          </SendButton>
                        )}
                      </>
                    )}
                  </>
                )}
              </ButtonContainer>
            </div>
          </Fade>
        </Modal>
      </div>
    </>
  );
};

export default SimpleModal;
