import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { primaryColor } from '../../../constants/colors';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 12
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: '#E6E6E6'
  },
  table: {
    width: '100%'
  }
}));

export default function Sncr({ data }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table
        className={classes.table}
        size="small"
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell>Municípios</StyledTableCell>
            <StyledTableCell>Natureza</StyledTableCell>
            <StyledTableCell>Denominação</StyledTableCell>
            <StyledTableCell>Matrícula</StyledTableCell>
            <StyledTableCell>Cartório</StyledTableCell>
            <StyledTableCell>CNS</StyledTableCell>
            <StyledTableCell>Situação</StyledTableCell>
            <StyledTableCell>SNCR Incra</StyledTableCell>
            <StyledTableCell>Área</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length ? (
            data.map(
              ({
                municipio,
                natureza,
                denominacao,
                matricula,
                cartorio,
                cns,
                situacao,
                sncr_incra,
                area
              }) => (
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row">
                    {municipio?.map((city) => (
                      <p>{city}</p>
                    ))}
                  </StyledTableCell>
                  <StyledTableCell align="left">{natureza}</StyledTableCell>
                  <StyledTableCell align="left">{denominacao}</StyledTableCell>
                  <StyledTableCell align="left">{matricula}</StyledTableCell>
                  <StyledTableCell align="left">{cartorio}</StyledTableCell>
                  <StyledTableCell align="left">{cns}</StyledTableCell>
                  <StyledTableCell align="left">{situacao}</StyledTableCell>
                  <StyledTableCell align="left">{sncr_incra}</StyledTableCell>
                  <StyledTableCell align="left">{area}</StyledTableCell>
                </StyledTableRow>
              )
            )
          ) : (
            <StyledTableRow>
              <StyledTableCell align="center" colSpan={11}>
                Nenhum resultado encontrado.
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
