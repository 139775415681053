import React from 'react';
import { Typography, AppBar, Tabs, Tab, Box, Badge } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TableImoveis from './TableImoveis';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

const StyledBadge = withStyles((theme) => ({
  badge: {
    left: 0,
    top: 10,
    padding: '0 4px'
  }
}))(Badge);

const ufsList = (data) => {
  return Object.values(data).map((state) => Object.keys(state).map((uf) => uf));
};

export default function Imoveis({ data }) {
  const [value, setValue] = React.useState(0);
  const states = ufsList(data);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Tabs Imoveis"
        >
          {states.map((uf, index) => (
            <Tab
              key={index}
              label={uf}
              icon={
                <StyledBadge
                  color="secondary"
                  badgeContent={data[index][uf].length}
                />
              }
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>

      {states.map((uf, index) => (
        <TabPanel value={value} index={index}>
          <TableImoveis data={data[index][uf]} />
        </TabPanel>
      ))}
    </>
  );
}
