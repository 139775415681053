import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { primaryColor } from '../../../constants/colors';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: '#E6E6E6'
  },
  table: {
    width: '100%'
  }
}));

export default function Anac({ data }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table
        className={classes.table}
        size="small"
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Proprietário</StyledTableCell>
            <StyledTableCell align="center">Outros proprietários</StyledTableCell>
            <StyledTableCell align="center">Operador</StyledTableCell>
            <StyledTableCell align="center">Marca - Modelo </StyledTableCell>
            <StyledTableCell align="center">Categoria</StyledTableCell>
            <StyledTableCell align="center">Ano de fabricação</StyledTableCell>
            <StyledTableCell align="center">Número de série</StyledTableCell>
            <StyledTableCell align="center">UF</StyledTableCell>

          </TableRow>
        </TableHead>
        {data?.length ? (
          <>
            <TableBody>
              {data.map((item) => (
                <StyledTableRow>
                  <StyledTableCell align="center" component="th" scope="row">
                    {item?.proprietario}
                  </StyledTableCell>
                  <StyledTableCell align="center" component="th" scope="row">
                    {item?.outros_proprietarios}
                  </StyledTableCell>
                  <StyledTableCell align="center" component="th" scope="row">
                    {item?.nm_operador}
                  </StyledTableCell>
                  <StyledTableCell align="center" component="th" scope="row">
                    {item?.marca} - {item?.ds_modelo}
                  </StyledTableCell>
                  <StyledTableCell align="center" component="th" scope="row">
                    {item?.cd_categoria}
                  </StyledTableCell>
                  <StyledTableCell align="center" component="th" scope="row">
                    {item?.nr_ano_fabricacao}
                  </StyledTableCell>
                  <StyledTableCell align="center" component="th" scope="row">
                    {item?.nr_serie}
                  </StyledTableCell>
                  <StyledTableCell align="center" component="th" scope="row">
                    {item?.sg_uf}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </>
        ) : (
          <StyledTableRow>
            <StyledTableCell align="center" colSpan={16}>
              Sem Resultados
            </StyledTableCell>
          </StyledTableRow>
        )}
      </Table>
    </TableContainer>
  );
}
