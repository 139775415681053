import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

export const StyledTable = styled(Table)`
  width: 100%;
`;

export const StyledTableBody = styled(TableBody)`
  width: 100%;
`;

export const StyledTableContainer = styled(TableContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
`;
