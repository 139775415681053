import { Button, TextField } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 25px;
  height: 2rem;
`;

export const StyledTextField = styled(TextField)`
  width: 90%;
`;

export const StyledButton = styled(Button)`
  width: 15%;
  font-size: 15px;
  .placeholder {
    font-size: 1px;
  }
  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
`;
