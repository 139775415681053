import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid, Typography, CardContent } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: '100%',
    marginBottom: '10px'
  }
});

export default function CertidaoDebitos({ data }) {
  const classes = useStyles();

  return (
    <>
      <Typography
        variant="h6"
        component="div"
        align="center"
        style={{
          marginBottom: '2rem'
        }}
      >
        Total de Processos: {data?.total_processos}
        <Typography color="textSecondary" style={{ marginTop: '2rem' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <p>
                <strong>Data da consulta:</strong> {data?.data_consulta}
              </p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>
                <strong>Validade da consulta:</strong> {data?.validade_consulta}
              </p>
            </Grid>
          </Grid>
        </Typography>
      </Typography>
      {data?.processos?.length ? (
        <>
          {data?.processos?.map((processo) => (
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <p>
                      <strong>Corte:</strong> {processo?.court}
                    </p>
                    <p>
                      <strong>Número:</strong> {processo?.number}
                    </p>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))}
        </>
      ) : (
        <>Nenhum dado encontrado</>
      )}
    </>
  );
}
