import { Li } from '../styled';

export default function FrontInaptaBaixada({front}) {
    return (
        <>
            <li>
                <h2>Frente do aprofundamento acerca do status de “inapta” ou “baixada” do(a) pesquisado.</h2>
            </li>
            <ul>
                <Li>
                    <strong>Considerações e/ou fatos relevantes</strong>:
                    <ul>
                        {front.rule.map((r) => (
                            <Li>
                                Considerando que o status do(a) pesquisado(a) 
                                “<strong>{r.name} - {r.cpf_cnpj}</strong>” consta como 
                                “<strong>Situação</strong>: {r.situation} <strong>Motivo</strong>: {r.reason}”;
                            </Li>
                        ))}
                        <Li>
                            Considerando que em casos de recuperação de crédito é comum que o(a) devedor(a) esvaziem o CNPJ 
                            pelo qual operavam para continuarem operando por meio de outro CNPJ ou até de pessoas físicas interpostas;
                        </Li>
                    </ul>
                </Li>
                <Li>
                    <strong>Conclusões</strong>: recomenda-se consultar a viabilidade jurídica, 
                    bem como a oportunidade e conveniência estratégica, de se realizar as seguintes medidas:
                    <ul>
                        <Li>
                            Em primeiro lugar, obter os atos constitutivos ou alterações societárias mais atualizadas a fim 
                            de se confirmar se tais empresas foram extintas em definitivo na Junta Comercial competente 
                            (ou Cartório de Registro de Pessoas Jurídicas, ou OAB, ou, enfim, a entidade responsável pelo registro dessa pessoa jurídica).
                        </Li>
                        <Li>
                            A depender do resultado da análise citada no tópico anterior, aprofundar as pesquisas, encomendar relatórios 
                            ou contratar dossiês mais elaborados sobre os sócios e pessoas próximas do(a) devedor(a) primário(a), 
                            a fim de confirmar ou refutar a hipótese de que tenha continuado a exercer a mesma atividade comercial 
                            em sucessão empresarial por meio de outras pessoas jurídicas ou naturais;
                        </Li>
                        <Li>
                            Buscar encontrar estabelecimentos virtuais ou físicos onde o(a) devedor(a) porventura esteja operando e/ou comercializando seus produtos e, 
                            caso esteja, recomenda-se adquirir produto ou serviço (caso o preço seja factível), a fim de se entender como estão operando e em nome de quem estão faturando;
                        </Li>
                        <Li>
                            Requerer em face do(a) devedor(a) as medidas executivas de praxe e pesquisas patrimoniais que exigem autorização judicial, 
                            mesmo constando a pessoa jurídica como inapta, pois sabe-se que é possível que pessoas jurídicas inaptas ou baixadas ainda consigam movimentar ou titularizar patrimônio, 
                            o que, inclusive, é utilizado como estratégia de alguns para blindar ardilosamente patrimônio;
                        </Li>
                    </ul>
                </Li>
                <Li>
                    Caso haja interesse em solicitar auxílio para tais tarefas 
                    e análises, <a target="_blank" rel="noreferrer" href="https://forms.monday.com/forms/53cda30c0b17fcb6c85e4a23a3ec7fec?r=use1"><strong>clique aqui</strong></a>.
                </Li>
            </ul>
        </>
    )
}