import { Li } from '../styled';


export default function AveriguacaoCnaeComercial({front}) {
    return (
        <>
        <h2>
            <li><strong>{front.front}</strong></li>
        </h2>
        <ul>
            <Li>Considerações e/ou fatos relevantes:
                <ul>
                    {front.rule.map((r) => (
                        <>
                        <Li>
                            Considerando que com relação ao(à) investigado(a) “<strong>{r.name} - {r.cpf_cnpj}</strong>”; 
                            considerando que um dos objetos sociais envolve <strong>atividade comercial</strong> (no caso, 
                            “<strong>CNAE Fiscal: {r.cnae}</strong>”);
                        </Li>
                        </>
                    ))}
                    <Li>
                        Considerando que pesquisas preventivas, a depender do negócio a ser realizado, 
                        podem tornar necessária avaliação da regularidade de estabelecimento comercial;
                    </Li>
                </ul>
            </Li>
            <Li>
                <strong>Conclusões</strong>: 
                Considerando esses pontos e o que mais consta no relatório, recomenda-se verificar a viabilidade jurídica, 
                bem como a conveniência e oportunidade estratégica, das seguintes medidas:
                <ul>
                    <Li>
                        Confirmar se o objeto social do(a) pesquisado(a) guarda correlação 
                        com o objeto do contrato que se pretende com ele realizar;
                    </Li>
                    <Li>
                        Caso haja interesse e seja relevante pelo tamanho do negócio e nível de confiança entre as partes, 
                        realizar pesquisas perante principais órgãos reguladores responsáveis pela atividade empresarial indicada, 
                        bem como as Fazendas federal, estadual e municipal competentes, para que informem a situação cadastral do(a) devedor(a). 
                        Se forem conhecidos outros players que possam fornecer referências sobre o(a) pesquisado(a), 
                        isso aumentará as chances de se descobrir sobre a idoneidade e competência do(a) pesquisado(a) em questão. Recomenda-se, 
                        por fim, e caso isso faça sentido, que se solicite ao próprio interlocutor apresentação dos documentos que comprovem 
                        a regularidade jurídica para o exercício da atividade em questão.
                    </Li>
                </ul>
            </Li>
            <Li>
                <strong>
                    Caso haja interesse em solicitar auxílio para tais tarefas e análises, {' '}
                    <a target="_blank" rel="noreferrer" href="mailto:vega@inquest.com.br?r=use1">
                        clique aqui
                    </a> ou {' '}
                    <a target="_blank" rel="noreferrer" href="mailto:vega@inquest.com.br?r=use1">
                        entre em contato conosco
                    </a>.
                </strong>
            </Li>
        </ul>
        </>
    )
}