import { FormControl, FormLabel, Input as ChakraInput } from '@chakra-ui/react';
import { forwardRef } from 'react';

const InputBase = ({ label, name, ...rest }, ref) => {
  return (
    <FormControl>
      {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}

      <ChakraInput
        name={name}
        _placeholder={{ color: 'black' }}
        id={name}
        focusBorderColor="pink.500"
        bgColor="gray.200"
        variant="filled"
        _hover={{
          bgColor: 'grey.100'
        }}
        size="lg"
        ref={ref}
        {...rest}
      />
    </FormControl>
  );
};

export default forwardRef(InputBase);
