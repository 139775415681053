import { Card, Typography, CardContent, Avatar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { H3 } from '../../styled';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  avatar: {
    backgroundColor: 'gray',
    width: theme.spacing(8),
    height: theme.spacing(8)
  }
}));

export function CardScore({ data, cpf_cnpj }) {
  const classes = useStyles();

  return data?.score?.map((score) => (
    <Card className={classes.root} variant="outlined">
      {/* {score_info.error_message && (
              <CardContent>
                <Alert severity="warning">
                  <AlertTitle>Aviso!</AlertTitle>
                  <strong>{score_info.error_message}</strong>
                </Alert>
              </CardContent>
            )} */}
      <CardContent align="center">
        <H3>{score?.nome_score}</H3>
        <Avatar aria-label="recipe" className={classes.avatar}>
          <h4>{score?.score}</h4>
        </Avatar>
      </CardContent>
      <CardContent align="center">
        <H3>Info:</H3>
        {score?.texto.length || score?.texto2.length ? (
          <>
            <Typography color="textSecondary">{score?.texto}</Typography>
            <Typography color="textSecondary">{score?.texto2}</Typography>
          </>
        ) : (
          <Typography color="textSecondary">Sem resultados.</Typography>
        )}
        {cpf_cnpj?.length === 14 ? (
          <>
            <H3 style={{ marginTop: '15px' }}>Aprovado:</H3>
            <Typography color="textSecondary">
              {data?.decisao?.aprova}
            </Typography>
            <Typography color="textSecondary">
              {data?.decisao?.texto}
            </Typography>
          </>
        ) : (
          <></>
        )}
      </CardContent>
    </Card>
  ));
}
