import { Body1, Body2, Li } from "../styled";


export default function AveriguacaoMatrizFiliais({front}) {
    return (
        <>
        <h2>
            <li><strong>{front.front}</strong></li>
        </h2>
        <Body1>
            Foi pesquisada a(s) pessoa(s) jurídica(s) abaixo e, 
            relacionada a essa(s) pessoa(s) foram encontradas matriz e/ou filiais. 
            Assim sendo, <strong>abaixo consta a lista com todos os CNPJs ligados a essa pessoa jurídica</strong>:
        </Body1>
        {front.rule.map(({name, cpf_cnpj, result}) => (
            <Li>
                <strong>{name} - {cpf_cnpj}</strong>
                <ul>
                    {result.map(({cnpj, name, situation, addr}) => (
                        <Li>
                            <strong>{cnpj} - {name}</strong>.{' '}
                            {situation} | {addr.street}, {' '}
                            Nº {addr.number ? addr.number : "S/N"}{' '}
                            - {addr.city} - {addr.province}
                        </Li>
                    ))}
                </ul>
            </Li>
        ))}
        <Body1>
            Pois bem.
        </Body1>
        <Body1>
            Existe no Direito brasileiro a posição já pacificada nos Tribunais, 
            inclusive pelo Superior Tribunal de Justiça (STJ), no sentido de que: 
        </Body1>
        <Body2>
            Considerando a posição pacificada pelo Superior Tribunal de Justiça (STJ) no sentido de que: 
            “a filial é uma espécie de estabelecimento empresarial, fazendo parte do acervo patrimonial 
            de uma única pessoa jurídica, partilhando dos mesmos sócios, contrato social e firma ou denominação da matriz. 
            Nessa condição, consiste, conforme doutrina majoritária, em uma universalidade de fato, 
            não ostentando personalidade jurídica própria, não sendo sujeito de direitos, tampouco uma pessoa distinta da sociedade empresária. 
            Cuida-se de um instrumento de que se utiliza o empresário ou sócio para exercer suas atividades. [...]. 
            <strong>A discriminação do patrimônio da empresa, mediante a criação de filiais, não afasta a unidade patrimonial da pessoa jurídica, 
            que, na condição de devedora, deve responder com todo o ativo do patrimônio social por suas dívidas</strong>” 
            (STJ, REsp Repetitivo n. 1355812/RS, Rel. Ministro MAURO CAMPBELL MARQUES, PRIMEIRA SEÇÃO, julgado em 22/05/2013, 
            DJe 31/05/2013, destaques nossos).
        </Body2>
        <Body1>
            <strong>CONCLUSÕES</strong>: tendo em vista essas considerações e fatos relevantes, 
            recomenda-se estudar a viabilidade jurídica e conveniência estratégica das seguintes medidas:
        </Body1>
        <ul>
            <Li>
                Realização de pesquisas nos CNPJs acima mencionados (caso já não tenham sido especificamente pesquisados), 
                tendo em vista a possibilidade de descoberta de problemas jurídicos, negativações, protestos, 
                processos e outros fatos relevantes em nome de uma e que atualmente não aparecem nas pesquisas da outra, 
                mas que no futuro podem vir a impactar, haja vista a unidade patrimonial da pessoa jurídica que faz com 
                que as filiais possam ser responsabilizadas pelas obrigações uma das outras, que a matriz possa ser responsabilizada 
                pelas obrigações das filiais, e que as filiais possam ser responsabilizadas pelas obrigações da matriz.
            </Li>
            <Li>
                <strong>
                    Caso haja interesse em solicitar auxílio para tais tarefas e análises, {' '}
                    <a target="_blank" rel="noreferrer" href="mailto:vega@inquest.com.br?r=use1">
                        clique aqui
                    </a> ou {' '}
                    <a target="_blank" rel="noreferrer" href="mailto:vega@inquest.com.br?r=use1">
                        entre em contato conosco
                    </a>.
                </strong>
            </Li>
        </ul>
        </>
    )
}