import React, { useCallback, useEffect, useState } from 'react';
import Header from '../../components/Header';
import {
  Container,
  Card,
  InputGroup,
  H1,
  StyledSelect,
  StyledFormControl,
  ContextContainer
} from './styled';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CircularProgress,
  makeStyles,
  MenuItem,
  InputLabel,
  Select,
  Button
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { toast, ToastContainer } from 'react-toastify';
import api from '../../services/api/api';
import { newCaseSchema } from '../../schemas/newCase';
import useProtectedPage from '../../hooks/useProtectedPage';
import { BASE_URL } from '../../constants/urls';
import axios from 'axios';
import TargetsComponent from '../../components/AddTargets';
import sendForm from '../../utils/sendForm';
import { useHistory } from 'react-router-dom';
import ReactInputMask from 'react-input-mask';
import { useCurrency as currencyFormatter } from 'react-hook-currency';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const NewCasePage = () => {
  useProtectedPage();
  const [newCaseData, setNewCaseData] = useState({
    clients: [],
    contexts: [],
    products: []
  });
  const [onScreen, setOnScreen] = useState('none');
  const [cpf, setCpf] = useState('');
  const [targets, setTargets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [caseData, setCaseData] = useState({
    client: '',
    product: '',
    context: ''
  });
  const classes = useStyles();
  const [value, setValue] = useState('');
  const history = useHistory();
  const currency = {
    style: 'currency',
    precision: 2,
    locale: 'pt-BR',
    currency: 'BRL'
  };
  const { onChange, format } = currencyFormatter(currency);
  const defaultValue = format('000');

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(newCaseSchema)
  });

  const loadNewCaseData = useCallback(() => {
    api
      .get(`/case/items`)
      .then((response) => {
        const data = response.data;
        setNewCaseData({
          ...newCaseData,
          clients: data?.clients,
          products: data?.product
        });
      })
      .catch((error) => {
        toast.error(error.response.data?.errors.msg);
      });
  }, [newCaseData]);

  useEffect(() => {
    loadNewCaseData();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const clientsInput = newCaseData.clients.map((client) => client);

  const handleChangeCase = (event) => {
    setCaseData({
      ...caseData,
      [event.target?.name]: event.target?.value
    });
  };

  const getContext = (event) => {
    if (event.target.value === 1) {
      setValue('');
      setOnScreen('none');
    } else {
      setOnScreen('block');
      setValue('');
    }
    setCaseData({ ...caseData, product: event.target.value });
    axios
      .get(`${BASE_URL}/product/${event.target.value}`)
      .then((response) => {
        setNewCaseData({ ...newCaseData, contexts: response?.data?.contexts });
      })
      .catch((error) => {
        toast.error(
          'Houve um erro ao carregar os contextos, tente novamente mais tarde.'
        );
      });
  };

  const getFields = (event) => {
    setValue(event.target.value.smart_id);
    setCaseData({ ...caseData, context: event.target.value.id });
  };

  const returnFields = () => {
    switch (value) {
      case 'credit_recovery':
        return (
          <StyledFormControl>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Data da celebração do contrato ou título de crédito que está
                sendo cobrado, com a comprovação documental (pode ser enviado
                por e-mail - monitoramento@inquest.tech)
              </strong>
            </p>
            <ReactInputMask
              mask="99/99/9999"
              fullWidth
              margin={'normal'}
              type={'text'}
              {...register('DataCred', { required: true })}
              label={'Data do crédito'}
              InputLabelProps={{ shrink: true }}
            >
              {(inputProps) => <TextField {...inputProps} type="tel" />}
            </ReactInputMask>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Data do vencimento do contrato ou do inadimplemento da dívida
              </strong>
            </p>
            <ReactInputMask
              mask="99/99/9999"
              fullWidth
              margin={'normal'}
              type={'text'}
              {...register('DataVencIna')}
              label={'Data do vencimento/inadimplemento'}
              required
              InputLabelProps={{ shrink: true }}
            >
              {(inputProps) => <TextField {...inputProps} type="tel" />}
            </ReactInputMask>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Data do protesto da dívida e/ou da anotação no cadastro de
                proteção ao crédito e/ou envio de notificação extrajudicial de
                cobrança (se for o caso){' '}
              </strong>
            </p>
            <ReactInputMask
              mask="99/99/9999"
              fullWidth
              margin={'normal'}
              type={'text'}
              {...register('DataProtesto')}
              label={'Data do protesto'}
              InputLabelProps={{ shrink: true }}
            >
              {(inputProps) => <TextField {...inputProps} type="tel" />}
            </ReactInputMask>
            <p style={{ marginTop: '3rem' }}>
              <strong>Data da distribuição do processo (se for o caso)</strong>
            </p>
            <ReactInputMask
              mask="99/99/9999"
              fullWidth
              margin={'normal'}
              type={'text'}
              {...register('DataDistProcess')}
              label={'Data da distribuição do processo'}
              InputLabelProps={{ shrink: true }}
            >
              {(inputProps) => <TextField {...inputProps} type="tel" />}
            </ReactInputMask>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Envio das cópias (se o caso já tiver sido judicializado) (pode
                ser enviado por e-mail - monitoramento@inquest.tech)
              </strong>
            </p>
            <ReactInputMask
              mask="99/99/9999"
              fullWidth
              margin={'normal'}
              type={'text'}
              {...register('DataDist')}
              label={'Data da distribuição'}
              InputLabelProps={{ shrink: true }}
            >
              {(inputProps) => <TextField {...inputProps} type="tel" />}
            </ReactInputMask>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Inserir aqui o valor histórico da causa ou, caso não tenha
                havido distribuição, o valor nominal do crédito
              </strong>
            </p>
            <TextField
              {...register('ValorHist', {
                required: true
              })}
              fullWidth
              label={'Valor histórico da causa ou do crédito'}
              onChange={onChange}
              defaultValue={defaultValue}
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>Data da citação dos réus/executados</strong>
            </p>
            <ReactInputMask
              mask="99/99/9999"
              fullWidth
              margin={'normal'}
              type={'text'}
              {...register('DataCitacaoReus')}
              label={'Data da citação dos réus/executados'}
              InputLabelProps={{ shrink: true }}
            >
              {(inputProps) => <TextField {...inputProps} type="tel" />}
            </ReactInputMask>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Houve alguma garantia prestada no contrato? Indicar quais foram.
              </strong>
            </p>
            <TextField
              {...register('GarantPrest')}
              label={'Garantias prestadas'}
              fullWidth
              multiline
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Inserir aqui qualquer informação adicional e complementar que se
                considere relevante.
              </strong>
            </p>
            <TextField
              {...register('Observacoes')}
              label={'Observações'}
              fullWidth
              multiline
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
          </StyledFormControl>
        );

      case 'credit_recovery_jr':
        return (
          <StyledFormControl>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Há devedores solidários, avalistas, coobrigados etc.? Se sim,
                indicar nome, CPF/CNPJ e o documento que comprova a
                solidariedade/garantia pessoal.{' '}
              </strong>
            </p>
            <TextField
              {...register('DevSolid')}
              label={'Devedores solidários'}
              fullWidth
              multiline
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Indicar o número do processo de recuperação ou falência e em que
                fase o processo está.
              </strong>
            </p>
            <TextField
              {...register('ProcessRjFalen', {
                required: true
              })}
              label={'Processo de RJ ou Falência'}
              fullWidth
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>Em que fase o processo está.</strong>
            </p>
            <TextField
              {...register('Texto', {
                required: true
              })}
              label={'Texto'}
              fullWidth
              multiline
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Data da celebração do contrato ou título de crédito que está
                sendo cobrado, com a comprovação documental (pode ser enviado
                por e-mail - monitoramento@inquest.tech)
              </strong>
            </p>
            <ReactInputMask
              mask="99/99/9999"
              fullWidth
              margin={'normal'}
              type={'text'}
              {...register('DataCred', { required: true })}
              label={'Data do crédito'}
              InputLabelProps={{ shrink: true }}
            >
              {(inputProps) => <TextField {...inputProps} type="tel" />}
            </ReactInputMask>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Data do vencimento do contrato ou do inadimplemento da dívida
              </strong>
            </p>
            <ReactInputMask
              mask="99/99/9999"
              fullWidth
              margin={'normal'}
              type={'text'}
              {...register('DataVencIna')}
              label={'Data do vencimento/inadimplemento'}
              required
              InputLabelProps={{ shrink: true }}
            >
              {(inputProps) => <TextField {...inputProps} type="tel" />}
            </ReactInputMask>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Data do protesto da dívida e/ou da anotação no cadastro de
                proteção ao crédito e/ou envio de notificação extrajudicial de
                cobrança (se for o caso)
              </strong>
            </p>
            <ReactInputMask
              mask="99/99/9999"
              fullWidth
              margin={'normal'}
              type={'text'}
              {...register('DataProtesto')}
              label={'Data do protesto'}
              InputLabelProps={{ shrink: true }}
            >
              {(inputProps) => <TextField {...inputProps} type="tel" />}
            </ReactInputMask>
            <p>
              <strong>Data da citação dos réus/executados</strong>
            </p>
            <ReactInputMask
              mask="99/99/9999"
              fullWidth
              margin={'normal'}
              type={'text'}
              {...register('DataCitacaoReus')}
              label={'Data da citação dos réus/executados'}
              InputLabelProps={{ shrink: true }}
            >
              {(inputProps) => <TextField {...inputProps} type="tel" />}
            </ReactInputMask>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Inserir aqui o valor histórico da causa ou, caso não tenha
                havido distribuição, o valor nominal do crédito
              </strong>
            </p>
            <TextField
              {...register('ValorHist', {
                required: true
              })}
              label={'Valor histórico da causa ou do crédito'}
              onChange={onChange}
              defaultValue={defaultValue}
              fullWidth
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Houve alguma garantia prestada no contrato? Indicar quais foram.
              </strong>
            </p>
            <TextField
              {...register('GarantPrest')}
              label={'Garantias prestadas'}
              fullWidth
              multiline
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Inserir aqui qualquer informação adicional e complementar que se
                considere relevante.
              </strong>
            </p>
            <TextField
              {...register('Observacoes')}
              label={'Observações'}
              fullWidth
              multiline
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
          </StyledFormControl>
        );

      case 'due_diligence_ma':
        return (
          <StyledFormControl>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Qual é o motivo da investigação preventiva? Explicar o contexto
                da negociação que está acontecendo e as eventuais dúvidas e/ou
                pontos de atenção que se procura esclarecer com a investigação
              </strong>
            </p>
            <TextField
              {...register('ContextoNeg', {
                required: true
              })}
              label={'Contexto negocial'}
              fullWidth
              multiline
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Data em que as partes pretendem celebrar o novo contrato
              </strong>
            </p>
            <ReactInputMask
              mask="99/99/9999"
              fullWidth
              margin={'normal'}
              type={'text'}
              {...register('DataEst', {
                required: true
              })}
              label={'Data estimada para o negócio'}
              InputLabelProps={{ shrink: true }}
            >
              {(inputProps) => <TextField {...inputProps} type="tel" />}
            </ReactInputMask>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Valor do contrato que as partes pretendem celebrar (se for
                contrato mensal, inserir a soma das parcelas; se for superior a
                um ano, inserir o valor de doze parcelas).
              </strong>
            </p>
            <TextField
              {...register('ValorNeg', {
                required: true
              })}
              label={'Valor do negócio'}
              onChange={onChange}
              defaultValue={defaultValue}
              fullWidth
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Houve alguma garantia prestada no contrato? Indicar quais foram.
              </strong>
            </p>
            <TextField
              {...register('GarantPrest')}
              label={'Garantias prestadas'}
              fullWidth
              multiline
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Inserir aqui qualquer informação adicional e complementar que se
                considere relevante.
              </strong>
            </p>
            <TextField
              {...register('Observacoes')}
              label={'Observações'}
              fullWidth
              multiline
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
          </StyledFormControl>
        );

      case 'preventive':
        return (
          <StyledFormControl>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Qual é o motivo da investigação preventiva? Explicar o contexto
                da negociação que está acontecendo e as eventuais dúvidas e/ou
                pontos de atenção que se procura esclarecer com a investigação
              </strong>
            </p>
            <TextField
              {...register('ContextoNeg', {
                required: true
              })}
              label={'Contexto negocial'}
              fullWidth
              multiline
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Objeto específico do contrato que as partes pretendem celebrar
                (ex.: compra da empresa XXX; investimento na start-up XPTO,
                Fornecedores, compra de imóvel de Matrícula n. xxx do 2º CRI de
                São Paulo/SP; contratação da pessoa Fulano de Tal como diretor
                de vendas etc.)
              </strong>
            </p>
            <TextField
              {...register('ObjNeg', {
                required: true
              })}
              label={'Objeto do negócio'}
              fullWidth
              multiline
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Data em que as partes pretendem celebrar o novo contrato
              </strong>
            </p>
            <ReactInputMask
              mask="99/99/9999"
              fullWidth
              margin={'normal'}
              type={'text'}
              {...register('DataEst', {
                required: true
              })}
              label={'Data estimada para o negócio'}
              InputLabelProps={{ shrink: true }}
            >
              {(inputProps) => <TextField {...inputProps} type="tel" />}
            </ReactInputMask>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Valor do contrato que as partes pretendem celebrar (se for
                contrato mensal, inserir a soma das parcelas; se for superior a
                um ano, inserir o valor de doze parcelas).
              </strong>
            </p>
            <TextField
              {...register('ValorNeg', {
                required: true
              })}
              label={'Valor do negócio'}
              onChange={onChange}
              defaultValue={defaultValue}
              fullWidth
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Haverá alguma garantia a ser prestada no novo contrato? Se sim,
                indicar qual será esta garantia.
              </strong>
            </p>
            <TextField
              {...register('Garantias')}
              label={'Garantias'}
              fullWidth
              multiline
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Inserir aqui qualquer informação adicional e complementar que se
                considere relevante.
              </strong>
            </p>
            <TextField
              {...register('Observacoes')}
              label={'Observações'}
              fullWidth
              multiline
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
          </StyledFormControl>
        );

      case 'compliance':
        return (
          <StyledFormControl>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Qual é o motivo da investigação? Explicar de forma objetiva a
                denúncia e a suspeita que existe no caso.
              </strong>
            </p>
            <TextField
              {...register('Denuncia', { required: true })}
              label={'Denúncia'}
              fullWidth
              margin={'normal'}
              required
              multiline
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Construir a narrativa resumida do caso (incluindo os fatos
                denunciados ou suspeitos e o histórico dos principais
                investigados, incluindo a data em que entraram na empresa,
                eventuais incidentes envolvendo-os, personalidades etc.).
              </strong>
            </p>
            <TextField
              {...register('HistAmp')}
              label={'Histórico ampliado'}
              fullWidth
              margin={'normal'}
              multiline
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Data de entrada na empresa ou de início do relacionamento
              </strong>
            </p>
            <ReactInputMask
              mask="99/99/9999"
              fullWidth
              margin={'normal'}
              type={'text'}
              {...register('DataIni')}
              label={
                'Data de entrada na empresa ou de início do relacionamento'
              }
              InputLabelProps={{ shrink: true }}
            >
              {(inputProps) => <TextField {...inputProps} type="tel" />}
            </ReactInputMask>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Inserir aqui a data em que se estima que os fatos suspeitos de
                não-conformidade foram iniciados
              </strong>
            </p>
            <ReactInputMask
              mask="99/99/9999"
              fullWidth
              margin={'normal'}
              type={'text'}
              {...register('InicioPrd', {
                required: true
              })}
              label={'Início do período de não-conformidade'}
              InputLabelProps={{ shrink: true }}
            >
              {(inputProps) => <TextField {...inputProps} type="tel" />}
            </ReactInputMask>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Inserir aqui a data em que se estima que os fatos suspeitos de
                não-conformidade foram encerrados
              </strong>
            </p>
            <ReactInputMask
              mask="99/99/9999"
              fullWidth
              margin={'normal'}
              type={'text'}
              {...register('FimPrd', {
                required: true
              })}
              label={'Fim do período de não-conformidade'}
              InputLabelProps={{ shrink: true }}
            >
              {(inputProps) => <TextField {...inputProps} type="tel" />}
            </ReactInputMask>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Data da saída do colaborador dos quadros da empresa; ou término
                da relação com aquela pessoa;ou data em que o suspeito foi
                confrontado diretamente com o fato de não-conformidade
              </strong>
            </p>
            <ReactInputMask
              mask="99/99/9999"
              fullWidth
              margin={'normal'}
              type={'text'}
              {...register('DataSaida')}
              label={'Data de saída'}
              InputLabelProps={{ shrink: true }}
            >
              {(inputProps) => <TextField {...inputProps} type="tel" />}
            </ReactInputMask>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Objetivo da investigação (explicar neste campo a finalidade para
                a qual se pretende utilizar as informações, quais são os
                objetivos da investigação e o que se espera que a investigação
                revele)
              </strong>
            </p>
            <TextField
              {...register('ObjEsc', {
                required: true
              })}
              label={'Objetivos e escopo'}
              fullWidth
              multiline
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Inserir aqui qualquer informação adicional e complementar que se
                considere relevante.
              </strong>
            </p>
            <TextField
              {...register('Observacoes')}
              label={'Observações'}
              fullWidth
              multiline
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
          </StyledFormControl>
        );

      case 'divorce':
        return (
          <StyledFormControl>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Narrar de forma objetiva a história do casal com os principais
                fatos (incluindo quando se conheceram, período de namoro,
                período de noivado, período de casamento, período de crise,
                período de separação de fato, período de divórcio e partilha,
                período posterior). A narrativa PRECISA incluir as principais
                razões do término, mesmo que forem pessoais ou desagradáveis,
                como extraconjugalidade, violência doméstica, desvio de
                patrimônio do casal, alcoolismo, vício em drogas etc (sigilo
                garantido).
              </strong>
            </p>
            <TextField
              {...register('HistCasal', { required: true })}
              label={'História do casal'}
              fullWidth
              multiline
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Inserir a data de início e final do relacionamento. Se a relação
                não terminou, inserir a data de hoje no campo de data final.{' '}
              </strong>
            </p>
            <ReactInputMask
              mask="99/99/9999"
              fullWidth
              margin={'normal'}
              type={'text'}
              {...register('Inicio')}
              label={'Início'}
              InputLabelProps={{ shrink: true }}
            >
              {(inputProps) => <TextField {...inputProps} type="tel" />}
            </ReactInputMask>
            <ReactInputMask
              mask="99/99/9999"
              fullWidth
              margin={'normal'}
              type={'text'}
              {...register('Fim')}
              label={'Fim'}
              InputLabelProps={{ shrink: true }}
            >
              {(inputProps) => <TextField {...inputProps} type="tel" />}
            </ReactInputMask>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Inserir o regime de bens do casal e a data do casamento (se
                possível, anexar ao final a certidão de casamento)
              </strong>
            </p>
            <TextField
              {...register('RegimeBens', { required: true })}
              label={'Regime de bens'}
              fullWidth
              multiline
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <ReactInputMask
              mask="99/99/9999"
              fullWidth
              margin={'normal'}
              type={'text'}
              {...register('DataCas', { required: true })}
              label={'Data do casamento'}
              InputLabelProps={{ shrink: true }}
            >
              {(inputProps) => <TextField {...inputProps} type="tel" />}
            </ReactInputMask>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Quais bens, ativos e passivos o(a) cliente conhece?
              </strong>
            </p>
            <TextField
              {...register('BensConhec')}
              label={'Bens conhecidos'}
              fullWidth
              multiline
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                O caso já foi judicializado? Se sim, indicar todos os processos
                relacionados com o casal, incluindo o processo de divórcio,
                processo de guarda, processo de separação de corpos, alimentos
                etc., incluindo o número do processo, eventual senha e cópias.{' '}
              </strong>
            </p>
            <TextField
              {...register('Judic')}
              label={'Judicialização'}
              fullWidth
              multiline
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Qual é a rede de relacionamentos do investigado? Quais são as
                pessoas que possivelmente possam ter ajudado o investigado(a) a
                ocultar ou desviar patrimônio?
              </strong>
            </p>
            <TextField
              {...register('RedeRelac')}
              label={'Rede principal de relacionamentos'}
              fullWidth
              multiline
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Objetivo da investigação (explicar neste campo a finalidade para
                a qual se pretende utilizar as informações, quais são os
                objetivos da investigação e o que se espera que a investigação
                revele)
              </strong>
            </p>
            <TextField
              {...register('ObjEsc', {
                required: true
              })}
              label={'Objetivos e escopo'}
              fullWidth
              multiline
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Inserir aqui qualquer informação adicional e complementar que se
                considere relevante.
              </strong>
            </p>
            <TextField
              {...register('Observacoes')}
              label={'Observações'}
              fullWidth
              multiline
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
          </StyledFormControl>
        );

      case 'inventary':
        return (
          <StyledFormControl>
            <p style={{ marginTop: '3rem' }}>
              <strong>Preencher aqui o nome e CPF da pessoa que faleceu</strong>
            </p>
            <TextField
              {...register('Falecido', {
                required: true
              })}
              label={'Falecido (de cujus, autor da herança ou sucessão)'}
              fullWidth
              multiline
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>Colocar aqui a data de óbito.</strong>
            </p>
            <ReactInputMask
              mask="99/99/9999"
              fullWidth
              margin={'normal'}
              type={'text'}
              {...register('DataObito', {
                required: true
              })}
              label={'Data de falecimento'}
              InputLabelProps={{ shrink: true }}
            >
              {(inputProps) => <TextField {...inputProps} type="tel" />}
            </ReactInputMask>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                O caso já foi judicializado? Indicar todos os processos
                relacionados com o inventário e eventuais senhas e cópias.
              </strong>
            </p>
            <TextField
              {...register('Judic', {
                required: true
              })}
              label={'Judicialização'}
              fullWidth
              multiline
              required
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Quais bens, ativos e passivos o(a) cliente conhece?
              </strong>
            </p>
            <TextField
              {...register('BensConhec')}
              label={'Bens conhecidos'}
              fullWidth
              multiline
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Quem são os sucessores (herdeiros e legatários), credores e
                devedores conhecidos do de cujus?
              </strong>
            </p>
            <TextField
              {...register('SucesCredDev', {
                required: true
              })}
              label={'Sucessores, credores e devedores'}
              fullWidth
              multiline
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Regime de bens do de cujus (Informar o regime de bens do de
                cujus (na hipótese de ter falecido casado ou com união estável).
                Se possível informar cartório, livro e folha em que está
                registrada a certidão de casamento; ou contrato de convivência;
                ou pacto antenupcial. Se esses arquivos estiverem disponíveis,
                anexar ao final)
              </strong>
            </p>
            <StyledFormControl style={{ minWidth: 150, marginTop: '20px' }}>
              <InputLabel style={{ marginLeft: 12 }} id="">
                Regime de bens do de cujus
              </InputLabel>
              <Select
                required
                {...register('RegimeBensCujus', {
                  required: true
                })}
              >
                <MenuItem value="Comunhão Total">Comunhão Total</MenuItem>
                <MenuItem value="Comunhão Parcial">Comunhão Parcial</MenuItem>
                <MenuItem value="Separação Convencional (Separação Total)">
                  {'Separação Convencional (Separação Total)'}
                </MenuItem>
                <MenuItem value="Separação Obrigatória">
                  Separação Obrigatória
                </MenuItem>
                <MenuItem value="Participação Final nos Aquestos">
                  Participação Final nos Aquestos
                </MenuItem>
              </Select>
            </StyledFormControl>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                O de cujus deixou testamento conhecido? Se sim, indicar o
                cartório, livro e folha. Se o arquivo estiver disponível, anexar
                ao final.
              </strong>
            </p>
            <TextField
              {...register('Testamento')}
              label={'Testamento'}
              fullWidth
              multiline
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Há alguma razão para crer que alguém está escondendo ou
                ocultando patrimônio do de cujus? Justificar.
              </strong>
            </p>
            <TextField
              {...register('SuspOcul')}
              label={'Suspeitas de ocultamento'}
              fullWidth
              multiline
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Qual era a situação familiar que precedeu a morte do de cujus?
                (Ex.: havia rixas ou disputas familiares? Havia pessoas brigadas
                entre si? Se sim, quem? Por quê? Enfim, indicar qualquer
                informação sobre a situação familiar que possa ser útil para as
                pesquisas)
              </strong>
            </p>
            <TextField
              {...register('SitFam')}
              label={'Situação familiar'}
              fullWidth
              multiline
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Objetivo da investigação (explicar neste campo a finalidade para
                a qual se pretende utilizar as informações, quais são os
                objetivos da investigação e o que se espera que a investigação
                revele)
              </strong>
            </p>
            <TextField
              {...register('ObjEsc', { required: true })}
              label={'Objetivos e escopo'}
              fullWidth
              multiline
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Inserir aqui qualquer informação adicional e complementar que se
                considere relevante.
              </strong>
            </p>
            <TextField
              {...register('Observacoes')}
              label={'Observações'}
              fullWidth
              multiline
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
          </StyledFormControl>
        );

      case 'docs':
        return (
          <StyledFormControl>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Informar qual é a pesquisa avulsa ou documento solicitado
                (EXEMPLOS.: aquisição da procuração de livro n. 3451, fl. 244,
                do 1º Tabelião de Notas de São Paulo/SP; ou realização de
                pesquisa de escrituras e procurações do Fulano de Tal, CPF
                xxx.xxx.xxx-xx; ou realização de pesquisa de imóveis em nome da
                empresa XPTO S/A, CNPJ xx.xxx.xxx/0001-24, na cidade de Belo
                Horizonte/MG; ou realização de diligência in loco para obtenção
                de todos do haras Villa do Descanso, em Boituva/SP; ou aquisição
                de todos os atos societários das empresa MAÇÃ S/A, CNPJ
                xx.xxx.xxx/0001-35, e BIGHARD S/A, CNPJ xx.xxx.xxx/0001-46;
                etc).
              </strong>
            </p>
            <TextField
              {...register('PesqAvuls', { required: true })}
              label={'Pesquisa avulsa ou documento a ser solicitado'}
              fullWidth
              multiline
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Objetivo da investigação (explicar neste campo a finalidade para
                a qual se pretende utilizar as informações, quais são os
                objetivos da investigação e o que se espera que a investigação
                revele)
              </strong>
            </p>
            <TextField
              {...register('ObjEsc', { required: true })}
              label={'Objetivos e escopo'}
              fullWidth
              multiline
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Inserir aqui qualquer informação adicional e complementar que se
                considere relevante.
              </strong>
            </p>
            <TextField
              {...register('Observacoes')}
              label={'Observações'}
              fullWidth
              multiline
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
          </StyledFormControl>
        );

      case 'joker':
        return (
          <StyledFormControl>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Objetivo da investigação (explicar neste campo a finalidade para
                a qual se pretende utilizar as informações, quais são os
                objetivos da investigação e o que se espera que a investigação
                revele)
              </strong>
            </p>
            <TextField
              {...register('ObjEsc', { required: true })}
              label={'Objetivos e escopo'}
              fullWidth
              multiline
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Inserir aqui qualquer informação adicional e complementar que se
                considere relevante.
              </strong>
            </p>
            <TextField
              {...register('Observacoes')}
              label={'Observações'}
              fullWidth
              multiline
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
          </StyledFormControl>
        );

      case 'imobiliary':
        return (
          <StyledFormControl>
            <p>
              <strong>
                Qual é a sua posição ou do seu cliente no futuro negócio?
              </strong>
            </p>
            <TextField
              {...register('Posicao', {
                required: true
              })}
              label={'Posição'}
              fullWidth
              multiline
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>Imóvel envolvido no negócio:</strong>
            </p>
            <TextField
              {...register('Imovel:', {
                required: true
              })}
              label={'Imóvel'}
              fullWidth
              multiline
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>Valor do Negócio:</strong>
            </p>
            <TextField
              {...register('ValorNeg', {
                required: true
              })}
              label={'Valor do negócio'}
              onChange={onChange}
              defaultValue={defaultValue}
              fullWidth
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Trata-se da primeira concessão de crédito para este cliente?
              </strong>
            </p>
            <Select
              {...register('ConcessCred')}
              label={'Concessão de crédito'}
              fullWidth
              multiline
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem value={'Sim'}>Sim</MenuItem>
              <MenuItem value={'Não'}>Não</MenuItem>
            </Select>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Inserir aqui qualquer informação adicional e complementar que se
                considere relevante.
              </strong>
            </p>
            <TextField
              {...register('Observacoes')}
              label={'Observações'}
              fullWidth
              multiline
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
          </StyledFormControl>
        );

      case 'due_diligence_pre':
        return (
          <StyledFormControl>
            <p>
              <strong>
                Qual é o motivo da investigação preventiva? Explicar o contexto
                da negociação que está acontecendo e as eventuais dúvidas e/ou
                pontos de atenção que se procura esclarecer com a investigação
              </strong>
            </p>
            <TextField
              {...register('ContNeg', {
                required: true
              })}
              label={'Contexto negocial'}
              fullWidth
              multiline
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Objeto específico do contrato que as partes pretendem celebrar
                (ex.: compra da empresa XXX; investimento na start-up XPTO,
                Fornecedores, compra de imóvel de Matrícula n. xxx do 2º CRI de
                São Paulo/SP; contratação da pessoa Fulano de Tal como diretor
                de vendas etc.)
              </strong>
            </p>
            <TextField
              {...register('ObjNeg', {
                required: true
              })}
              label={'Objeto do negócio'}
              fullWidth
              multiline
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Data em que as partes pretendem celebrar o novo contrato
              </strong>
            </p>
            <ReactInputMask
              mask="99/99/9999"
              fullWidth
              margin={'normal'}
              type={'text'}
              {...register('DataEst', {
                required: true
              })}
              label={'Data estimada para o negócio'}
              InputLabelProps={{ shrink: true }}
            >
              {(inputProps) => <TextField {...inputProps} type="tel" />}
            </ReactInputMask>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Valor do contrato que as partes pretendem celebrar (se for
                contrato mensal, inserir a soma das parcelas; se for superior a
                um ano, inserir o valor de doze parcelas).
              </strong>
            </p>
            <TextField
              {...register('ValorNeg', {
                required: true
              })}
              label={'Valor do negócio'}
              onChange={onChange}
              defaultValue={defaultValue}
              fullWidth
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Haverá alguma garantia a ser prestada no novo contrato? Se sim,
                indicar qual será esta garantia.
              </strong>
            </p>
            <TextField
              {...register('Garantias')}
              label={'Garantias'}
              fullWidth
              multiline
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Inserir aqui qualquer informação adicional e complementar que se
                considere relevante.
              </strong>
            </p>
            <TextField
              {...register('Observacoes')}
              label={'Observações'}
              fullWidth
              multiline
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
          </StyledFormControl>
        );

      case 'credit_analysis':
        return (
          <StyledFormControl>
            <p>
              <strong>
                Inserir aqui o valor do crédito a ser concedido ou o valor da
                operação do negócio.
              </strong>
            </p>
            <TextField
              {...register('ValorCred', {
                required: true
              })}
              label={'Valor do crédito a ser concedido'}
              onChange={onChange}
              defaultValue={defaultValue}
              fullWidth
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Informar aqui se o crédito a ser concedido é de longo ou curto
                prazo. Curto Prazo = 6 meses ou menos Longo Prazo é + de 6 Meses
              </strong>
            </p>
            <Select
              style={{ textAlign: 'left' }}
              {...register('Prazo', {
                required: true
              })}
              label={'Crédito é de longo ou curto prazo?'}
              fullWidth
              multiline
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem value={'Curto'}>Curto</MenuItem>
              <MenuItem value={'Longo'}>Longo</MenuItem>
            </Select>
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Informar se é a primeira vez que se faz negócio com essa parte
              </strong>
            </p>
            <Select
              style={{ textAlign: 'left' }}
              {...register('ConcessCred', {
                required: true
              })}
              label={
                'Trata-se da primeira concessão de crédito para este cliente?'
              }
              fullWidth
              margin={'normal'}
              required
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem value={'Sim'}>Sim</MenuItem>
              <MenuItem value={'Não'}>Não</MenuItem>
            </Select>
            <p style={{ marginTop: '3rem' }}>
              <strong>Tempo de relacionamento com o cliente</strong>
            </p>
            <ReactInputMask
              mask="99/99/9999"
              fullWidth
              margin={'normal'}
              type={'text'}
              {...register('TempoRelac', {
                required: true
              })}
              label={'Data'}
              InputLabelProps={{ shrink: true }}
            >
              {(inputProps) => <TextField {...inputProps} type="tel" />}
            </ReactInputMask>
            <p style={{ marginTop: '3rem' }}>
              <strong>Número de operações bem sucedidas</strong>
            </p>
            <TextField
              {...register('NumBemSuc')}
              label={'Bem-sucedidas'}
              fullWidth
              required
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>Número de operações problemáticas</strong>
            </p>
            <TextField
              {...register('NumIntercor')}
              label={'Intercorrências'}
              fullWidth
              required
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
            />
            <p style={{ marginTop: '3rem' }}>
              <strong>
                Inserir aqui qualquer informação adicional e complementar que se
                considere relevante.
              </strong>
            </p>
            <TextField
              {...register('Observacoes')}
              label={'Observações'}
              fullWidth
              multiline
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
              type={'text'}
            />
          </StyledFormControl>
        );

      default:
        return <div />;
    }
  };

  const validateForm = async (data) => {
    if (cpf !== '') {
      toast.warning('Você esqueceu de adicionar o Target informado!');
      setIsLoading(false);
    } else if (data.name === '') {
      toast.error('Insira o nome do caso!');
      setIsLoading(false);
    } else if (targets.length === 0) {
      toast.error('Insira um target!');
      setIsLoading(false);
    } else if (caseData.client === '') {
      toast.error('Insira um cliente!');
      setIsLoading(false);
    } else if (caseData.product === '') {
      toast.error('Insira um tipo de caso!');
      setIsLoading(false);
    } else if (caseData.context === '' && caseData.product !== 1) {
      toast.error('Insira um contexto!');
      setIsLoading(false);
    }
    setIsLoading(true);
    let response = await sendForm(value, targets, caseData, data, history);
    setIsLoading(response);
  };

  console.error = () => {};

  return (
    <>
      <Header />
      <ToastContainer />
      <form
        className={classes.form}
        noValidate
        onSubmit={handleSubmit(validateForm)}
      >
        <Container>
          <Card>
            <InputGroup>
              <H1 style={{ marginBottom: '20px' }}>Dados do caso</H1>
              {isLoading ? (
                <CircularProgress color={'primary'} size={80} />
              ) : (
                <>
                  <h4>Clientes</h4>
                  <StyledSelect
                    noOptionsMessage={() =>
                      'Cliente não encontrado, verifique os dados.'
                    }
                    placeholder={<>Digite para pesquisar</>}
                    options={clientsInput}
                    getOptionLabel={(clientsInput) =>
                      clientsInput['fantasy_name']
                    }
                    getOptionValue={(clientsInput) => clientsInput['id']}
                    name="client"
                    {...register('client')}
                    value={clientsInput.id}
                    onChange={(e) => {
                      handleChangeCase({
                        target: { name: 'client', value: e.id }
                      });
                    }}
                  />
                  <TextField
                    style={{ marginBottom: 20 }}
                    name={'name'}
                    {...register('name')}
                    label={'Nome do caso'}
                    fullWidth
                    margin={'normal'}
                    required
                    type={'text'}
                  />
                  {errors.name?.message}
                  <StyledFormControl style={{ marginBottom: 20 }}>
                    <InputLabel id="">Tipo de Caso</InputLabel>
                    <Select
                      labelId=""
                      id=""
                      name="product"
                      onChange={getContext}
                      required
                    >
                      {newCaseData.products?.map((product, index) => (
                        <MenuItem
                          key={index}
                          value={product?.id}
                          {...register('product')}
                        >
                          {product?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                  <ContextContainer style={{ display: `${onScreen}` }}>
                    <StyledFormControl
                      className="fomrcontrol"
                      style={{ marginBottom: 6 }}
                    >
                      <InputLabel id="">Contexto</InputLabel>
                      <Select
                        onChange={getFields}
                        labelId=""
                        id=""
                        name="context"
                        required
                      >
                        {newCaseData.contexts?.map((context, index) => (
                          <MenuItem key={index} value={context}>
                            {context?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </StyledFormControl>
                    {returnFields()}
                  </ContextContainer>
                  <Button
                    disabled={isLoading}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Criar Caso
                  </Button>
                </>
              )}
            </InputGroup>
            <TargetsComponent
              cpf={cpf}
              targets={targets}
              setCpf={setCpf}
              setTargets={setTargets}
            />
          </Card>
        </Container>
      </form>
    </>
  );
};

export default NewCasePage;
