import { Li } from '../styled';
import Checktudo from '../../../../../../components/ServicesComponent/Checktudo';


export default function FrontLandVehicles({front}) {
    return (
        <>
            <li>
                <h2><strong>{front.front}</strong></h2>
            </li>
            {front.rule.map((r) => (
                    <Li>
                        <strong>Frente dos Veículos Terrestres de {r.name} - {r.cpf_cnpj}</strong>.
                        <ul>
                            <Li>
                                <strong>Considerandos</strong>:
                                <ul>
                                    <Li>
                                        Considerando que nas pesquisas realizadas foram encontrados veículos de “<strong>{r.name}</strong>”, notadamente os seguintes:
                                    </Li>
                                </ul>
                            </Li>
                        </ul>
                        <Checktudo data={r.result} />
                    </Li>
            ))}
            <Li>
                <strong>Conclusões</strong>: sugere-se estudar a viabilidade jurídica e conveniência estratégica das seguintes medidas:
                <ul>
                    <Li>Utilizar tais bens para a satisfação do crédito.</Li>
                </ul>
            </Li>
            <Li>
                Caso haja interesse em solicitar auxílio para tais tarefas 
                e análises, <a target="_blank" rel="noreferrer" href="https://forms.monday.com/forms/53cda30c0b17fcb6c85e4a23a3ec7fec?r=use1">clique aqui</a>.
            </Li>
        </>
    )
}