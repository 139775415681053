import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  ButtonContainer,
  Container,
  DOCIcon,
  H2,
  ObitoContainer
} from './styled';
import {
  Button,
  Grid,
  Tooltip,
  LinearProgress,
  Box,
  Typography,
  Tab,
  Tabs,
  AppBar
} from '@material-ui/core';
import ServicesComponent from '../ServicesComponent';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../constants/urls';
import { toast, ToastContainer } from 'react-toastify';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
}));

function LinearProgressWithLabel({ services }) {
  const qtd_services = services.length;
  const qtd_finished = services.filter((s) => s.success).length;
  const porcent = Math.round((qtd_finished * 100) / qtd_services);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...{ value: porcent }} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {`${qtd_finished}/${qtd_services}`}
        </Typography>
      </Box>
    </Box>
  );
}

const TargetTabs = ({ targets }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const params = useParams();
  const token = localStorage.getItem('token');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.error = () => {};

  return (
    <div className={classes.root}>
      <ToastContainer />
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {targets &&
            targets.map((target, index) => (
              <Tooltip title={target.cpf_cnpj} placement="top" arrow>
                <Tab
                  key={index}
                  label={target.name}
                  {...a11yProps(index)}
                  iconPosition="bottom"
                  icon={
                    !target.services.every((s) => s.success) && (
                      <Box sx={{ width: '100%' }}>
                        <LinearProgressWithLabel services={target?.services} />
                      </Box>
                    )
                  }
                />
              </Tooltip>
            ))}
        </Tabs>
      </AppBar>

      {targets &&
        targets.map((target, index) => (
          <TabPanel key={index} value={value} index={index}>
            <H2>Informações básicas</H2>
            <Grid
              container
              spacing={3}
              style={{ wordBreak: 'break-all', marginTop: '2rem' }}
            >
              <Grid item xs={12} sm={6}>
                <p>
                  <strong>Nome:</strong> {target.name}
                </p>
                <p>
                  <strong>Secundário: </strong>
                  {target.secundary ? <>Sim</> : <>Não</>}
                </p>
                <p>
                  <strong>Data de nascimento:</strong> {target.birthdate}
                </p>
                <p>
                  <strong>CPF/CNPJ: </strong>
                  {target.cpf_cnpj}
                </p>
                <p>
                  <strong>Status receita:</strong> {target.status_receita}
                </p>
                {target.cpf_cnpj.length === 14 && (
                  <>
                    <p>
                      <strong>RG:</strong> {target.identity_number}
                    </p>
                    <p>
                      <strong>Nome da mãe:</strong> {target.mother_name}
                    </p>
                    <p>
                      <strong>Escolaridade:</strong> {target.education_level}
                    </p>
                  </>
                )}
                <p>
                  {target.cpf_cnpj.length === 14 ? (
                    <>
                      <strong>Renda presumida:</strong> {target.presumed_income}
                    </>
                  ) : (
                    <>
                      <strong>Capital Social:</strong> {target.presumed_income}
                    </>
                  )}
                </p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <p>
                  <strong>Emails cadastrados: </strong>{' '}
                  {target.emails.map((email) => (
                    <p>{email}</p>
                  ))}
                </p>
                <p>
                  <strong>Endereços cadastrados:</strong>{' '}
                  {target.address.map((addr) => (
                    <p>
                      {addr.address || addr.complement},{' '}
                      {addr.number ? addr.number : 'S/N'} - {addr.neighborhood}{' '}
                      - {addr.zipcode} - {addr.city} - {addr.province}
                    </p>
                  ))}
                </p>
                <p>
                  <strong>Telefones cadastrados: </strong>
                  {target?.phones?.map((phone) => (
                    <p style={{ display: 'inline' }}>{phone} </p>
                  ))}
                </p>
              </Grid>
            </Grid>

            {target.cpf_cnpj.length === 14 && (
              <>
                <H2 style={{ marginTop: '20px' }}>Óbito</H2>
                {target?.obito ? (
                  <ObitoContainer>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <p>
                          <strong>Nome do cartório: </strong>
                          {target.obito?.nome_cartorio}
                        </p>
                        <p>
                          <strong>Endereço do cartório: </strong>
                          {target.obito?.endereco_cartorio}
                        </p>
                        <p>
                          <strong>Bairro do cartório: </strong>
                          {target.obito?.bairro_cartorio}
                        </p>
                        <p>
                          <strong>Cidade do cartório: </strong>
                          {target.obito?.cidade_cartorio}
                        </p>
                        <p>
                          <strong>Cep do cartório: </strong>
                          {target.obito?.cep_cartorio}
                        </p>
                        <p>
                          <strong>UF do cartório: </strong>
                          {target.obito?.uf_cartorio}
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p>
                          <strong>Id do cartório: </strong>
                          {target.obito?.id_cartorio}
                        </p>
                        <p>
                          <strong>Número da folha: </strong>
                          {target.obito?.numero_folha}
                        </p>
                        <p>
                          <strong>Número do livro: </strong>
                          {target.obito?.numero_livro}
                        </p>
                        <p>
                          <strong>Número do termo: </strong>
                          {target.obito?.numero_termo}
                        </p>
                        <p>
                          <strong>Data da lavratura: </strong>
                          {target.obito?.data_lavratura}
                        </p>
                        <p>
                          <strong>Data do óbito: </strong>
                          {target.obito?.data_obito}
                        </p>
                      </Grid>
                    </Grid>
                  </ObitoContainer>
                ) : (
                  <p>Nada consta.</p>
                )}
              </>
            )}

            <Container>
              <ServicesComponent target={target} />
            </Container>
          </TabPanel>
        ))}
    </div>
  );
};

export default TargetTabs;
