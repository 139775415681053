import Typography from '@material-ui/core/Typography';
import { Alert, AlertTitle } from '@material-ui/lab';
import ParecerVerde from '../../../../../assets/ParecerVerde.png';
import { LogoImage, H1 } from '../../styled';
import { Body1, VerdeClaro, VerdeEscuro } from './styled';

export default function ParecerCreditAnalysis({ context_id }) {
  const currentDate = () => {
    const date = new Date();
    const monthName = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro'
    ];

    return (
      date.getDate() +
      ' de ' +
      monthName[date.getMonth()] +
      ' de ' +
      date.getFullYear()
    );
  };

  return (
    <>
      <Alert severity="warning">
        <AlertTitle>
          AVISO! No momento o parecer final se encontra incompleto, por favor
          desconsiderar este parecer.
        </AlertTitle>
      </Alert>
      <Typography variant="h5" align="center" gutterBottom>
        Proposta de Parecer de análise de crédito
      </Typography>
      <LogoImage src={ParecerVerde} />
      <Typography variant="body1" align="justify" gutterBottom>
        Entendemos que nas pesquisas e averiguações realizadas{' '}
        <VerdeEscuro>NÃO apareceu qualquer fato desabonador</VerdeEscuro> em
        face da(s) pessoa(s) pesquisada(s). Neste momento das tratativas, e com
        as informações disponíveis até o momento (ver abaixo), emite-se{' '}
        <VerdeClaro>PROPOSTA DE PARECER POSITIVO</VerdeClaro> e{' '}
        <VerdeClaro>SUGERE-SE QUE O CRÉDITO SEJA APROVADO</VerdeClaro>.
      </Typography>
      <Typography variant="body1" align="justify" gutterBottom>
        Se desejar auxílio para aprofundamento deste relatório, para efetivação
        dos destaques e medidas que estão sendo indicadas (
        <strong>ver abaixo</strong>), para dar feedback, sugerir melhorias, ou
        para tirar qualquer dúvida sobre este relatório,{' '}
        <strong>clique aqui</strong>.
      </Typography>
      <Typography variant="body1" gutterBottom style={{ marginBottom: '20px' }}>
        São Paulo/SP, <strong>{currentDate()}</strong>.
      </Typography>
      <Typography
        variant="body1"
        align="justify"
        gutterBottom
        style={{ marginBottom: '20px' }}
      >
        <strong>Obs.</strong>: Essa é a conclusão obtida exclusivamente com a
        análise dos dados que apareceram nos “<strong>REPORTS</strong>” gerados
        (conforme pesquisas abaixo). Essa é a conclusão obtida exclusivamente
        com a análise dos dados que apareceram nos. No mais, a utilização deste
        dossiê pressupõe concordância irrestrita com os termos de uso, que podem
        ser acessados{' '}
        <strong>
          <a href="https://www.dropbox.com/s/30qzuong4uyhdq8/Termos%20de%20uso%20e%20avisos%20legais%20-%20v1.pdf?dl=0">
            aqui
          </a>
        </strong>
        .
      </Typography>

      <H1>
        1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Destaques e Recomendações Técnicas
      </H1>
      <Body1>
        Tendo em vista os dados encontrados, pode-se indicar os seguintes{' '}
        <strong>destaques</strong>:
      </Body1>
    </>
  );
}
