import styled from 'styled-components';
import GroupAddTwoToneIcon from '@material-ui/icons/GroupAddTwoTone';
import { Button } from '@material-ui/core';
import { primaryColor } from '../../constants/colors';

export const UserIcon = styled(GroupAddTwoToneIcon)`
  margin-left: 0.5rem;
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: center;
  justify-content: center;
`;

export const StyledButton = styled(Button)`
  padding: 0.7rem;
  margin-right: 1rem;
  width: auto;
  @media screen and (max-width: 768px) {
    font-size: 0;
    padding: 1rem;
    height: 50px;
  }
`;

export const AddButton = styled(Button)`
  padding: 0.7rem;
  width: 80%;
`;

export const SendButton = styled(Button)`
  padding: 0.7rem;
  background-color: ${primaryColor};
  margin-top: 0.5rem;
  color: white;
  width: 80%;
  :hover {
    background-color: ${primaryColor};
  }
`;
