import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormLabel,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import axios from 'axios';
import React from 'react';
import { useForm } from 'react-hook-form';
import { RiAddLine } from 'react-icons/ri';
import { BASE_URL } from '../../constants/urls';
import InputBase from '../ChakraInput/Input';

const ModalCadUsuer = (id) => {
  console.error = () => {};
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const token = localStorage.getItem('token');

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors }
  } = useForm();

  const createUser = async (form) => {
    const registerId = Object.values(id).toString();

    const reqBody = {
      name: form.name,
      email: form.email,
      username: form.userName,
      enable: form.enable,
      rule_id: form.rule_id
    };

    const response = await axios
      .post(`${BASE_URL}/register/${registerId}/user`, reqBody, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(() => {
        toast({
          title: 'Usuário Criado com sucesso!',
          description:
            'A página irá atualizar automaticamente em alguns segundos',
          status: 'success',
          duration: 5000,
          isClosable: true
        });

        setTimeout(() => {
          window.location.reload();
        }, 5000);
      })
      .catch((error) => {
        const msg = error?.response?.data?.detail?.map((msg) => msg.msg);
        toast({
          title: 'Falha ao cadastrar usuário!',
          description: msg,
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      });
  };

  return (
    <>
      <Button
        onClick={onOpen}
        as="a"
        size="sm"
        fontSize="sm"
        colorScheme="pink"
        leftIcon={<Icon as={RiAddLine} />}
      >
        Criar Novo
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={handleSubmit(createUser)}>
          <ModalHeader>Cadastrar Usuário</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="4">
              <InputBase
                label="Nome (min 10 carácteres)"
                type="text"
                {...register('name', { required: true, minLength: 10 })}
              />
              {errors.name && (
                <Alert
                  status="error"
                  colorScheme="pink"
                  textColor="blackAlpha.500"
                >
                  <AlertIcon />
                  {errors.name?.type === 'minLength'
                    ? 'Mínimo de 10 carácteres'
                    : 'Esse campo é obrigatório'}
                </Alert>
              )}

              <InputBase
                label="E-mail"
                type="email"
                {...register('email', { required: true })}
              />
              {errors.email && (
                <Alert
                  status="error"
                  colorScheme="pink"
                  textColor="blackAlpha.500"
                >
                  <AlertIcon />
                  Esse campo é obrigatório
                </Alert>
              )}

              <InputBase
                label="Nome de Usuário (min 4 carácteres)"
                {...register('userName', { required: true, minLength: 4 })}
              />
              {errors.userName && (
                <Alert
                  status="error"
                  colorScheme="pink"
                  textColor="blackAlpha.500"
                >
                  <AlertIcon />
                  {errors.userName?.type === 'minLength'
                    ? 'Mínimo de 4 carácteres'
                    : 'Esse campo é obrigatório'}
                </Alert>
              )}

              <FormControl>
                <FormLabel>Ativo</FormLabel>
                <Stack spacing="4">
                  <Select
                    {...register('enable', { required: true })}
                    size="lg"
                    focusBorderColor="pink.500"
                    bgColor="gray.200"
                    variant="filled"
                    _hover={{
                      bgColor: 'grey.100'
                    }}
                    placeholder="Selecionar..."
                  >
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </Select>
                  {errors.enable && (
                    <Alert
                      status="error"
                      colorScheme="pink"
                      textColor="blackAlpha.500"
                    >
                      <AlertIcon />
                      Esse campo é obrigatório
                    </Alert>
                  )}
                </Stack>
              </FormControl>

              <FormControl>
                <FormLabel>Rule ID</FormLabel>
                <Stack spacing="4">
                  <Select
                    {...register('rule_id', { required: true })}
                    size="lg"
                    focusBorderColor="pink.500"
                    bgColor="gray.200"
                    variant="filled"
                    _hover={{
                      bgColor: 'grey.100'
                    }}
                    placeholder="Selecionar..."
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </Select>
                  {errors.enable && (
                    <Alert
                      status="error"
                      colorScheme="pink"
                      textColor="blackAlpha.500"
                    >
                      <AlertIcon />
                      Esse campo é obrigatório
                    </Alert>
                  )}
                </Stack>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="pink" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              isLoading={formState.isSubmitting}
              colorScheme="blue"
            >
              Cadastrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalCadUsuer;
