import { Button, Input } from '@material-ui/core';
import styled from 'styled-components';
import { primaryColor } from '../../constants/colors';

export const InputS = styled(Input)`
  display: none;
`;

export const CloseContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

export const ProcButton = styled(Button)`
  width: 200px;
  color: white;
  background-color: ${primaryColor};
  @media screen and (max-width: 768px) {
    font-size: 8px;
    width: 100px;
  }
  :hover {
    background-color: ${primaryColor};
  }
`;

export const PButton = styled(Button)`
  width: 100px;
  color: white;
  background-color: ${primaryColor};
  font-size: 10px;
  @media screen and (max-width: 768px) {
    font-size: 8px;
    width: 100px;
  }
  :hover {
    background-color: ${primaryColor};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 768px) {
    font-size: 12px;
    flex-direction: column;
  }
`;

export const SendButton = styled(Button)`
  background-color: ${primaryColor};
  color: white;
  @media screen and (max-width: 768px) {
    font-size: 12px;
    margin-top: 10px;
  }
  :hover {
    background-color: ${primaryColor};
  }
`;

export const InputNone = styled.input`
  display: none;
`;

export const StyledButton = styled(Button)`
  width: 150px;
  margin-bottom: 5px;
`;
