import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import axios from 'axios';
import React, { useCallback, useState } from 'react';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../constants/urls';
import validaCpfCnpj from '../../utils/teste';
import { CPFandCNPJ } from '../../utils/validate';
import {
  CardTarget,
  CheckBoxContainer,
  DeleteTargetButton,
  H1,
  InputGroup,
  StyledFormControl,
  StyledTextField
} from './styled';

const TargetsComponent = ({ cpf, setCpf, targets, setTargets }) => {
  const token = localStorage.getItem('token');
  const [isLoadingCPF, setIsLoadingCPF] = useState(false);
  const [checked, setChecked] = useState(false);
  const [currentName, setCurrentName] = useState('');
  const [reason, setReason] = useState({
    reason: ''
  });
  const [color, setColor] = useState('secondary');
  const [isValid, setIsValid] = useState(false);

  const getDataByCpf = useCallback(
    (cpf) => {
      setIsLoadingCPF(true);
      axios
        .post(`${BASE_URL}/person/search/${cpf}`, null, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((response) => {
          if (response.data.name === '') {
            toast.warning(
              'Não foram retornados dados para o CPF/CNPJ digitado'
            );
            setIsLoadingCPF(false);
          }
          setIsLoadingCPF(false);
          setIsValid(true);
          setCurrentName(response.data.name);
        })
        .catch((error) => {
          toast.error('Dados não encontrados.');
          setIsLoadingCPF(false);
          setIsValid(false);
        });
    },
    [token]
  );

  const checkTargetExists = (target) => {
    const found_cpf_cnpj = targets.filter((item) => {
      return target.cpf_cnpj === item.cpf_cnpj;
    });

    if (found_cpf_cnpj.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const addTarget = () => {
    const targetForRegister = {
      name: currentName,
      cpf_cnpj: cpf.replace(/[^\d]+/g, ''),
      secundary: checked,
      reason: reason.reason
    };

    const targetExists = checkTargetExists(targetForRegister);
    let isValid = false;
    if (
      targetForRegister.cpf_cnpj.toString().replace(/[^0-9]/g, '').length ===
        11 ||
      targetForRegister.cpf_cnpj.toString().replace(/[^0-9]/g, '').length === 14
    ) {
      isValid = validaCpfCnpj(CPFandCNPJ(targetForRegister.cpf_cnpj));
      if (cpf === '' || !isValid) {
        toast.error('Preencha o campo de CPF/CNPJ corretamente!');
      } else if (checked === true && reason.reason?.trim() === '') {
        toast.error('Targets secundários devem ter o motivo especificado.');
      } else if (targetExists) {
        toast.error('Este target já está cadastrado no caso.');
        setCurrentName('');
        setReason({
          reason: ''
        });
        setCpf('');
        setChecked(false);
      } else {
        setTargets([...targets, targetForRegister]);
        setCurrentName('');
        setReason({
          reason: ''
        });
        setCpf('');
        setChecked(false);
      }
    }
  };

  const handleChangeCheckBox = (event) => {
    setChecked(event.target.checked);
  };

  const handleChange = useCallback(
    (event) => {
      setCpf(CPFandCNPJ(event.target.value));
      const cpfFormat = event.target.value.replace(/[^\d]+/g, '');
      let isValid = false;
      if (
        event.target.value.toString().replace(/[^0-9]/g, '').length === 11 ||
        event.target.value.toString().replace(/[^0-9]/g, '').length === 14
      ) {
        isValid = validaCpfCnpj(CPFandCNPJ(event.target.value));
        if (isValid) {
          setColor('primary');
          getDataByCpf(cpfFormat);
        } else {
          setColor('secondary');
          setCurrentName('');
        }
      } else {
        setColor('secondary');
        setCurrentName('');
      }
    },
    [getDataByCpf]
  );

  const removeTargetFromArray = (target) => {
    const filteredArray = targets.filter((value) => {
      return value.cpf_cnpj !== target.cpf_cnpj;
    });

    setTargets([...filteredArray]);
  };

  const handleChangeReason = (event) => {
    setReason({
      ...reason,
      [event.target?.name]: event.target?.value
    });
  };

  return (
    <>
      <InputGroup>
        <H1 style={{ marginBottom: 5 }}>Incluir Targets</H1>
        {targets.map((target, index) => (
          <StyledFormControl>
            <CardTarget key={index}>
              <DeleteTargetButton onClick={() => removeTargetFromArray(target)}>
                <Delete />
              </DeleteTargetButton>
              <InputMask
                mask={
                  target.cpf_cnpj.length === 11
                    ? '999.999.999-99'
                    : '99.999.999/9999-99'
                }
                maskChar=""
                value={target.cpf_cnpj}
                disabled
              >
                {() => (
                  <TextField
                    label={'CPF/CNPJ '}
                    name={target.cpf_cnpj}
                    margin="normal"
                    type="text"
                    disabled
                  />
                )}
              </InputMask>
              <TextField
                label="Nome"
                name={target.name}
                margin="normal"
                value={target.name}
                disabled
              />
              {target.secundary ? (
                <TextField
                  label="Secundário"
                  name={target.secundary}
                  margin="normal"
                  value={'Sim'}
                  disabled
                />
              ) : (
                <TextField
                  label="Secundário"
                  name={target.secundary}
                  margin="normal"
                  value={'Não'}
                  disabled
                />
              )}

              {!target.secundary ? (
                <></>
              ) : (
                <>
                  <TextField
                    label="Motivo"
                    name={target.reason}
                    margin="normal"
                    value={target.reason}
                    disabled
                  />
                </>
              )}
            </CardTarget>
          </StyledFormControl>
        ))}
        <StyledFormControl>
          <TextField
            label={'CPF ou CNPJ (Somente números)'}
            name={cpf}
            color={color}
            margin="normal"
            required
            type="text"
            value={cpf}
            onChange={handleChange}
          />
        </StyledFormControl>
        {currentName !== '' && (
          <StyledFormControl>
            <TextField
              label={'Nome'}
              name={'nome'}
              margin="normal"
              type="text"
              value={currentName}
            />
          </StyledFormControl>
        )}
        <StyledFormControl>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                label={'Target Secundário'}
                checked={checked}
                onChange={handleChangeCheckBox}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label="Target secundário"
          />
          {!checked ? (
            <></>
          ) : (
            <CheckBoxContainer>
              <StyledTextField
                name={'reason'}
                disabled={!checked}
                label={'Motivo'}
                fullWidth
                margin={'normal'}
                onChange={handleChangeReason}
                required
                type={'text'}
              />
            </CheckBoxContainer>
          )}
        </StyledFormControl>

        {isLoadingCPF ? (
          <CircularProgress color={'inherit'} size={30} />
        ) : (
          <Button
            type="button"
            onClick={addTarget}
            value="add"
            variant="outlined"
          >
            ADICIONAR TARGET
          </Button>
        )}
      </InputGroup>
    </>
  );
};

export default TargetsComponent;
