import AveriguacaoCnaeComercial from "./AveriguacaoCnaeComercial";
import AveriguacaoEcossistema from "./AveriguacaoEcossistema";
import AveriguacaoEmpresasPesquisados from "./AveriguacaoEmpresasPesquisados";
import AveriguacaoMatrizFiliais from "./AveriguacaoMatrizFiliais";
import AveriguacaoNegativacoes from "./AveriguacaoNegativacoes";
import AveriguacaoProcessosAtivosPassivos from "./AveriguacaoProcessosAtivosPassivos";
import AveriguacaoProtestos from "./AveriguacaoProtestos";
import AveriguacaoRegularidadeFormal from "./AveriguacaoRegularidadeFormal";
import AveriguacaoGrupoEconomicoLTDA from "./AveriguacaoGrupoEconomicoLTDA";
import AveriguacaoGrupoEconomicoSA from "./AveriguacaoGrupoEconomicoSA";
import AveriguacaoScore from "./AveriguacaoScore";


export default function FrontsCreditAnalysis({front}) {
    return (
        <ul>
            {front.front_slug === 'averiguacao_regularidade_formal' && (
                <AveriguacaoRegularidadeFormal front={front} />
            )}
            {front.front_slug === 'averiguacao_processos_ativos_passivos' && (
                <AveriguacaoProcessosAtivosPassivos front={front} />
            )}
            {front.front_slug === 'averiguacao_grupo_economico_ltda' && (
                <AveriguacaoGrupoEconomicoLTDA front={front} />
            )}
            {front.front_slug === 'averiguacao_grupo_economico_sa' && (
                <AveriguacaoGrupoEconomicoSA front={front} />
            )}
            {front.front_slug === 'averiguacao_protestos' && (
                <AveriguacaoProtestos front={front} />
            )}
            {front.front_slug === 'averiguacao_negativacoes' && (
                <AveriguacaoNegativacoes front={front} />
            )}
            {front.front_slug === 'averiguacao_score' && (
                <AveriguacaoScore front={front} />
            )}
            {front.front_slug === 'averiguacao_matriz_filiais' && (
                <AveriguacaoMatrizFiliais front={front} />
            )}
            {front.front_slug === 'front_ecosystem' && (
                <AveriguacaoEcossistema front={front} />
            )}
            {front.front_slug === 'averiguacao_empresas_pesquisados' && (
                <AveriguacaoEmpresasPesquisados front={front} />
            )}
            {front.front_slug === 'averiguacao_cnae_comercial' && (
                <AveriguacaoCnaeComercial front={front} />
            )}
        </ul>
        
    )
}