import {
  Card,
  Grid,
  Typography,
  CardContent,
  Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: '100%',
    marginBottom: '10px'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  actual: {
    border: '2px solid #81c784'
  }
});

const Sintegra = ({ data }) => {
  const classes = useStyles();

  return data?.result?.length ? (
    data?.result?.map((info) => (
      <>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h6">{data?.message}</Typography>
        </div>
        <Card className={classes.root} variant="outlined">
          <CardContent className={classes.actual}>
            <Typography variant="h6" style={{ marginBottom: '20px' }}>
              {info?.razao_social} - {info?.cnpj}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <p>
                    <strong>Atividade Econômica:</strong>{' '}
                    {info?.atividade_economica}
                  </p>
                  <p>
                    <strong>Natureza Jurídica:</strong>{' '}
                    {info?.natureza_juridica}
                  </p>
                  <p>
                    <strong>Nome Fantasia:</strong> {info?.nome_fantasia}
                  </p>
                  <p>
                    <strong>Inscrição Estadual:</strong>{' '}
                    {info?.inscricao_estadual}
                  </p>
                  <p>
                    <strong>Ocorrência Fiscal:</strong>{' '}
                    {info?.ocorrencia_fiscal}
                  </p>
                  <p>
                    <strong>Posto Fiscal:</strong> {info?.posto_fiscal}
                  </p>
                  <p>
                    <strong>Regime Apuração:</strong> {info?.regime_apuracao}
                  </p>
                  <p>
                    <strong>Situação Cadastral:</strong>{' '}
                    {info?.situacao_cadastral}
                  </p>
                  <p>
                    <strong>Data da Situação:</strong>{' '}
                    {info?.situacao_cadastral_data}
                  </p>
                  <p>
                    <strong>Observações da Situação:</strong>{' '}
                    {info?.situacao_cadastral_observacoes}
                  </p>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <p>
                    <strong>Credenciamento CTE data:</strong>{' '}
                    {info?.credenciamento_cte_data}
                  </p>
                  <p>
                    <strong>Credenciamento NFE data:</strong>{' '}
                    {info?.credenciamento_nfe_data}
                  </p>
                  <p>
                    <strong>Data de Inatividade:</strong>{' '}
                    {info?.inatividade_data}
                  </p>
                  <p>
                    <strong>Indicador de Obrigatoriedade NFE:</strong>{' '}
                    {info?.indicador_obrigatoriedade_nfe}
                  </p>
                  <p>
                    <strong>Data de início da obrigatoriedade:</strong>{' '}
                    {info?.inicio_obrigatoriedade_nfe_data}
                  </p>
                  <p>
                    <strong>Modal Aquaviário:</strong> {info?.modal_aquaviario}
                  </p>
                  <p>
                    <strong>Modal Rodiviário:</strong> {info?.modal_rodoviario}
                  </p>
                  <p>
                    <strong>Mensagem:</strong> {info?.mensagem}
                  </p>
                </Grid>
              </Grid>
            </Typography>
            <CardContent>
              <Divider />
              <Typography
                color="textSecondary"
                display="block"
                variant="caption"
                align="left"
                style={{ marginBottom: '20px' }}
              >
                Endereço
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <p>
                    <strong>Logradouro:</strong> {info?.endereco_logradouro}
                  </p>
                  <p>
                    <strong>Número:</strong> {info?.endereco_numero}
                  </p>
                  <p>
                    <strong>Complemento:</strong> {info?.endereco_complemento}
                  </p>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <p>
                    <strong>CEP:</strong> {info?.endereco_cep}
                  </p>
                  <p>
                    <strong>Cidade:</strong> {info?.endereco_municipio}
                  </p>
                  <p>
                    <strong>Estado:</strong> {info?.endereco_uf}
                  </p>
                  <p>
                    <strong>Bairro:</strong> {info?.endereco_bairro}
                  </p>
                  <p>
                    <strong>Telefone:</strong> {info?.telefone}
                  </p>
                </Grid>
              </Grid>
            </CardContent>
          </CardContent>
        </Card>
      </>
    ))
  ) : (
    <div style={{ textAlign: 'center' }}>
      <Typography variant="h6">{data?.message}</Typography>
      <p>Nenhum resultado encontrado.</p>
    </div>
  );
};

export default Sintegra;
