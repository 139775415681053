import React, { useState, useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import * as XLSX from 'xlsx';
import { toast, ToastContainer } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  ButtonContainer,
  InputNone,
  SendButton,
  Strong,
  StyledButton
} from './styled';
import { CloudUpload } from '@material-ui/icons';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import Select from 'react-select';
import axios from 'axios';
import { BASE_URL } from '../../constants/urls';
import { primaryColor } from '../../constants/colors';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #4a4a4a',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '25rem',
    height: '645px',
    overflowY: 'scroll',
    '@media (max-width: 768px)': {
      height: '550px'
    }
  },
  root: {
    flexGrow: 1,
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: '#E6E6E6'
  },
  table: {
    width: '100%'
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 12
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const ModalSendData = ({ name, targets }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [dataForRegister, setDataForRegister] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [targetsForRegister, setTargetsForRegister] = useState([]);
  const token = localStorage.getItem('token');
  const uploadRef = useRef();
  const myRef = useRef(null);

  const targetsData = targets.map((target) => {
    return {
      value: target.name,
      label: target.name + ' - ' + target.cpf_cnpj,
      cpf_cnpj: target.cpf_cnpj
    };
  });

  const handleOpen = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setOpen(false);
    setDataForRegister([]);
    setIsLoading(false);
  };

  const readExcel = (file) => {
    if (/\.(xls?x)$/i.test(file?.name)) {
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
          e.stopPropagation();
          const buffferArray = e.target.result;

          const wb = XLSX.read(buffferArray, { type: 'buffer' });

          const wsname = wb.SheetNames[0];

          const ws = wb.Sheets[wsname];

          const data = XLSX.utils.sheet_to_json(ws);

          resolve(data);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });

      promise.then((data) => {
        const dataList = data
          .filter((item) => item['Termos'])
          .map((item) => item['Termos']);
        if (dataList.length) {
          setDataForRegister([...dataForRegister, ...dataList]);
          uploadRef.current.value = null;
        } else {
          toast.warning(
            'O documento deve conter as tabelas no formato válido!.'
          );
          uploadRef.current.value = null;
        }
      });
    } else {
      toast.error(
        'Envie um tipo de arquivo válido(Apenas formato .xls ou xlsx)'
      );
      uploadRef.current.value = null;
    }
  };

  const OnChangeTarget = (e) => {
    const data = e.map((e) => {
      return {
        cpf_cnpj: e?.cpf_cnpj,
        name: e?.value
      };
    });
    setTargetsForRegister(data);
  };

  const sendForm = () => {
    setIsLoading(true);
    const reqBody = {
      targets: targetsForRegister,
      case_name: name,
      query: dataForRegister
    };
    if (reqBody.targets.length === 0 || reqBody.query.length === 0) {
      setIsLoading(false);
      toast.error('Insira os dados para continuar!.');
    } else {
      axios
        .post(`${BASE_URL}/jusbrasil/export`, reqBody, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((response) => {
          toast.success('Dados registrados com sucesso!');
          setOpen(false);
          setDataForRegister([]);
          setIsLoading(false);
        })
        .catch((error) => {
          toast.error('Erro ao registrar dados!');
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <StyledButton
        onClick={handleOpen}
        variant="outlined"
        color="primary"
        size="small"
        endIcon={<CloudUpload />}
      >
        Exportar blocos
      </StyledButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <>
          <Fade in={open}>
            <div className={classes.paper}>
              <ButtonContainer style={{ marginBottom: '40px' }}>
                <strong style={{ marginTop: 20 }}>
                  Clique no botão para fazer o envio da planilha.
                </strong>
                <InputNone
                  ref={uploadRef}
                  type="file"
                  accept=".xls,.xlsx,.xlsb"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    readExcel(file);
                  }}
                />
                <Button
                  style={{ marginTop: '2rem' }}
                  onClick={() => {
                    uploadRef.current.click();
                  }}
                  variant="contained"
                  color="primary"
                >
                  <PublishIcon color="inherit" />
                </Button>
              </ButtonContainer>
              <TableContainer component={Paper}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-label="customized table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Termos</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataForRegister.length ? (
                      dataForRegister.map((item) => (
                        <StyledTableRow>
                          <StyledTableCell
                            align="center"
                            component="th"
                            scope="row"
                          >
                            {item}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell align="center" colSpan={15}>
                          Sem resultados.
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <ButtonContainer>
                <Strong>
                  Selecione os targets para os quais deseja vincular o
                  documento:
                </Strong>
              </ButtonContainer>
              <Select
                closeMenuOnSelect={false}
                isMulti
                name="colors"
                options={targetsData}
                onChange={OnChangeTarget}
                className="basic-multi-select"
                classNamePrefix="Selecione"
                placeholder="Selecionar..."
                noOptionsMessage={() => 'Não há mais targets para adicionar.'}
              />
              <SendButton
                variant="outlined"
                color="primary"
                onClick={sendForm}
                disabled={isLoading}
              >
                <>
                  {isLoading ? (
                    <CircularProgress color={'inherit'} size={30} />
                  ) : (
                    'Enviar'
                  )}
                </>
              </SendButton>
            </div>
          </Fade>
        </>
      </Modal>
    </>
  );
};

export default ModalSendData;
