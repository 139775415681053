import Header from '../../components/Header';
import useProtectedPage from '../../hooks/useProtectedPage';
import { Box, Flex, SimpleGrid, Text, theme } from '@chakra-ui/react';
import Chart from 'react-apexcharts';
import SideBar from '../../components/SideBar';

const AdminPage = () => {
  useProtectedPage();

  const options = {
    chart: {
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      foreColor: theme.colors.gray[500]
    },
    grid: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      enabled: false
    },
    xAxis: {
      type: 'datetime',
      axisBorder: {
        color: theme.colors.gray[600]
      },
      axisTicks: {
        color: theme.colors.gray[600]
      },
      categories: [
        '2022-01-04T00:00:00.000Z',
        '2022-01-05T00:00:00.000Z',
        '2022-01-06T00:00:00.000Z',
        '2022-01-07T00:00:00.000Z',
        '2022-01-08T00:00:00.000Z',
        '2022-01-09T00:00:00.000Z',
        '2022-01-10T00:00:00.000Z'
      ]
    },
    fill: {
      opacity: 0.3,
      type: 'gradient',
      gradient: {
        shade: 'light',
        opacityFrom: 0.7,
        opacityTo: 0.3
      }
    }
  };
  const series = [{ name: 'series1', data: [31, 120, 10, 28, 61, 18, 109] }];

  return (
    <Flex direction="column" h="100vh">
      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <SideBar />

        <SimpleGrid flex="1" gap="4" minChildWidth="320px" align="flex-start">
          <Box p={['6', '8']} bg="gray.50" borderRadius={8} pb="4" height="250">
            <Text fontSize="lg" mb="4">
              Inscritos da semana
            </Text>
            <Chart options={options} series={series} type="area" height={160} />
          </Box>

          <Box p={['6', '8']} bg="gray.50" borderRadius={8} pb="4" height="250">
            <Text fontSize="lg" mb="4">
              Taxa de Abertura
            </Text>
            <Chart options={options} series={series} type="area" height={160} />
          </Box>

          <Box p={['6', '8']} bg="gray.50" borderRadius={8} pb="4" height="250">
            <Text fontSize="lg" mb="4">
              Taxa de Abertura
            </Text>
            <Chart options={options} series={series} type="area" height={160} />
          </Box>

          <Box p={['6', '8']} bg="gray.50" borderRadius={8} pb="4" height="250">
            <Text fontSize="lg" mb="4">
              Taxa de Abertura
            </Text>
            <Chart options={options} series={series} type="area" height={160} />
          </Box>
        </SimpleGrid>
      </Flex>
    </Flex>
  );
};

export default AdminPage;
