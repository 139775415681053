import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import {
  ButtonsContainer,
  Img,
  StyledButton,
  StyledCircle,
  StyledExit,
  StyledNewCase,
  StyledP,
  StyledPeople,
  StyledPerson,
  StyledProfile,
  StyledSearch,
  StyledToolbar
} from './styled';
import Logo from '../../assets/Logo.png';
import {
  goToAdmin,
  goToCreate,
  goToHome,
  goToLogin,
  goToSearch
} from '../../routes/coordinator';
import { useHistory, useLocation } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

const Header = () => {
  const history = useHistory();
  const location = useLocation();
  const userName = localStorage.getItem('cg_name');
  const access = localStorage.getItem('access');

  const logout = () => {
    localStorage.clear();
    goToLogin(history);
  };

  const home = () => {
    if (location.pathname === '/') {
      window.location.reload();
    } else {
      goToHome(history);
    }
  };

  return (
    <AppBar position="static">
      <StyledToolbar>
        <Img src={Logo} alt="Logo" onClick={() => home()} />
        <ButtonsContainer>
          {location.pathname !== '/novo-caso' && (
            <Tooltip title="Novo Caso">
              <StyledNewCase
                onClick={() => goToCreate(history)}
                variant="outlined"
                color="inherit"
              >
                <StyledCircle />
              </StyledNewCase>
            </Tooltip>
          )}
          <Tooltip title="Receita Federal">
            <StyledButton
              onClick={() => goToSearch(history)}
              variant="outlined"
              color="inherit"
            >
              <StyledSearch />
            </StyledButton>
          </Tooltip>
          {access >= 4 && (
            <Tooltip title="Dashboard">
              <StyledButton
                onClick={() => goToAdmin(history)}
                variant="outlined"
                color="inherit"
              >
                <StyledPeople />
              </StyledButton>
            </Tooltip>
          )}
          <StyledProfile variant="outlined" color="inherit">
            <StyledPerson />
            <StyledP>{userName}</StyledP>
          </StyledProfile>
          <Tooltip title="Sair">
            <StyledButton
              onClick={() => logout()}
              variant="outlined"
              color="inherit"
            >
              <StyledExit />
            </StyledButton>
          </Tooltip>
        </ButtonsContainer>
      </StyledToolbar>
    </AppBar>
  );
};

export default Header;
