import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { primaryColor } from '../../../constants/colors';


const StyledTableCell = withStyles((theme) => ({

    head: {
        backgroundColor: primaryColor,
        color: theme.palette.common.white
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        backgroundColor: '#E6E6E6'
    },
    table: {
        width: '100%'
    }

}));

export default function Pep({ data }) {
    const classes = useStyles();
    return (
        < TableContainer component={Paper} >
            <Table
                className={classes.table}
                size='small'
                aria-label='customized table'
            >
                <TableHead>
                    <StyledTableCell>Nome</StyledTableCell>
                    <StyledTableCell>Órgão</StyledTableCell>
                    <StyledTableCell>Função</StyledTableCell>
                    <StyledTableCell>Data Início</StyledTableCell>
                    <StyledTableCell>Data Fim</StyledTableCell>
                    <StyledTableCell>Data Fim Carência</StyledTableCell>
                </TableHead>
                <TableBody>
                    {data.length ? (
                        data.map(({ name, nome_orgao, desc_funcao, data_inicio, data_fim, data_fim_carencia }) => (
                            <StyledTableRow>
                                <StyledTableCell>{name}</StyledTableCell>
                                <StyledTableCell>{nome_orgao}</StyledTableCell>
                                <StyledTableCell>{desc_funcao}</StyledTableCell>
                                <StyledTableCell>{data_inicio}</StyledTableCell>
                                <StyledTableCell>{data_fim}</StyledTableCell>
                                <StyledTableCell>{data_fim_carencia}</StyledTableCell>
                            </StyledTableRow>
                        ))
                    ) : (
                        <StyledTableRow>
                            <StyledTableCell align="center" colSpan={6}>
                                Sem Resultados
                            </StyledTableCell>
                        </StyledTableRow>
                    )}

                </TableBody>
            </Table>
        </TableContainer >

    );
}



