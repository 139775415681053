import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Card,
  CardContent,
  Typography,
  Divider
} from '@material-ui/core';
import { primaryColor } from '../../../../constants/colors';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    marginBottom: '10px'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: '#E6E6E6'
  },
  table: {
    width: '100%'
  },
  markSocio: {
    fontWeight: 'bold'
  }
}));

export default function EconomicGroup({ data }) {
  const classes = useStyles();

  return (
    <>
      {data.main_company && (
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography variant="h6" style={{ marginBottom: 12 }}>
              {data.main_company?.company_name} - {data.main_company?.cnpj}
            </Typography>
            <Divider />
            <Typography
              color="textSecondary"
              display="block"
              variant="caption"
              align="center"
            >
              Quadro de Sócios e Administradores
            </Typography>
            {data.main_company?.qsa.map(socio => (
              <Typography variant="body2" component="p">
                {socio.name} - {socio.cpf_cnpj} ({socio.type})
              </Typography>
            ))}
          </CardContent>
        </Card>
      )}

      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Nome</StyledTableCell>
              <StyledTableCell>CNPJ</StyledTableCell>
              <StyledTableCell>Matches</StyledTableCell>
              <StyledTableCell>QSA</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.group?.length ? (
              data.group.map(({ company_name, cnpj, matches, qsa }) => (
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row">
                    {company_name}
                  </StyledTableCell>
                  <StyledTableCell align="left">{cnpj}</StyledTableCell>
                  <StyledTableCell align="left">{matches}</StyledTableCell>
                  <StyledTableCell align="left">
                    {qsa?.map((socio) => (
                      <p className={socio.strong && classes.markSocio}>
                        {socio.name} - {socio.cpf_cnpj} ({socio.type})
                      </p>
                    ))}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell align="center" colSpan={4}>
                  Nenhum Resultado Encontrado.
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
