import React from 'react';
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Typography,
  AppBar,
  Tabs,
  Tab,
  Box,
  Badge,
  Button,
  Tooltip,
  CircularProgress
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { green, red } from '@material-ui/core/colors';
import { Paper } from '@material-ui/core';
import { primaryColor } from '../../../constants/colors';
import PropTypes from 'prop-types';
import TableProcessos from './TableProcessos';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: '#E6E6E6'
  },
  table: {
    width: '100%'
  },
  spacing: {
    '& > *': {
      margin: theme.spacing(1)
    },
    textAlign: 'center'
  }
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    left: 50,
    padding: '0 4px'
  }
}))(Badge);

export default function Processos({ data }) {
  const { court_status, process_all_data, process_only_number, values } = data;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const qtd_all_processes = process_all_data ? process_all_data.length : 0;
  const qtd_process_only_number = process_only_number
    ? process_only_number.length
    : 0;

  const typeStatus = (status) => {
    if (status === 'SUCCESS') return 'SUCESSO';
    else if (status === 'RUNNING') return 'PROCESSANDO';
    else if (status === 'FAILED') return 'ERRO';
    return null;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <h3>Processos Judiciais e Administrativos:</h3>
      <div className={classes.spacing}>
        {court_status?.map(({ court, status }) => (
          <Tooltip title={typeStatus(status)} placement="top">
            <Button size="small" disableTouchRipple={true}>
              <strong>{court}</strong>
              {status === 'SUCCESS' && (
                <CheckCircleIcon style={{ color: green[500] }} />
              )}
              {status === 'RUNNING' && (
                <CircularProgress color="secondary" size={20} />
              )}
              {status === 'FAILED' && (
                <ReportProblemIcon style={{ color: red[500] }} />
              )}
            </Button>
          </Tooltip>
        ))}
      </div>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Tabs Processos por CPF/CNPJ"
        >
          <Tab
            label="Processos por CPF/CNPJ"
            {...a11yProps(0)}
            icon={
              <StyledBadge color="secondary" badgeContent={qtd_all_processes} />
            }
          />
          <Tab
            label="Demais Processos"
            {...a11yProps(1)}
            icon={
              <StyledBadge
                color="secondary"
                badgeContent={qtd_process_only_number}
              />
            }
          />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <TableProcessos
          processes={process_all_data}
          values={values}
          status={true}
        />
        <TableProcessos
          processes={process_all_data}
          values={values}
          status={false}
        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Tribunal</StyledTableCell>
                <StyledTableCell>Número do Processo</StyledTableCell>
                <StyledTableCell>Polo</StyledTableCell>
                <StyledTableCell>Pessoas Relacionadas</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {qtd_process_only_number > 0 ? (
                process_only_number.map(
                  ({ court, number, pole, related_people }) => (
                    <StyledTableRow>
                      <StyledTableCell align="left">{court}</StyledTableCell>
                      <StyledTableCell align="left">{number}</StyledTableCell>
                      <StyledTableCell align="left">{pole}</StyledTableCell>
                      <StyledTableCell align="left">
                        {related_people.map((person) => (
                          <StyledTableRow>
                            <StyledTableCell align="left">
                              {person.name} ({person.role.toLowerCase()})
                              {person.lawers?.length > 0 && (
                                <>
                                  <p
                                    style={{
                                      textAlign: 'center',
                                      fontWeight: 'bold',
                                      fontSize: 10
                                    }}
                                  >
                                    ADVOGADOS
                                  </p>
                                  {person.lawers.map((adv) => (
                                    <p>{adv}</p>
                                  ))}
                                </>
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                )
              ) : (
                <StyledTableRow>
                  <StyledTableCell align="center" colSpan={4}>
                    Nenhum Resultado Encontrado.
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
    </>
  );
}
