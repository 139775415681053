import styled from 'styled-components';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import { People } from '@material-ui/icons';

export const StyledToolbar = styled(Toolbar)`
  display: flex;
  color: white;
  flex-direction: row;
  justify-content: flex-end;
  background: rgb(242, 5, 94);
  background: linear-gradient(
    90deg,
    rgba(242, 5, 94, 1) 12%,
    rgba(13, 150, 177, 1) 100%
  );
  @media screen and (max-width: 768px) {
    height: 5rem;
    justify-content: center;
    align-items: center;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: -0.5rem;
  @media screen and (max-width: 768px) {
    position: absolute;
    bottom: 10px;
    justify-content: center;
  }
`;

export const Img = styled.img`
  width: auto;
  height: 2rem;
  cursor: pointer;
  position: absolute;
  left: 1rem;
  @media screen and (max-width: 768px) {
    height: 1rem;
    position: absolute;
    top: 15px;
    left: 10px;
  }
`;

export const StyledProfile = styled(Button)`
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: auto;
  font-size: 10px;
  @media screen and (max-width: 768px) {
    padding: 2px;
    font-size: 8px;
    margin-left: 0.5rem;
  }
`;

export const StyledButton = styled(Button)`
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  @media screen and (max-width: 768px) {
    padding: 1px;
    font-size: 8px;
    margin-left: 0.5rem;
    padding: 5px;
  }
`;

export const StyledNewCase = styled(Button)`
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: aquamarine;
  font-size: 10px;
  @media screen and (max-width: 768px) {
    padding: 2px;
    font-size: 8px;
    margin-left: 0.5rem;
  }
`;

export const StyledP = styled.p`
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const StyledCircle = styled(AddCircleIcon)``;

export const StyledExit = styled(ExitToAppIcon)``;

export const StyledPerson = styled(PersonIcon)`
  margin-right: 5px;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;

export const StyledSearch = styled(SearchIcon)``;

export const StyledPeople = styled(People)``;
