import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { primaryColor } from '../../../constants/colors';
import { Paper } from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 12
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: '#E6E6E6'
  },
  table: {
    width: '100%'
  }
}));

export default function Procuracoes({ data }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table
        stickyHeader
        className={classes.table}
        aria-label="customized table"
        size="small"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Nome</StyledTableCell>
            <StyledTableCell align="center">CPF/CNPJ/OAB</StyledTableCell>
            <StyledTableCell align="center">Identidade</StyledTableCell>
            <StyledTableCell align="center">Natureza Escritura</StyledTableCell>
            <StyledTableCell align="center">Natureza</StyledTableCell>
            <StyledTableCell align="center">Valor</StyledTableCell>
            <StyledTableCell align="center">Litígio</StyledTableCell>
            <StyledTableCell align="center">Acordo</StyledTableCell>
            <StyledTableCell align="center">Partes</StyledTableCell>
            <StyledTableCell align="center">Cartório</StyledTableCell>
            <StyledTableCell align="center">Cns</StyledTableCell>
            <StyledTableCell align="center">Livro</StyledTableCell>
            <StyledTableCell align="center">Folha</StyledTableCell>
            <StyledTableCell align="center">Data do Ato</StyledTableCell>
            <StyledTableCell align="center">Tipo do Ato</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length ? (
            data.map((item) => (
              <StyledTableRow>
                <StyledTableCell align="center" component="th" scope="row">
                  {item.nome ? item.nome : '-'}
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row">
                  {item.cpf_cnpj_oab ? item.cpf_cnpj_oab : '-'}
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row">
                  {item.identidade ? item.identidade : '-'}
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row">
                  {item.natureza_escritura ? item.natureza_escritura : '-'}
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row">
                  {item.natureza ? item.natureza : '-'}
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row">
                  {item.valor ? item.valor : '-'}
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row">
                  {item.litigio ? item.litigio : '-'}
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row">
                  {item.acordo ? item.acordo : '-'}
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row">
                  {item.partes ? item.partes : '-'}
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row">
                  {item.cartorio ? item.cartorio : '-'}
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row">
                  {item.cns ? item.cns : '-'}
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row">
                  {item.livro ? item.livro : '-'}
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row">
                  {item.folha ? item.folha : '-'}
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row">
                  {item.data_do_ato ? item.data_do_ato : '-'}
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row">
                  {item.tipo_ato ? item.tipo_ato : '-'}
                </StyledTableCell>
              </StyledTableRow>
            ))
          ) : (
            <StyledTableRow>
              <StyledTableCell align="center" colSpan={15}>
                Sem resultados.
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
