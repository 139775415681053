import {
  makeStyles,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles
} from '@material-ui/core';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import Header from '../../components/Header';
import { primaryColor } from '../../constants/colors';
import {
  Container,
  SearchContainer,
  StyledButton,
  StyledTextField
} from './styled';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useProtectedPage from '../../hooks/useProtectedPage';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white,
    padding: '1rem'
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

const useStyles2 = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
}));

const SearchPage = () => {
  useProtectedPage();
  const classes = useStyles();
  const classes2 = useStyles2();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Tabela = () => {
    return (
      <>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Nome</StyledTableCell>
                <StyledTableCell align="center">CPF/CNPJ</StyledTableCell>
                <StyledTableCell align="right">E-mail</StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        {/* <PaginationContainer>
          <ReactPaginate
            previousLabel={'Anterior'}
            nextLabel={'Próximo'}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={'paginationBttns'}
            previousLinkClassName={'previusBttn'}
            nextLinkClassName={'nextBttn'}
            disabledClassName={'paginationDisabled'}
            activeClassName={'paginationActive'}
          />
        </PaginationContainer> */}
      </>
    );
  };

  return (
    <>
      <Header />
      <ToastContainer />
      <Container>
        <div className={classes2.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label="Pesquisa por empresa" {...a11yProps(0)} />
              <Tab label="Pesquisa por email" {...a11yProps(1)} />
              <Tab label="Pesquisa por sócio" {...a11yProps(2)} />
              <Tab label="Pessoa física" {...a11yProps(3)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <SearchContainer>
              <StyledTextField
                id="outlined-basic"
                label="Nome do proprietário ou CNPJ"
                variant="outlined"
                size="small"
              />
              <StyledButton variant="outlined" color="primary" size="large">
                Pesquisar
              </StyledButton>
            </SearchContainer>
            <Tabela />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <SearchContainer>
              <StyledTextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                size="small"
              />
              <StyledButton variant="outlined" color="primary" size="large">
                Pesquisar
              </StyledButton>
            </SearchContainer>
            <Tabela />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <SearchContainer>
              <StyledTextField
                id="outlined-basic"
                label="Nome do Sócio"
                variant="outlined"
                size="small"
              />
              <StyledButton variant="outlined" color="primary" size="large">
                Pesquisar
              </StyledButton>
            </SearchContainer>
            <Tabela />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <SearchContainer>
              <StyledTextField
                id="outlined-basic"
                label="Nome da pessoa"
                variant="outlined"
                size="small"
              />
              <StyledButton variant="outlined" color="primary" size="large">
                Pesquisar
              </StyledButton>
            </SearchContainer>
            <Tabela />
          </TabPanel>
        </div>
      </Container>
    </>
  );
};

export default SearchPage;
