import { Box, Stack, Text, Link, Icon } from '@chakra-ui/react';
import React from 'react';
import {
  RiArchiveLine,
  RiBriefcaseLine,
  RiContactsLine,
  RiDashboardLine,
  RiSettingsLine
} from 'react-icons/ri';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  goToAdmin,
  goToContexts,
  goToProducts,
  goToServices,
  goToUsers
} from '../../routes/coordinator';

const SideBar = () => {
  const history = useHistory();

  return (
    <Box as="aside" w="64" mr="8">
      <Stack spacing="12" align="flex-start">
        <Box>
          <Text fontWeight="bold" fontSize="small">
            PAINEL ADMINISTRATIVO
          </Text>
          <Stack spacing="4" mt="8" align="stretch">
            <Link
              display="flex"
              align="center"
              onClick={() => goToAdmin(history)}
            >
              <Icon mt="1" fontSize="md" as={RiDashboardLine} />
              <Text ml="4" fontSize="md" fontWeight="medium">
                Dashboard
              </Text>
            </Link>
            <Link
              display="flex"
              align="center"
              onClick={() => goToUsers(history)}
            >
              <Icon mt="1" fontSize="md" as={RiContactsLine} />
              <Text ml="4" fontSize="md" fontWeight="medium">
                Cadastro Geral
              </Text>
            </Link>
            <Link
              display="flex"
              align="center"
              onClick={() => goToServices(history)}
            >
              <Icon mt="1" fontSize="md" as={RiSettingsLine} />
              <Text ml="4" fontSize="md" fontWeight="medium">
                Serviços
              </Text>
            </Link>
            <Link
              display="flex"
              align="center"
              onClick={() => goToProducts(history)}
            >
              <Icon mt="1" fontSize="md" as={RiBriefcaseLine} />
              <Text ml="4" fontSize="md" fontWeight="medium">
                Produtos
              </Text>
            </Link>
            <Link
              display="flex"
              align="center"
              onClick={() => goToContexts(history)}
            >
              <Icon mt="1" fontSize="md" as={RiArchiveLine} />
              <Text ml="4" fontSize="md" fontWeight="medium">
                Contextos
              </Text>
            </Link>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default SideBar;
