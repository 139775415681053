import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { toast, ToastContainer } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ButtonContainer, SendButton, Strong, StyledButton } from './styled';
import DescriptionIcon from '@material-ui/icons/Description';
import {
  Box,
  FormControl,
  FormGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles
} from '@material-ui/core';
import axios from 'axios';
import { BASE_URL } from '../../constants/urls';
import { primaryColor } from '../../constants/colors';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #4a4a4a',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '25rem',
    height: '645px',
    overflowY: 'scroll',
    '@media (max-width: 768px)': {
      height: '550px'
    }
  },
  root: {
    flexGrow: 1,
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: '#E6E6E6'
  },
  table: {
    width: '100%'
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 12
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const ModalDocx = ({ caseDetails }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [planilha, setPlanilha] = useState(true);
  const [docx, setDocx] = useState(true);
  const [smart, setSmart] = useState(false);
  const [targetsForRegister, setTargetsForRegister] = useState(
    caseDetails.targets?.map((target) => {
      return {
        name: target.name,
        id: target.id,
        checked: true
      };
    })
  );

  const serviceStatus = caseDetails.targets?.every((target) =>
    target.services?.map((service) => {
      if (service?.dag_id !== 'procuracoes') {
        console.log(service.success);
        return service?.success;
      }
    })
  );

  const token = localStorage.getItem('token');
  const params = useParams();

  const handleOpen = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setOpen(false);
    setIsLoading(false);
  };

  const onChangeCheckBox = (e) => {
    let data = targetsForRegister.map((target) => {
      if (target.id == e.target.value) {
        return { ...target, checked: !target.checked };
      }
      return target;
    });
    setTargetsForRegister(data);
  };

  const onChangeDocx = () => {
    setDocx(!docx);
  };

  const onChangePlanilha = () => {
    setPlanilha(!planilha);
  };

  const onChangeSmart = () => {
    setSmart(!smart);
  };

  const sendSmart = () => {
    const arrayFiltrado = targetsForRegister
      .filter((target) => target.checked == true)
      .map((target) => {
        if (target !== null && target !== undefined) {
          return target.id;
        }
      });

    let reqBody = {
      smart: smart,
      targets: arrayFiltrado
    };

    axios
      .post(`${BASE_URL}/case/${params.id}/report`, reqBody, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        toast.success(`Email enviado com sucesso para ${response.data.msg}`);
      })
      .catch((error) => {
        toast.error('Houve um erro ao enviar o Email!.');
      });
  };

  return (
    <>
      <ToastContainer />
      <StyledButton
        onClick={handleOpen}
        variant="outlined"
        color="primary"
        size="small"
        endIcon={<DescriptionIcon />}
      >
        DOCX
      </StyledButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <>
          <Fade in={open}>
            <div className={classes.paper}>
              <ButtonContainer>
                <Strong>
                  Selecione os targets para os quais deseja vincular o
                  documento:
                </Strong>
              </ButtonContainer>
              <TableContainer component={Paper}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-label="customized table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Targets</StyledTableCell>
                      <StyledTableCell align="center">
                        Selecionado
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {targetsForRegister?.map((target) => {
                      return (
                        <StyledTableRow>
                          <StyledTableCell
                            align="center"
                            component="th"
                            scope="row"
                          >
                            {target?.name}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            component="th"
                            scope="row"
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={{ marginLeft: '2rem' }}
                                  value={target?.id}
                                  defaultChecked={true}
                                  onChange={onChangeCheckBox}
                                />
                              }
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <FormControl component="fieldset" variant="standard">
                  <Strong style={{ marginLeft: '2rem' }}>Smart</Strong>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!serviceStatus}
                          checked={smart}
                          onChange={onChangeSmart}
                        />
                      }
                      label="Com Smart"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={!smart} onChange={onChangeSmart} />
                      }
                      label="Sem Smart"
                    />
                  </FormGroup>
                </FormControl>
                <FormControl
                  required
                  component="fieldset"
                  variant="standard"
                  style={{ marginLeft: '2rem' }}
                >
                  <Strong style={{ marginLeft: '2rem' }}>Conteúdo</Strong>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox onChange={onChangeDocx} checked={docx} />
                      }
                      label="Docx"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={onChangePlanilha}
                          checked={planilha}
                        />
                      }
                      name="planilha"
                      label="Planilha"
                    />
                  </FormGroup>
                </FormControl>
              </Box>

              <SendButton
                variant="outlined"
                color="primary"
                onClick={sendSmart}
                disabled={isLoading}
              >
                <>
                  {isLoading ? (
                    <CircularProgress color={'inherit'} size={30} />
                  ) : (
                    'Enviar'
                  )}
                </>
              </SendButton>
            </div>
          </Fade>
        </>
      </Modal>
    </>
  );
};

export default ModalDocx;
