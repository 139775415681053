import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

import { primaryColor } from '../../../../../../constants/colors';
import { Li } from '../styled';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%'
  }
}));

export default function FrontPropertiesAdvancedSearch({ front }) {
  const classes = useStyles();

  return (
    <>
      <li>
        <h2>{front.front}</h2>
        <ul>
          <Li>
            <strong>Considerações e/ou fatos relevantes</strong>.
            <ul>
              <Li>
                Considerando que o(s) pesquisado(s) abaixo possuem endereços
                fora de área de abrangência de sistemas digitais unificados de
                registro de imóveis ou o sistema competente não estava
                disponível no momento da pesquisa:
              </Li>
            </ul>
          </Li>
          <TableContainer component={Paper} style={{ marginTop: '10px' }}>
            <Table
              className={classes.table}
              size="small"
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>Nome</StyledTableCell>
                  <StyledTableCell>CPF/CNPJ</StyledTableCell>
                  <StyledTableCell>
                    Endereço fora de área de abrangência
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {front.rule.map(({ name, cpf_cnpj, result }) => (
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                      {name}
                    </StyledTableCell>
                    <StyledTableCell align="left">{cpf_cnpj}</StyledTableCell>
                    <StyledTableCell align="left">
                      {result.map(
                        ({
                          complement,
                          number,
                          neighborhood,
                          city,
                          province,
                          zipcode
                        }) => (
                          <StyledTableRow>
                            <StyledTableCell align="left">
                              {complement}, Nº {number ? number : 'S/N'} -{' '}
                              {neighborhood} - {city} - {province} - CEP:{' '}
                              {zipcode}
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Li>
            <strong>Conclusões e/ou recomendações técnicas</strong>.
            Considerando os elementos acima indicados, e levando em consideração
            tudo o que foi encontrado, sugere-se consultar advogado sobre a
            viabilidade jurídica, bem como estudar a conveniência estratégica,
            de se promover as seguintes medidas:
            <ul>
              <Li>
                Realizar pesquisas nos cartórios e cidades específicas em que
                o(s) pesquisado(s) possam ter atividades ou bens, conforme
                tabela acima.
              </Li>
            </ul>
          </Li>
          <Li>
            Caso haja interesse em solicitar auxílio para tais tarefas e
            análises,{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://forms.monday.com/forms/53cda30c0b17fcb6c85e4a23a3ec7fec?r=use1"
            >
              <strong>clique aqui</strong>
            </a>
            .
          </Li>
        </ul>
      </li>
    </>
  );
}
