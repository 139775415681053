import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { primaryColor } from '../../../../../../constants/colors';

import { Li } from '../styled';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 12
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%'
  }
}));

export default function FrontRuralProperties({ front }) {
  const classes = useStyles();

  return (
    <>
      <li>
        <h2>{front.front}</h2>
      </li>
      <ul>
        <Li>
          <strong>Considerações e/ou fatos relevantes</strong>.
          {front.rule.map((rule) => (
            <ul>
              <Li>
                Considerando que nas pesquisas realizadas foi(ram) encontrado(s)
                o(s) seguinte(s) imóvel(s) de{' '}
                <strong>
                  {rule.name} - {rule.cpf_cnpj}
                </strong>
                :
              </Li>
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Municípios</StyledTableCell>
                      <StyledTableCell>Natureza</StyledTableCell>
                      <StyledTableCell>Denominação</StyledTableCell>
                      <StyledTableCell>Matrícula</StyledTableCell>
                      <StyledTableCell>Cartório</StyledTableCell>
                      <StyledTableCell>CNS</StyledTableCell>
                      <StyledTableCell>Situação</StyledTableCell>
                      <StyledTableCell>SNCR Incra</StyledTableCell>
                      <StyledTableCell>Área</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rule.result.map(
                      ({
                        municipio,
                        natureza,
                        denominacao,
                        matricula,
                        cartorio,
                        cns,
                        situacao,
                        sncr_incra,
                        area
                      }) => (
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row">
                            {municipio.map((city) => (
                              <p>{city}</p>
                            ))}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {natureza}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {denominacao}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {matricula}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {cartorio}
                          </StyledTableCell>
                          <StyledTableCell align="left">{cns}</StyledTableCell>
                          <StyledTableCell align="left">
                            {situacao}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {sncr_incra}
                          </StyledTableCell>
                          <StyledTableCell align="left">{area}</StyledTableCell>
                        </StyledTableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </ul>
          ))}
        </Li>
        <Li>
          <strong>Conclusões e/ou recomendações técnicas</strong>. Levando em
          consideração as considerações acima, bem como o que mais consta do
          relatório, sugere-se verificar a viabilidade jurídica, bem como a
          conveniência e oportunidade estratégica, de se promover as seguintes
          ações:
          <ul>
            <Li>
              Verificar se com esses dados já é possível a tomada de atos
              executivos (sobretudo penhora) com relação a esse bem (se
              pertencer à pessoa devedora ou a alguém cuja responsabilidade
              patrimonial já foi estabelecida);
            </Li>
            <Li>
              Aprofundar as pesquisas para verificar a possibilidade de se obter
              a matrícula, a escritura, ou outros documentos que melhor
              individualizem o imóvel em questão e que melhor elucidem sua atual
              situação jurídica.
            </Li>
          </ul>
        </Li>
        <Li>
          Caso haja interesse em solicitar auxílio para tais tarefas e análises,{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://forms.monday.com/forms/53cda30c0b17fcb6c85e4a23a3ec7fec?r=use1"
          >
            <strong>clique aqui</strong>
          </a>
          .
        </Li>
      </ul>
    </>
  );
}
