import { Container, Typography } from "@material-ui/core";
import { H1 } from "../styled";


export default function ContextSmart({caseDetail}) {
    return (
        <Container>
            <H1>Dados do Contexto</H1>
            <p>Este relatório foi <strong>encomendado</strong> por e sob responsabilidade de:</p>
            <Typography variant="body1" align="center" gutterBottom>
                <i>“{caseDetail.client} - {caseDetail.cpf_cnpj}”;</i>
            </Typography>
            <p>tendo como <strong>justa(s) causa(s):</strong></p>
            <Typography variant="body1" align="center" gutterBottom>
                <p><i>“{caseDetail.smart.context.label}” (LGPD, art. 7º, inc. X);</i></p>
            </Typography>
            <p>A fim de <strong>elucidar a situação patrimonial</strong> de:</p>
            {caseDetail.smart.targets.map((target, index) => (
                <Typography key={index} variant="body1" align="center" gutterBottom>
                    <i>“{target.name} - {target.cpf_cnpj}”;</i>
                </Typography>
            ))}
            <p>Com a <strong>finalidade</strong> de:</p>
            <Typography variant="body1" align="center" gutterBottom>
                <i>“{caseDetail.smart.context.name}”</i>
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Com os seguintes <strong>dados de contexto (ATENÇÃO</strong>: as respostas aos pontos abaixo têm o potencial de melhorar 
                significativamente a qualidade da análise do REPORT SMART. Se você não teve a oportunidade de preencher esses dados anteriormente 
                ou agora entende haver interesse em complementação dos mesmos, <a target="_blank" rel="noreferrer" href="https://forms.monday.com/forms/6c1494b2e50f8b82e93379a42bcd250e?r=use1">clique aqui</a> para fazê-lo 
                e depois <a target="_blank" rel="noreferrer" href="https://forms.monday.com/forms/53cda30c0b17fcb6c85e4a23a3ec7fec?r=use1">clique aqui</a> para solicitar nova emissão do REPORT SMART.
            </Typography>
            <p><strong>Nome do caso</strong>: {caseDetail.name}</p>
            <p><strong>Qualificação do solicitante</strong>: {caseDetail.client}</p>
            <p><strong>E-mail do solicitante</strong>: {caseDetail.client_email}</p>
            <p><strong>CPFs ou CNPJs a serem consultados:</strong></p>
            {caseDetail.smart.targets.map((target, index) => (
                <div key={index}>
                    <p><strong>Nome do Target nº {index+1}</strong>: {target.name}</p>
                    <p><strong>CPF/CNPJ do Target nº {index+1}</strong>: {target.cpf_cnpj}</p>
                </div>
            ))}
            {caseDetail.smart?.context?.context_form?.map(({name, response}, index) => (
                <p key={index}>
                    <strong>{name}</strong>: <i>{response || "não informado"}</i>
                </p>
            ))}
        </Container>
    )
}