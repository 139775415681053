import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { primaryColor } from '../../constants/colors';
import DescriptionIcon from '@material-ui/icons/Description';

export const H2 = styled.h2``;

export const Container = styled.div`
  color: #4a4a4a;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1rem;
  color: #4a4a4a;
`;

export const StyledButton = styled(Button)`
  height: 2rem;
  background-color: ${primaryColor};
  color: white;
  margin-right: 1rem;
  color: #4a4a4a;
  background-color: red;
  :hover {
    background-color: red;
  }
`;

export const DOCIcon = styled(DescriptionIcon)`
  margin-right: 0.5rem;
  color: #4a4a4a;
`;

export const ObitoContainer = styled.div`
  margin-top: 1rem;
  color: #4a4a4a;
`;