import styled from 'styled-components';
import { FormControl, Button, TextField } from '@material-ui/core';
import { primaryColor } from '../../constants/colors';
import Select from 'react-select';

export const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin: 0;
  padding: 0;
  color: #4a4a4a;
`;

export const StyledButton = styled(Button)`
  margin-top: 1rem;
  background-color: ${primaryColor};
  color: white;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  :hover {
    background-color: ${primaryColor};
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4a4a4a;
`;

export const Card = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  width: 80%;
  height: auto;
  border-radius: 4px;
  background-color: white;
  display: flex;
  flex-direction: row;
  color: #4a4a4a;
  box-shadow: 10px 10px 20px -1px rgba(0, 0, 0, 0.38);
  -webkit-box-shadow: 10px 10px 20px -1px rgba(0, 0, 0, 0.38);
  -moz-box-shadow: 10px 10px 20px -1px rgba(0, 0, 0, 0.38);
  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
`;

export const InputGroup = styled.div`
  padding: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  height: 100%;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

export const CardTarget = styled.div`
  position: relative;
  border: solid 1px #000;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin: 10px;
  @media screen and (max-width: 768px) {
    margin-top: 2rem;
  }
`;

export const DeleteTargetButton = styled.button`
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: none;
  position: absolute;
  right: 10px;
  top: 5px;
  color: #4a4a4a;
`;

export const H1 = styled.h1`
  @media screen and (max-width: 768px) {
    margin-top: 2rem;
  }
`;

export const StyledSelect = styled(Select)`
  margin-top: 5px;
  width: 100%;
`;

export const ClientsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
`;

export const StyledTextField = styled(TextField)`
  margin-top: -0.6rem;
`;

export const ContextContainer = styled.div`
  width: 100%;
`;
