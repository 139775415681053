import styled from 'styled-components';


export const VerdeEscuro = styled.span`
    color: #008F00;
    font-weight: bold;
`;

export const VerdeClaro = styled.span`
    color: #00B050;
    font-weight: bold;
`;

export const VermelhoEscuro = styled.span`
    color: #FF0000;
    font-weight: bold;
`;

export const Body1 = styled.div`
  margin-bottom: 2rem;
  text-align: justify;
`;